import { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Image, Tab, Tabs } from 'react-bootstrap';
import axiosAPI from '../../../Services/axiosAPI';
import styles from './publicProfile.module.css';
import { useCart } from '../../Navbar/CartContext';
import FollowButton from '../../BaseComponents/FollowButton';
import SellerWorkshops from './SellerWorkshops';
import SellerBoasts from './SellerBoasts';
import './publicProfile.module.css';
// import LoadingOverlay from '../../BaseComponents/LoadingOverlay';
import experienceServices from '../../../Services/experienceServices';
import userServices from '../../../Services/userServices';
import cartServices from '../../../Services/cartServices';
import AuthContext from '../../Context/AuthContext';


const SellersProfiePage = ({ username }) => {

    // console.log("SellersProfiePage-- ", username)
    const { dispatch } = useCart();
    const { setIsLoading } = useContext(AuthContext);
    const [activeTab, setActiveTab] = useState('workshops');
    const [sellerDetails, setSellerDetails] = useState(null);
    const [sellerWorkshops, setSellerWorkshops] = useState(null);
    const [sellerBoasts, setSellerBoasts] = useState(null);
    // const [isLoading, setIsLoading] = useState(false);
    // const [workshopIndex, setWorkshopIndex] = useState(1);
    // const [boastIndex, setBoastIndex] = useState(1);
    
    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                
                // Fetch seller details
                const sellerDetailsResponse = await experienceServices.fetchSellerDetails(username);
                if (sellerDetailsResponse?.statusCode === 200 && sellerDetailsResponse?.data) {
                    setSellerDetails(sellerDetailsResponse.data[0]);
                } else {
                    setSellerDetails(null); // Reset state if API fails or returns empty data
                }
    
                // Fetch seller workshops
                const sellerWorkshopsResponse = await experienceServices.fetchSellerWorkshops(username, 'workshops', 1);
                if (sellerWorkshopsResponse?.statusCode === 200 && sellerWorkshopsResponse?.data) {
                    setSellerWorkshops(sellerWorkshopsResponse.data);
                } else {
                    setSellerWorkshops([]); // Reset to empty array if API fails
                }
    
                // Fetch seller boasts
                const sellerBoastsResponse = await experienceServices.fetchSellerBoasts(username, 'boasts', 1);
                if (sellerBoastsResponse?.statusCode === 200 && sellerBoastsResponse?.data?.boasts) {
                    setSellerBoasts(sellerBoastsResponse.data.boasts);
                } else {
                    setSellerBoasts([]); // Reset to empty array if API fails
                }
    
            } catch (error) {
                console.error("API Error:", error);
                setSellerDetails(null);
                setSellerWorkshops([]);
                setSellerBoasts([]);
            } finally {
                setIsLoading(false);
            }
        }
    
        if (username) {
            fetchData();
        }
    }, [username, setIsLoading]); 
    

    const followOptimizeHandler = (id) => {
        setSellerDetails((prevDetails) => {
            return {
                ...prevDetails,
                isFollowing: id,
            };
        });
    };

  const BoastLikeOptimisticHandler = async(fId, bId, type) => {
    const accessToken = localStorage.getItem('accessToken');
    const token = `Bearer ${accessToken}`;
    const URL = type ? `feedback/blike/${fId}/${bId}` : `feedback/blike/${fId}/${bId}?revoke=true`
    await axiosAPI.axiosAuth.get(URL, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
      });
  }
  
  const MutateBoastLikeStatus = (fId, bId) => {
    let boastsArr = [...sellerBoasts]
    let foundPostBoast = boastsArr.find(boasts => boasts.boast.boastId === bId)
    
    if (foundPostBoast) {
        let foundBoast = foundPostBoast.boast
        let currentLikeStatus = foundBoast.isLikedByMe
        foundBoast.isLikedByMe = !currentLikeStatus
        if(currentLikeStatus === false){
        foundBoast.likesCount=foundBoast.likesCount+1
        BoastLikeOptimisticHandler(fId, bId, true)
        }else{
        foundBoast.likesCount=foundBoast.likesCount-1
        BoastLikeOptimisticHandler(fId, bId, false)
        }
        setSellerBoasts(boastsArr)
    }
  }

  const UserFollowHandler = (postId, type) => {
    
    if (type === "workshop") {
        let postsArr = [...sellerWorkshops];
        let foundPost = postsArr.find(i => i._id === postId);
        if (foundPost) {
            let userId = foundPost.userDetails.id;
            let currentFollowStatus = foundPost.userDetails.isFollowing;
            let postsByUserId = postsArr.filter(post => post.userDetails.id === userId);
            postsByUserId.forEach(post => {
                if (post.userDetails.hasOwnProperty('isFollowing')) {
                    post.userDetails.isFollowing = !post.userDetails.isFollowing;
                }
            });
            setSellerWorkshops(postsArr);

            if (currentFollowStatus === false) {
                FollowOptimisticHandler(userId, true);
            } else {
                FollowOptimisticHandler(userId, false);
            }
        }

    } else if (type === "boast") {
        let postsArr = [...sellerBoasts];
        let foundPost = postsArr.find(i => i.boast.boastId === postId);
        if (foundPost) {
            let userId = foundPost.userDetails.id;
            let currentFollowStatus = foundPost.userDetails.isFollowing;
            let postsByUserId = postsArr.filter(post => post.userDetails.id === userId);
            postsByUserId.forEach(post => {
                if (post.userDetails.hasOwnProperty('isFollowing')) {
                    post.userDetails.isFollowing = !post.userDetails.isFollowing;
                }
            });
            setSellerBoasts(postsArr);

            if (currentFollowStatus === false) {
                FollowOptimisticHandler(userId, true);
            } else {
                FollowOptimisticHandler(userId, false);
            }
        }

    } else {
        alert("Error while following user, postId not found!");
    }
  }

  const FollowOptimisticHandler = async(userId, type) => {

    if(userId === "" || userId === null){
      console.log("Please provide the UserId first.");
      return;
    }

    if(type){
      try {
        const response = await userServices.followUser(userId);
        console.log("Rss:",response);
      } catch (error) {
        console.error("API Error:", error);
      }

      } else {
        try {
          const response = await userServices.unfollowUser(userId,true);
          console.log("Rss:",response);
        } catch (error) {
          console.error("API Error:", error);
        }
      }
  }

  const AddItemtoCart = async (feedId) => {
    try {
      setIsLoading(true);
      const response = await cartServices.validateCartandCount(feedId);
      // console.log("API Response:", response);
      if (response.statusCode === 200) {
        if(response.data[0].isAdded){
          let sellerWorkshopsArr = [...sellerWorkshops];
          let foundWorkshop = sellerWorkshopsArr.find(i => i._id === feedId);
          let currentCartStatus = foundWorkshop.isInCart;
          foundWorkshop.isInCart = !currentCartStatus;
          setSellerWorkshops(sellerWorkshopsArr);
          dispatch({ type: 'SET_CART_COUNT', payload: response.data[0].totalCartItems });
        }
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
        setIsLoading(false);
    }
  };
    
  return (
    
    <Container>
        {/* {isLoading ? (
            <LoadingOverlay />
        ) : (
            <> */}
            {sellerDetails ? (
                <>
                    <Row className={`boxBody ${styles.stickyRow}`}>
                        <Col lg={1} md={1} sm={2} xs={2}>
                            {sellerDetails.imageUrl ? (
                            <Image src={sellerDetails.imageUrl} roundedCircle className={styles.sellerProfilePic} alt="Profile" />
                            ) : (
                            <Image src="/Content/Images/empty_user.jpg" roundedCircle className={styles.sellerProfilePic} alt="Profile" />
                            )}
                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="minPadding mr-2">
                            <div className={styles.mainHostdiv}>
                            <div className={styles.hostProfileDiv}>
                                <h3 className={styles.hostNameStyleTag}>{sellerDetails.username}</h3>
                                {/* <label className={styles.hostDescStyleTag} style={{visibility: 'hidden'}}>&nbsp;{sellerDetails.bio}</label> */}
                            </div>
                            <div className={styles.hostProfileDiv}>
                                <FollowButton
                                    followUser={sellerDetails.id}
                                    followStatus={sellerDetails.isFollowing ? true : false}
                                    contentOwner={sellerDetails.contentOwner ? true : false}
                                    followOptimizeHandler={followOptimizeHandler}
                                />
                            </div>
                            </div>
                            <div className="mt-1">
                                <label className={styles.sellerCaptionStyle}>{sellerDetails.bio}</label>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="minPadding mt-4">
                            <Tabs
                            activeKey={activeTab}
                            onSelect={(k) => setActiveTab(k)}
                            className={`customTabHeaders ${styles.customTabHeaders}`}
                            >
                                <Tab eventKey="workshops"
                                    title={<span className={`${styles.customTabHeader} ${activeTab === 'workshops' ? styles.PublicProfileClickActive : ''}`}> Workshops </span> } />

                                <Tab eventKey="boasts"
                                    title={<span className={`${styles.customTabHeader} ${activeTab === 'boasts' ? styles.PublicProfileClickActive : ''}`}> Boasts </span> } />
        
                            </Tabs>
                        </Col>
                    </Row>
                    <Row className={`${styles.scrollableContent}`}>
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                            {activeTab === 'workshops' && <SellerWorkshops posts={sellerWorkshops} UserFollowHandler={UserFollowHandler} AddItemtoCart={AddItemtoCart} />}
                            {activeTab === 'boasts' && <SellerBoasts posts={sellerBoasts} UserFollowHandler={UserFollowHandler} MutateBoastLikeStatus={MutateBoastLikeStatus} />}
                        </Col>
                    </Row>
                </>  
            ) : (
                <Row className="boxBody">
                    <p style={{textAlign: 'center'}}>No Host details available.</p>
                </Row>
            )}
            {/* </>
        )} */}
    </Container>
      
  );
};

export default SellersProfiePage;