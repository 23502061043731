import { useRef, useState } from 'react';
import { Container, Row, Col, Button, Accordion } from 'react-bootstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import experienceServices from '../../../Services/experienceServices';
import LoadingOverlay from '../../BaseComponents/LoadingOverlay';
import styles from './ExperienceStudio.module.css';
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { SlArrowLeft } from "react-icons/sl";


const CreateWorkshop = ({ onStatusChange, appendSelectedSequence }) => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [workshopLibrary, setWorkshopLibrary] = useState([]);
    const [selectedMediaItems, setSelectedMediaItems] = useState([]);
    const [currentSelectedMedia, setCurrentSelectedMedia] = useState(null);
    const [failureError, setFailureError] = useState(false);
    const failureErrorRef = useRef(null);
    const fileInputRef = useRef(null);

    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setWorkshopLibrary(files);
    };

    const openFileInput = () => {
        fileInputRef.current.click();
    };

    const handleMoreUpload = (event) => {
        const files = Array.from(event.target.files);
        const uniqueNewMediaItems = files.filter((newItem) => {
            return !workshopLibrary.some((existingItem) => existingItem.name === newItem.name);
        });
        setWorkshopLibrary((prev) => [...prev, ...uniqueNewMediaItems]);
    };

    const clickItemtoSelectMedia = (media) => {
        setSelectedMediaItems((prevSelected) => {
            const isAlreadySelected = prevSelected.includes(media);
            if (isAlreadySelected) {
                return prevSelected.filter((item) => item !== media); // Remove item if already selected
            } else {
                return [...prevSelected, media]; // Add item if not selected
            }
        });
    };
    
    const deleteItemfromSelectedMedia = () => {
        if (!currentSelectedMedia) return; // Ensure there is an item to remove
    
        setSelectedMediaItems((prevSelected) =>
            prevSelected.filter((item) => item !== currentSelectedMedia)
        );
    };
    
    const onDragEnd = (result) => {  
        if (!result.destination) return; // Exit if no destination
    
        const reorderedItems = Array.from(selectedMediaItems); // Copy array
        const [movedItem] = reorderedItems.splice(result.source.index, 1); // Remove dragged item
        reorderedItems.splice(result.destination.index, 0, movedItem); // Insert at new position
    
        setSelectedMediaItems(reorderedItems); // Update state
    };    
    
    
    const clicktoViewSelectMediaItem = (media) => {
        console.log("::: ", media)
          setCurrentSelectedMedia((prevSelectedMedia) =>
            prevSelectedMedia === media ? null : media
          );
          const mediaIndex = selectedMediaItems.findIndex((item) => item === media);
          if (mediaIndex !== -1) {
            // setSequence(mediaIndex + 1);
          }
      };
      
      const saveSequenceMedia = async () => {
        setFailureError("");
        
        if (selectedMediaItems.length === 0) {
            setFailureError('Please select at least one video/image to continue.');
            return;
          }
          
        try {
          setIsLoading(true);
          const response = await experienceServices.saveUploadedContentstoWorkshop(selectedMediaItems, "contents", selectedMediaItems.length);
          // console.log("appendSequenceMediatoDetails Response:", response);
          if (response !== null && response.length > 0) {
            appendSelectedSequence(response);
            onStatusChange('experience-details');
          } else {
            setFailureError(response.message || "Error while processing media files, try after some time.");
          }
        } catch (error) {
          setFailureError(error.message || "Error while processing media files, try after some time.");
        } finally {
          setIsLoading(false);
        }
      };


    return (
        <Container fluid className="mainPad">
            <Row className="d-flex justify-content-between align-items-center">
                <Col xs="auto">
                    <div className={`d-flex align-items-center ${styles.HoverBackOptiondiv}`}>
                        <Link to="/seller/dashboard">
                            <SlArrowLeft className={styles.HoverBackOption} /> &nbsp;&nbsp;&nbsp;&nbsp;
                        </Link>
                        <p className={styles.labelname}>Create a Workshop</p>
                    </div>
                    <div className='text-center'>
                        {failureError && <label ref={failureErrorRef} key={failureError} className={styles.errorMessage} style={{textAlign: 'center'}}>{failureError}</label>}
                    </div>
                </Col>
            </Row>
            
            <Row className={styles.createWSBackdropSlide}>
                <Col xs={12} sm={4} md={4} lg={4}>
                    <Row className={styles.createWSWhiteBackdrop}>
                        {workshopLibrary.length === 0 ? (
                        <Col className="d-flex align-items-center justify-content-center">
                            <input
                                type="file"
                                accept="image/*,video/*"
                                multiple
                                onChange={handleImageUpload}
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                id="fileInput"
                            />
                            <div className={styles.uploadSectiondiv} onClick={openFileInput}>
                                    <div className={styles.uploadIcondiv}>
                                        <img
                                            src="/Content/Images/General/document-upload.png"
                                            className={styles.uploadbtnImage}
                                            alt="upload"
                                        />
                                    </div>
                                    <label htmlFor="fileInput" className={styles.uploadNameTag}>
                                        Upload a video
                                    </label>
                             </div>
                        </Col>
                        ) : (
                            <Col>
                                <Row className="mb-2 d-flex justify-content-between align-items-center">
                                    <Col xs="auto">
                                        <p className={styles.labelname}>Your videos</p>
                                    </Col>
                                    <Col xs="auto">
                                        <Button onClick={openFileInput} className={styles.addMoreButtonStyles} > + Add more </Button>
                                        <input
                                            type="file"
                                            accept="image/*,video/*"
                                            multiple
                                            onChange={handleMoreUpload}
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                        />
                                    </Col>
                                </Row>

                                {/* Scrollable media container */}
                                <div className={styles.mediaContainer}>
                                    <div className={styles.mediaGridWrapper}>
                                        {/* First Row */}
                                        <Row className={`${styles.mediaGrid} flex-nowrap`}>
                                            {workshopLibrary.map((media, index) => 
                                                index % 2 === 0 && (
                                                    <Col key={index} xs="auto" className={styles.mediaItem}>
                                                        <div 
                                                            className={`${styles.mediaItemContainer} position-relative`} 
                                                            onClick={() => clickItemtoSelectMedia(media)}
                                                        >
                                                            {media.type.startsWith('image') ? (
                                                                <img className={styles.mediaImagesSize} alt={media.name} src={URL.createObjectURL(media)} />
                                                            ) : (
                                                                <video className={styles.mediaImagesSize}>
                                                                    <source src={URL.createObjectURL(media)} type={media.type} />
                                                                </video>
                                                            )}

                                                            {/* Selection Badge (Correctly Positioned) */}
                                                            {selectedMediaItems.includes(media) && (
                                                                <div className={`${styles.BadgeSelection} position-absolute top-0 end-0`}>
                                                                    <img 
                                                                        alt="selectionIcon" 
                                                                        src="/Content/Images/icons/selectedBadge.png"
                                                                        className={styles.badgeIcon} 
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Col>
                                                )
                                            )}
                                        </Row>

                                        {/* Second Row */}
                                        <Row className={`${styles.mediaGrid} flex-nowrap`}>
                                            {workshopLibrary.map((media, index) => 
                                                index % 2 !== 0 && (
                                                    <Col key={index} xs="auto" className={styles.mediaItem}>
                                                        <div 
                                                            className={`${styles.mediaItemContainer} position-relative`} 
                                                            onClick={() => clickItemtoSelectMedia(media)}
                                                        >
                                                            {media.type.startsWith('image') ? (
                                                                <img className={styles.mediaImagesSize} alt={media.name} src={URL.createObjectURL(media)} />
                                                            ) : (
                                                                <video className={styles.mediaImagesSize}>
                                                                    <source src={URL.createObjectURL(media)} type={media.type} />
                                                                </video>
                                                            )}

                                                            {/* Selection Badge (Correctly Positioned) */}
                                                            {selectedMediaItems.includes(media) && (
                                                                <div className={`${styles.BadgeSelection} position-absolute top-0 end-0`}>
                                                                    <img 
                                                                        alt="selectionIcon" 
                                                                        src="/Content/Images/icons/selectedBadge.png"
                                                                        className={styles.badgeIcon} 
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Col>
                                                )
                                            )}
                                        </Row>
                                    </div>
                                </div>
                                
                                <div className='mt-2 mb-3 text-center'>
                                    <label onClick={openFileInput} className={styles.filesAddedButtonStyles} > {workshopLibrary.length} Files added </label>
                                </div>

                            </Col>
                        )}
                    </Row>
                </Col>

                <Col xs={12} sm={4} md={4} lg={4} >
                    <Row className={styles.createWSWhiteBackdrop} >
                        {selectedMediaItems.length === 0 ? (
                            <Col className="d-flex align-items-center justify-content-center">
                                <label className={styles.faqsAnswerFont} >Please select one or more videos to create your workshop.</label>
                            </Col>
                        ) : (
                            <Col>
                                <Row>
                                {(currentSelectedMedia || selectedMediaItems[0]) && ( // Directly using inside JSX
                                    <div className={styles.itemContainer}>
                                        {(currentSelectedMedia || selectedMediaItems[0]).type.startsWith('image') ? (
                                            <img
                                                className={styles.currentviewedMediaFile}
                                                alt={(currentSelectedMedia || selectedMediaItems[0]).name}
                                                src={URL.createObjectURL(currentSelectedMedia || selectedMediaItems[0])}
                                            />
                                        ) : (currentSelectedMedia || selectedMediaItems[0]).type.startsWith('video') ? (
                                            <video
                                                className={styles.currentviewedMediaFile}
                                                alt={(currentSelectedMedia || selectedMediaItems[0]).name}
                                                controls
                                                controlsList='nodownload'
                                            >
                                                <source
                                                    src={URL.createObjectURL(currentSelectedMedia || selectedMediaItems[0])}
                                                    type={(currentSelectedMedia || selectedMediaItems[0]).type}
                                                />
                                            </video>
                                        ) : null}

                                        <Row className="d-flex justify-content-between align-items-center">
                                            <Col className="text-truncate" style={{ maxWidth: "80%" }}>
                                                <label 
                                                    className={`${styles.mediaviewNameTag}`} 
                                                    title={(currentSelectedMedia || selectedMediaItems[0]).name}
                                                >
                                                    File name: {(currentSelectedMedia || selectedMediaItems[0]).name}
                                                </label>
                                            </Col>
                                            <Col xs="auto">
                                                <Button className={styles.selectedMediaDeleteButton} onClick={deleteItemfromSelectedMedia}>
                                                    <RiDeleteBin6Line />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                                </Row>
                                <Row>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="media-items" direction="horizontal">
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                className={styles.scrollContainer} // Horizontal scrolling container
                                            >
                                                {selectedMediaItems.map((media, index) => (
                                                    <Draggable key={media.name} draggableId={media.name} index={index}>
                                                        {(dragProvided) => (
                                                            <div
                                                                ref={dragProvided.innerRef}
                                                                {...dragProvided.draggableProps}
                                                                {...dragProvided.dragHandleProps}
                                                                className={styles.mediaItem}
                                                                onClick={() => clicktoViewSelectMediaItem(media)}
                                                            >
                                                                {/* Media Content (Image/Video) */}
                                                                {media.type.startsWith('image') ? (
                                                                    <img className={styles.selectedMediaImagesSize} alt={media.name} src={URL.createObjectURL(media)} />
                                                                ) : media.type.startsWith('video') ? (
                                                                    <video className={styles.selectedMediaImagesSize} alt={media.name}>
                                                                        <source src={URL.createObjectURL(media)} type={media.type} />
                                                                    </video>
                                                                ) : null}

                                                                {/* Sequence Number Below Media */}
                                                                <div className={styles.sequenceNumber}>
                                                                    {index + 1}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                </Row>
                                
                                {isLoading ? (
                                    <LoadingOverlay />
                                ) : (
                                    <Row className="mt-2 justify-content-end">
                                        <Col xs="auto">
                                            <Link to="/seller/dashboard">
                                                <Button variant="outline-primary">&nbsp;&nbsp; Back &nbsp;&nbsp;</Button>&nbsp;&nbsp;
                                            </Link>
                                        </Col>
                                        <Col xs="auto">
                                        <Button variant="primary" onClick={saveSequenceMedia}>&nbsp;&nbsp; Next &nbsp;&nbsp;</Button>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        )}
                    </Row>
                </Col>

                <Col xs={12} sm={4} md={4} lg={4} >
                    <Row className={styles.createWSWhiteBackdrop} >
                        <div>
                            <h1 className={styles.faqsHeader}>How to create a Workshop</h1>

                            {/* <label className={styles.faqsQuestionFont} >1. Upload your video </label> */}

                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                    <Accordion.Header className={styles.WSAccordionHeader} >Upload your video.</Accordion.Header>
                                        {/* <Accordion.Body className={styles.WSAccordionBody}>
                                            Other disclosers of information
                                        </Accordion.Body> */}
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="0">
                                    <Accordion.Header className={styles.WSAccordionHeader} >To upload more videos, click ‘+ Add more’. You can upload up to 10 videos that can be joined together as a series, and sold as one Workshop.</Accordion.Header>
                                        {/* <Accordion.Body className={styles.WSAccordionBody}>
                                            Other disclosers of information
                                        </Accordion.Body> */}
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion defaultActiveKey="2">
                                    <Accordion.Item eventKey="0">
                                    <Accordion.Header className={styles.WSAccordionHeader} >Select the video or videos you want to use for your final workshop by clicking on the files in the first panel.</Accordion.Header>
                                        {/* <Accordion.Body className={styles.WSAccordionBody}>
                                            Other disclosers of information
                                        </Accordion.Body> */}
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion defaultActiveKey="3">
                                    <Accordion.Item eventKey="0">
                                    <Accordion.Header className={styles.WSAccordionHeader} >If you’ve selected multiple videos, select them in the sequence you’d like them to appear. When you’re done click ‘Next’.</Accordion.Header>
                                        {/* <Accordion.Body className={styles.WSAccordionBody}>
                                            Other disclosers of information
                                        </Accordion.Body> */}
                                    </Accordion.Item>
                                </Accordion>
                        </div>
                    </Row>
                </Col>
            </Row>

        </Container>
    );
};

export default CreateWorkshop;
