import { useState, useEffect, useContext } from 'react';
import { Container, Row, Badge } from 'react-bootstrap';
import hubStyles from './hub.module.css';
// import UserPurchasesCount from '../BaseComponents/UserPurchasesCount';
// import UserRefferalsCount from '../BaseComponents/UserRefferalsCount';
// import LikesCount from '../BaseComponents/LikesCount';
import PurchasedExperiences from './PurchasedExperiences';
import hubServices from '../../Services/hubServices';
import userServices from '../../Services/userServices';
import AuthContext from '../Context/AuthContext';



const HubOverview = ({ overviewType }) => {

    const { setIsLoading } = useContext(AuthContext);
    const [purchasedExperiences, setPurchasedExperiences] = useState([]);
    // const [purchasedExperiencesCount, setPurchasedExperiencesCount] = useState(0);
    // const [totalLikesCount, setTotalLikesCount] = useState(0);
    const [experiencesFetched, setExperiencesFetched] = useState(false);
      
  useEffect(() => {
    if (!experiencesFetched) {
      async function fetchPurchasedExperiences() {
        try {
          setIsLoading(true);
          const response = await hubServices.fetchPurchasedExperiences();
          //console.log("fetchPurchasedExperiences Response:", response);
          
          if (response !== null && response.statusCode === 200) {
            if(response.data !== null && response.data.length >0) {

              // Custom function to format date as dd/mm/yyyy
              const formatDate = (date) => {
                const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
                return date.toLocaleDateString('en-GB', options);
              };

            // Iterate over each purchase experience in response.data
            const finalResponse = response.data.map(experience => {
              const dobDate = experience.datePurchased ? new Date(experience.datePurchased) : null;

              if (dobDate) {
                  return {
                      ...experience,
                      datePurchased: formatDate(dobDate)
                  };
              } else {
                  return experience;
              }
            });
  
            setPurchasedExperiences(finalResponse);
            setExperiencesFetched(true);
            }
          }
        } catch (error) {
          console.error("fetchPurchasedExperiences Error:", error);
          setExperiencesFetched(false);
        }finally{
          setIsLoading(false);
        }
      }
      fetchPurchasedExperiences();
    }

    // async function fetchPurchasedShopsCount() {
    //   try {
    //     const response = await userServices.fetchPurchasedExperiencesCount();
    //     // console.log("fetchPurchasedShopsCount Response:", response);
    //     if (response !== null && response.statusCode === 200) {
    //       if (
    //         response.data !== null &&
    //         response.data !== "" &&
    //         response.data[0] !== null &&
    //         response.data[0].count !== null
    //       ) {
    //         setPurchasedExperiencesCount(response.data[0].count);
    //       }
    //     }
    //   } catch (error) {
    //     console.error("API Error:", error);
    //   }
    // }
    // fetchPurchasedShopsCount();

    // async function fetchTotalLikesCount() {
    //   try {
    //     const response = await userServices.fetchTotalLikesCount();
    //     // console.log("fetchPurchasedShopsCount Response:", response);
    //     if (response !== null && response.statusCode === 200) {
    //       if (
    //         response.data !== null &&
    //         response.data !== "" &&
    //         response.data[0] !== null &&
    //         response.data[0].count !== null
    //       ) {
    //         setTotalLikesCount(response.data[0].count);
    //       }
    //     }
    //   } catch (error) {
    //     console.error("API Error:", error);
    //   }
    // }
    // fetchTotalLikesCount();

  }, [experiencesFetched, setIsLoading]);


  const followHandlerOptimizer = async (userId, type) => {

    if(userId === "" || userId === null){
      alert("Please provide the UserId first.");
      return;
    }

    let purchasedWorkshopDetails = [...purchasedExperiences];
    let foundWorkshopbyId = purchasedWorkshopDetails.find(i => i.contentOwner === userId);
    let currentFollowStatus = foundWorkshopbyId.isFollowing;
    foundWorkshopbyId.isFollowing = !currentFollowStatus;
    setPurchasedExperiences(purchasedWorkshopDetails);

    try {
      setIsLoading(true);
      if (currentFollowStatus) {
        await userServices.unfollowUser(userId, true);
      } else {
        await userServices.followUser(userId);
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <Container>
        <Row className={`d-flex flex-column align-items-right `}>

        {/* <h5 className={hubStyles.ActionHeader}>Overview</h5> */}
        
        {/* <Row>
          <Col xs={4} md={4}>
            <UserPurchasesCount count={purchasedExperiencesCount} />
          </Col>
          <Col xs={4} md={4}>
            <LikesCount count={totalLikesCount} />
          </Col>
          <Col xs={4} md={4}>
            <UserRefferalsCount count='17' />
          </Col>
        </Row> */}

        <h5 className={`mt-3 ${hubStyles.ActionHeader}`} >Your Workshops <Badge pill className={hubStyles.workshopsCountBg}>{purchasedExperiences.length}</Badge></h5>

        <Row className="mt-2 RightPadding">
            <PurchasedExperiences experiences={purchasedExperiences} handleUser={followHandlerOptimizer} overviewType={overviewType} />
        </Row>

        <br/>
        <br/>
        
        </Row>
    </Container>
      
  );
};

export default HubOverview;