import React from 'react';
import { Container, Image, Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";
import styles from './publicProfile.module.css';



const SellerWorkshops = ({ posts, UserFollowHandler, AddItemtoCart }) => {

    // console.log("Posts", posts);

  return (
    <Container className={`mb-4 noPadding`}>
        <div className="pb-2">
           <h5 className={`mt-3 mb-2 ${styles.mainTitleHeader}`}>
                Workshops &nbsp;
                {posts && (
                    <Badge pill className={styles.workshopsCountBg}>
                        {posts.length}
                    </Badge>
                )}
            </h5>
        </div>
        
      {posts && posts.length > 0 ? (
        posts.map((post, index) => (
        <React.Fragment key={index}>
        <div className={`mb-4 ${styles.FeedBody}`}>
        <div className={`${styles.feedContainerdiv}`} key={post._id}>
            <Link to={`/product-details/workshop/${post._id}`}>
                <Image src={post.cover || '/Content/Images/General/no_thumbnail.png'} alt="feed_thumbnail" fluid roundedCircle className={styles.feedThumbnail} />
            </Link>
            <div className={styles.feedContent}>
                <Image src="/Content/Images/icons/document-text.png" className={styles.feedDescTextImgSize} />
                    &nbsp;
                <p className={styles.feedContentTextColorFont}>{post.description}</p>
            </div>

        <div className={styles.feedContentSide}>
            <div className={styles.feedContentdiv}>
                <div className='followUsersetupDiv mb-2' >
                {post.userDetails && (
                <Link to={`/seller/public-profile/${post?.userDetails?.username}`}>
                    <div className={styles.feedIconwithCountsdiv}>
                    <div className={styles.feedIconFollowAdddiv}>
                        <Image 
                        src={post.userDetails.imageUrl ? post.userDetails.imageUrl : '/Content/Images/empty_user.jpg'} 
                        className={styles.feedIconFollowImg} 
                        />
                        {!post.userDetails.contentOwner && (
                        <Link onClick={() => { UserFollowHandler(post.userDetails.id, 'workshop') }}>
                            <Image 
                                src={post.userDetails.isFollowing ? "/Content/Images/icons/icons-cancel.png" : "/Content/Images/icons/icons-plus.png"} 
                                className={styles.feedIconFollowAddIcon} 
                            />
                        </Link>
                        )}
                    </div>
                    </div>
                </Link>
                )}
                </div>

                <div className={styles.feedIconwithCountsdiv}>
                    <Image src="/Content/Images/icons/icons-rating.png" className={styles.feedIconImg} />
                    <label className={styles.feedUserContentFont}>{(post.ratings).toFixed(0) ?? 0}</label>
                </div>
                <div className={styles.feedIconwithCountsdiv}>
                    <Image src="/Content/Images/icons/totalsalesCount.png" className={styles.feedIconImg} />
                    <label className={styles.feedUserContentFont}>{post.purchasedCount ?? 0}</label>
                </div>
                <div className={styles.feedIconwithCountsdiv}>
                    <Image src="/Content/Images/icons/icons-dollar-coin.png" className={styles.feedIconImg} />
                    <label className={styles.feedUserContentFont}>{post.currency ?? '$'} {post.price ?? 0}</label>
                </div>
                <div className={styles.feedIconwithCountsdiv}>
                    {(!post.userDetails.contentOwner && !(post.isPurchased || post.isInCart)) ? (
                        <Image
                            src="/Content/Images/icons/icons-buy.png"
                            className={styles.feedIconImg}
                            onClick={() => AddItemtoCart(post._id)}
                        />
                    ) : (
                        <Image
                            src="/Content/Images/icons/add_shopping.png"
                            className={`${styles.feedIconImg} ${styles.disabled}`}
                            style={{ pointerEvents: 'none', opacity: 0.5 }}
                        />
                    )}
                    <label className={styles.feedUserContentFont} style={{visibility: 'hidden'}}>{post.cartCount ?? 0}</label>
                </div>
                    
            </div>
        </div>

        </div>
        </div>
    </React.Fragment>
    ))
    ) : (
        <div className={`${styles.FeedBody}`}>
            <p style={{ textAlign: 'center', margin: '0', fontWeight: 600, }} >No workshops are available.</p>
        </div>
    )}
    </Container>
    
  );
};

export default SellerWorkshops;