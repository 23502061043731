import { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button, Image, Toast } from 'react-bootstrap';
import { Link } from "react-router-dom";
import styles from './ExperienceStudio.module.css';
import { FaAngleDown } from "react-icons/fa";
import experienceServices from "../../../Services/experienceServices";
import LoadingOverlay from '../../BaseComponents/LoadingOverlay';


const PublishExperience = ({ onStatusChange, previewExperienceForm, onPublishSuccess }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [experienceForm, setExperienceForm] = useState(null);
    const [isPublishChecked, setIsPublishChecked] = useState(false);
    const [isBankDataAvailable, setIsBankDataAvailable] = useState(false);
    const [userProfileData, setUserProfileData] = useState(null);
    const [showDescription, setShowDescription] = useState(true);
    const [showMaterial, setShowMaterial] = useState(true);
    
    const toggleShowDescription = () => setShowDescription(!showDescription);
    const toggleShowMaterial = () => setShowMaterial(!showMaterial);
    
    const draftIdRef = useRef(localStorage.getItem('draftWID'));

    useEffect(() => {
      const getDraftWorkshopDetails = async (draftId) => {
        try {
          setIsLoading(true);
          const response = await experienceServices.getDraftWorkshop(draftId);
          if (response.statusCode === 200) {
            if (response.data && response.data[0] !== null) {
              setExperienceForm(response.data[0]);
              await validateBankDetails();
            } else {
              setErrorMessage(response.message || "Publish failed, Please try after some time.");
            }
          } else {
            setErrorMessage(response.message || "Publish failed, Please try after some time.");
          }
        } catch (error) {
          console.error("API Error:", error);
          setErrorMessage("API error occurred, Please contact support.");
        } finally {
          setIsLoading(false);
        }
      };
  
      const validateBankDetails = async () => {
        try {
          setIsLoading(true);
          const response = await experienceServices.validateSellerBankDetails();
          if (response.statusCode === 200) {
            setIsBankDataAvailable(response.data?.[0]?.available || false);
          } else {
            setIsBankDataAvailable(false);
            setErrorMessage(response.message || "Internal error, Please contact support.");
          }
        } catch (error) {
          console.error("API Error:", error);
          setIsBankDataAvailable(false);
          setErrorMessage("API Error occurred.");
        } finally {
          setIsLoading(false);
        }
      };
  
      const draftId = draftIdRef.current;
      if (draftId) {
        getDraftWorkshopDetails(draftId);
      }
  
      const profileData = localStorage.getItem('userProfileData');
      if (profileData) {
        setUserProfileData(JSON.parse(profileData));
      }
    }, []);
  
    const publishingExperience = async () => {
      setErrorMessage("");

      const draftId = draftIdRef.current;
      if (!isBankDataAvailable) {
        setErrorMessage('You must check payment details to proceed.');
        return;
      } else if (!draftId) {
        setErrorMessage('Internal error, Please check the unpublished ID.');
        return;
      } else {
        setErrorMessage("");
      }

      if (!isPublishChecked) {
        setErrorMessage('Please agree to the terms & conditions before you publish.');
        return;
      } else {
        setErrorMessage('');
      }
  
      try {
        setIsLoading(true);
        const response = await experienceServices.publishWorkshop(draftId);
        // console.log("publishingExperience Response:", response);

        if (response.statusCode === 200) {
          if(response.data !== null && response.data[0] !== null && response.data[0].published){
            localStorage.removeItem('draftWID');
            onStatusChange('success-preview');
            previewExperienceForm(experienceForm);
            onPublishSuccess(true);
          } else {
            onStatusChange('publish-experience');
            setErrorMessage(response.message || "Publish failed, Please try after some time.");
          }
        } else {
          onStatusChange('publish-experience');
          setErrorMessage(response.message || "Publish failed, Please try after some time.");
        }
  
      } catch (error) {
        console.error("publishingExperience Error:", error);
        setErrorMessage('Internal error, workshop creation failed.');
      } finally {
        setIsLoading(false);
      }
    };
  
    const editWorkshop = () => {
      localStorage.setItem('isEditWorkshop', true);
      onStatusChange('experience-details');
    };
  
    const stripeOnBorading = () => {
      onStatusChange('create-account');
    };

  
  return (
    <Container fluid className="mainPad">

      {isLoading ? (
        <LoadingOverlay />
      ) : (
        experienceForm && Object.keys(experienceForm).length > 0 ? (
          <Row className="mt-2 mb-2">
            <Col md={8} xs={12}>
              <Row className={styles.ESWhiteBackdrop}>
                {errorMessage && <div className={styles.errorMessage} style={{ textAlign: "center" }}>{errorMessage}</div>}

                <Row>
                  <div className="d-flex align-items-center">
                    {userProfileData && userProfileData.imageUrl ? (
                      <>
                        <Image
                          src={userProfileData.imageUrl ? userProfileData.imageUrl : '/Content/Images/empty_user.jpg'}
                          alt="Profile"
                          roundedCircle
                          className={`roundedUser ${styles.previewProfileImg}`}
                        />
                        <div className={`${styles.flexStyleforUserdiv}`}>
                          <h3 className={styles.productSellername}>{userProfileData.name}</h3>
                          <label className={styles.productSellerdesc}>{userProfileData.bio}</label>
                        </div>
                      </>
                    ) : (
                      <div className={styles.productSellername}>No user data available</div>
                    )}
                  </div>
                </Row>

                <Row className="mt-3 mb-1">
                  <h3 className={styles.productCaption}>{experienceForm.caption}</h3>
                </Row>

                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className={styles.workshopContainerdiv}>
                    {experienceForm.icon ? (
                      <img
                        src={experienceForm.icon ? experienceForm.icon : '/Content/Images/noImageBG.jpg'}
                        className={styles.workshopThumbnailImage}
                        alt="thumbnail"
                      />
                    ) : (
                      <img
                        className={`d-block w-100 ${styles.workshopThumbnailImage}`}
                        src="/Content/Images/SellerExpStudio/noImageBG.jpeg"
                        alt="thumbnail"
                      />
                    )}
                  </div>
                </Col>

                <Row className="mt-4">
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <div className={`overlap-group ${styles.productDetailsBox}`}>
                      <div className={`mb-3 ${styles.ProductDetailsNameTagSetup}`}>
                        <label className={styles.ProductBoxLabel}>Duration</label>
                        <div className={styles.IconSetup}>
                          <Image
                            src='/Content/Images/icons/icons-sundial.png'
                            alt="duration_icon"
                            className={styles.IconSetImage}
                          />
                        </div>
                      </div>
                      <label className={styles.ProductBoxlabelValue}>{experienceForm.duration}&nbsp;Mins</label>
                    </div>
                  </Col>

                  <Col xs={4} sm={4} md={4} lg={4}>
                    <div className={`overlap-group ${styles.productDetailsBox}`}>
                      <div className={`mb-3 ${styles.ProductDetailsNameTagSetup}`}>
                        <label className={styles.ProductBoxLabel}>Price</label>
                        <div className={styles.IconSetup}>
                          <Image
                            src='/Content/Images/icons/icons-dollar-coin.png'
                            alt="price_icon"
                            className={styles.IconSetImage}
                          />
                        </div>
                      </div>
                      <label className={styles.ProductBoxlabelValue}>{experienceForm.currency}&nbsp;{experienceForm.price}</label>
                    </div>
                  </Col>

                  <Col xs={4} sm={4} md={4} lg={4}>
                    <div className={`overlap-group ${styles.productDetailsBox}`}>
                      <div className={`mb-3 ${styles.ProductDetailsNameTagSetup}`}>
                        <label className={styles.ProductBoxLabel}>Rating</label>
                        <div className={styles.IconSetup}>
                          <Image
                            src='/Content/Images/icons/icons-rating.png'
                            alt="rating_icon"
                            className={styles.IconSetImage}
                          />
                        </div>
                      </div>
                      <label className={styles.ProductBoxlabelValue}>N/A</label>
                    </div>
                  </Col>
                </Row>

                <br /><br />
                <Row className="mt-4 mb-4">
                  <Col>
                    <div className={styles.toggleDropdowndiv}>
                      <label className={styles.accordionHeader}>Description</label>
                      <Button onClick={toggleShowDescription} className={styles.toggleDropdownClick}>
                        <FaAngleDown />
                      </Button>
                    </div>
                    <Toast show={showDescription} onClose={toggleShowDescription} className={styles.toggleToastStyles}>
                      <label className={styles.accordionDescription}>{experienceForm.desc}&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    </Toast>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <div className={styles.toggleDropdowndiv}>
                      <label className={styles.accordionHeader}>Extra Material</label>
                      <Button onClick={toggleShowMaterial} className={styles.toggleDropdownClick}>
                        <FaAngleDown />
                      </Button>
                    </div>
                    <Toast show={showMaterial} onClose={toggleShowMaterial} className={styles.toggleToastStyles}>
                      <label className={styles.accordionDescription}>{experienceForm.addInfo}&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    </Toast>
                  </Col>
                </Row>
              </Row>
            </Col>

            <Col md={4} xs={12}>
              <div className="mb-2 mt-2">
                <Col xs="auto">
                  {!isBankDataAvailable ? (
                    <div>
                      <div className={styles.PaymentTermsHeaderDiv}>
                        <h2 className={styles.PaymentTermsHeaderFont}>Set up your banking details</h2>
                      </div>
                      <div className={styles.PaymentTermsdiv}>
                        <label className={styles.PaymentTermsfont}>
                          It’s now time to set up a Stripe account to receive payments. Stripe is required by regulators to verify your identity and your business information (if applicable).
                          The information they are required to collect differs from country to country but will include:

                          <ul className={`mt-3 mb-3 ${styles.unOrderListStyles}`}>
                            <li>Business details including name, address, and phone number</li>
                            <li>Type of business - if you do not have a registered business, select Individual</li>
                            <li>The product/category being sold</li>
                            <li>Bank account details</li>
                            <li>Identity documents</li>
                          </ul>

                          If you change the nature of your business, or any other important information, you must promptly update your Stripe account.

                          By submitting your payment information you agree to the Terms and Conditions including the payment terms available at hapifyr <Link className={styles.LinkTagDirect} to="/info/terms-and-conditions" target='_blank' rel='noopener noreferrer'>Terms and Conditions</Link>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.PaymentTermsdiv}>
                      <label className={styles.PaymentTermsLabel}>
                        <input 
                          type="checkbox"
                          name="PublishCheck"
                          id="inline-checkbox-1"
                          checked={isPublishChecked}
                          onChange={(e) => setIsPublishChecked(e.target.checked)}
                          className={styles.CustomCheckbox}
                        />
                        <label>
                          By publishing my workshop I agree to the 
                            &nbsp;
                            <Link 
                              className={styles.LinkTagDirect} 
                              to="/info/terms-and-conditions" 
                              target='_blank' 
                              rel='noopener noreferrer'
                            >
                              Terms and Conditions
                            </Link> 
                            &nbsp;
                          including payment terms available at Hapifyr.
                        </label>
                      </label>
                    </div>
                  )}
                </Col>
              </div>
              <Row className={styles.PaddingSetupRow}>
                <Row className="mt-3 justify-content-end">
                  {!isBankDataAvailable && (
                    <Col xs="auto">
                      <Button onClick={stripeOnBorading} className={styles.PublishSubmitButtons}>Payment Details</Button>&nbsp;&nbsp;
                    </Col>
                  )}
                  <Col xs="auto">
                    <Button className={styles.PublishSubmitButtonEdit} onClick={editWorkshop}>&nbsp; Edit &nbsp;</Button>&nbsp;&nbsp;
                  </Col>
                  <Col xs="auto">
                    <Button onClick={publishingExperience} className={styles.PublishSubmitButtons} disabled={!isBankDataAvailable}>&nbsp; Publish &nbsp;</Button>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row className="mt-2 mb-2 viewportHt">
            <Row className={styles.ESWhiteBackdrop}>
              <h3 className={styles.errorMessage} style={{ textAlign: 'center', fontWeight: '800' }}>Workshop data is not available.</h3>
            </Row>
          </Row>
        )
      )}

    <br/><br/><br/>
    </Container>
  );
};

export default PublishExperience;