import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Header from './Navbar/Header';
import ProductOverview from './Buy/ProductOverview';
import { CartProvider } from './Navbar/CartContext';


const ProductDetails = () => {

  const [loginStatus] = useState('loggedIn');
  const { contentType, contentId } = useParams();
  const [searchParams] = useSearchParams();

  // Extract `?ref=` from Product URL
  const productReferral = searchParams.get("ref") || null;
  // console.log("Referral ID:", productReferral);


  return (
    <CartProvider>

      <div className="ProductDetailsDiv stickySidebar">
        <Header loginStatus={loginStatus} openedProductId={contentId} />
        <ProductOverview contentType={contentType} contentId={contentId} refferalId={productReferral} />
      </div>
    
    </CartProvider>
  );
}

export default ProductDetails;
