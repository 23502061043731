import axiosAPI from "./axiosAPI";


const userServices = {

    getUserFeeds: async (startIndex, filters) => {
        try {
            // console.log("getUserFeeds:: ", filters);
            let category = null;
            let feedType = null;
            let price = null;
            let length = null;
            let rating = null;
            let reviewCount = null;
            let forSale = null;
            let user = null;

            if(filters) {

              if (filters.category) {
                if(filters.category === "all")
                  category = null;
                else
                category = filters.category;
              }
              if (filters.workshop) {
                feedType = filters.workshop;
              }
              if (filters.price) {
                price = filters.price;
              }
              if (filters.length) {
                length = filters.length;
              }              
              if (filters.rating) {
                rating = filters.rating;
              }
              if (filters.reviewCount) {
                reviewCount = filters.reviewCount;
              } 
              if (filters.forSale) {
                forSale = filters.forSale;
              }
              if (filters.user) {
                user = filters.user;
              }

            } else {
              filters = null;
            }

          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.post(`/experiences/feeds`, {
            page: startIndex,
            interests: category,
            feedType: feedType,
            priceRange: price,
            length: length,
            ratingOrder: rating,
            reviewOrder: reviewCount,
            feedStatus: forSale,
            sellers: user,
          });

          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      followUser: async (userId) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.post(`/profile/follow`, {
            following: userId,
          });
          // console.log("followUser:: ", response.data)
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      unfollowUser: async (userId, revoke) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.post(`/profile/follow`, {
            following: userId,
            revoke: revoke,
          });
          
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      removeUser: async (userId) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.delete(`/profile/remove/${userId}`);
          
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      blockUser: async (userId) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/profile/block/${userId}`);
          
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      unblockUser: async (userId) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/profile/unblock/${userId}`);
          
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      fetchFollowersCount: async () => {
        try {
          
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/profile/count-followers`);

          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      fetchTotalSalesCount: async () => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/experiences/total-sales`);
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      fetchPurchasedExperiencesCount: async () => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/experiences/total-active-purchase`);
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      fetchTotalLikesCount: async () => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/experiences/total-likes`);
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      updateUserProfileBasic: async (dob) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.patch(`/profile/users`, {
            dob: dob,
          });
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      updateUsername: async (username) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.patch(`/profile/users`, {
            username : username,
          });
          
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      updateUserPassword: async (oldPassword, newPassword) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.patch(`/profile/users`, {
            oldPassword : oldPassword,
            password : newPassword,
          });
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      updateUserProfileBio: async (bio) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.patch(`/profile/users`, {
            bio: bio,
          });
          console.log(response);
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      updateUserProfileContact: async (country, phoneNumber) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.patch(`/profile/users`, {
            country: country,
            phone: phoneNumber,
          });
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      updateUserProfileInterests: async (interests) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.patch(`/profile/users`, {
            interests: interests,
          });
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      checkUsernameAvailability: async (username) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.baseInstance.post(`/profile/valid-uid`, {
            username: username,
          });
    
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      headerSearchOptimizer: async (type, searchQuery, index) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/common/discover/?searchType=${type}&searchQuery=${searchQuery}&p=${index}`);
          
          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      stripeDashboard: async () => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/upm/stripe/dashboard`);
          // console.log("stripeDashboard: ", response.data.path);
          return response.data;

        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      fetchFollowersDetails: async () => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/profile/followers`);
          // console.log("fetchFollowersDetails: ", response.data.path);
          return response.data;

        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      fetchFollowingsDetails: async () => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/profile/following`);
          // console.log("fetchFollowingsDetails: ", response.data.path);
          return response.data;

        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      fetchBlockedUsersDetails: async () => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/profile/blocked`);
          // console.log("fetchBlockedUsersDetails: ", response.data.path);
          return response.data;

        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },


};

export default userServices;