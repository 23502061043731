import { useEffect, useState, useRef } from "react";
import "@sendbird/uikit-react/dist/index.css";
import './SBStyles.css';
import SendbirdChat from "@sendbird/chat";
import { timestampToTime } from '../../Utils/messageUtils';
import {
    GroupChannelModule,
    GroupChannelFilter,
    GroupChannelListOrder,
} from '@sendbird/chat/groupChannel';
import SearchUserstoChat from "./SearchUserstoChat";
import { Button,Image, Badge } from 'react-bootstrap';
import baseStyles from './Chat.module.css';
import LoadingOverlay from "../BaseComponents/LoadingOverlay";
import config from "../../config/config.js";

const APP_ID = config.sendbirdAppId;
let sb;

const SendBirdChatLists = ({ changeType, changeUrl, chatUser, onOpenUsers, activeForm, boardType }) => {
    
    // console.log("boardType::", boardType);
    const sendbirdId = localStorage.getItem('sendbirdUserId');
    const sendbirdName = localStorage.getItem('sendbirdNickname');
    const [start, setStart] = useState(true);
    const [state, updateState] = useState({
        applicationUsers: [],
        groupChannelMembers: [],
        currentlyJoinedChannel: null,
        messages: [],
        channels: [],
        messageInputValue: "",
        userNameInputValue: sendbirdName,
        userIdInputValue: sendbirdId,
        channelNameUpdateValue: "",
        settingUpUser: true,
        file: null,
        messageToUpdate: null,
        messageCollection: null,
        loading: false,
        error: false,
        isReactions: false,
        currentMessage: {}
    });

    useEffect(() => {
        if(start){
            setupUser();
        }
    });
    
    const setupUser = async () => {
        const { userNameInputValue, userIdInputValue } = state;
        
        const sendbirdInit = await SendbirdChat.init({
            appId: APP_ID,
            localCacheEnabled: true,
            modules: [new GroupChannelModule()]
        });

        await sendbirdInit.connect(userIdInputValue);
        await sendbirdInit.setChannelInvitationPreference(true);

        const userUpdateParams = {};
        userUpdateParams.nickname = userNameInputValue;
        userUpdateParams.userId = userIdInputValue;
        await sendbirdInit.updateCurrentUserInfo(userUpdateParams);

        sb = sendbirdInit;
        updateState({ ...state, loading: true });
        const [channels, error] = await loadChannels(channelHandlers);
        if (error) {
            return onError(error);
        }
        
        updateState({ ...state, channels: channels, loading: false, settingUpUser: false });
        setStart(false);
    }

    
    // Helpful functions that call Sendbird
    const loadChannels = async (channelHandlers) => {
        
        const groupChannelFilter = new GroupChannelFilter();
        groupChannelFilter.includeEmpty = true;
        
        const collection = sb.groupChannel.createGroupChannelCollection({
            filter: groupChannelFilter,
            order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
        });
        
        collection.setGroupChannelCollectionHandler(channelHandlers);

        const channels = await collection.loadMore();
        // console.log('ALL Cannels::', channels);
        return [channels, null];
    }

    const [activeTab, setActiveTab] = useState(activeForm ? activeForm : 'users');
    const handleTabClick = (tab) => {
      setActiveTab(tab);
      
      if(tab === "create-group"){
        onOpenUsers(true);
        changeType("create-group");
      }
    };

    //need to access state in message received callback
    const stateRef = useRef();
    stateRef.current = state;

    const channelRef = useRef();

    const channelHandlers = {
        onChannelsAdded: (context, channels) => {
            const updatedChannels = [...channels, ...stateRef.current.channels];
            updateState({ ...stateRef.current, channels: updatedChannels, applicationUsers: [] });
        },
        onChannelsDeleted: (context, channels) => {
            const updatedChannels = stateRef.current.channels.filter((channel) => {
                return !channels.includes(channel.url);
            });
            updateState({ ...stateRef.current, channels: updatedChannels });

        },
        onChannelsUpdated: (context, channels) => {
            const updatedChannels = stateRef.current.channels.map((channel) => {
                const updatedChannel = channels.find(incomingChannel => incomingChannel.url === channel.url);
                if (updatedChannel) {
                    return updatedChannel;
                } else {
                    return channel;
                }
            });

            updateState({ ...stateRef.current, channels: updatedChannels });
        },
    }

    const scrollToBottom = (item, smooth) => {
        item?.scrollTo({
            top: item.scrollHeight,
            behavior: smooth
        })
    }

    useEffect(() => {
        scrollToBottom(channelRef.current)
    }, [state.currentlyJoinedChannel])

    useEffect(() => {
        scrollToBottom(channelRef.current, 'smooth')
    }, [state.messages])

    const onError = (error) => {
        updateState({ ...state, error: error.message });
        // console.log(error);
    }

    const handleJoinChannel = async (url, userId) => {
        changeType("one-to-one");
        changeUrl(url);
        chatUser(userId);
    }

    const chatwithUser = async (url) => {
        handleJoinChannel(url);
    }
    
    const handleLoadMemberSelectionList = async () => {
        updateState({ ...state, currentlyJoinedChannel: null });
        const [users, error] = await getAllApplicationUsers();
        if (error) {
            return onError(error);
        }
        updateState({ ...state, currentlyJoinedChannel: null, applicationUsers: users, groupChannelMembers: [sb.currentUser.userId] });
    }


    if (state.loading) {
        return <LoadingOverlay />;
    }

    if (state.error) {
        return <div className="error">{state.error} check console for more information.</div>
    }


    return (
        <>
            <SearchUserstoChat chatwithUser={chatwithUser} handleTabClick={handleTabClick} />
            <ChannelList
                channels={state.channels}
                handleJoinChannel={handleJoinChannel}
                activeTab={activeTab}
                handleTabClick={handleTabClick}
                sendbirdId={sendbirdId}
                handleCreateChannel={handleLoadMemberSelectionList}
                handleLoadMemberSelectionList={handleLoadMemberSelectionList}
                boardType={boardType}
            />
        </>
    );
};

// Chat UI Components
const ChannelList = ({
    channels,
    handleJoinChannel,
    activeTab,
    handleTabClick,
    sendbirdId,
    boardType
}) => {
    return (
        <div className='mt-1'>
            <div className="channel-type">
                <ul className="nav nav-tabs d-flex align-items-center justify-content-between flex-row">

                    <li className="nav-item">
                    <Button variant={`outline-primary ${baseStyles.MsgBtnClick} ${activeTab === 'users' ? baseStyles.MsgBtnClickActive : ''}`}
                        onClick={() => handleTabClick('users')} >
                        Users
                    </Button>
                    </li>
                    <li className="nav-item">
                    <Button variant={`outline-primary ${baseStyles.MsgBtnClick} ${activeTab === 'groups' ? baseStyles.MsgBtnClickActive : ''}`}
                        onClick={() => handleTabClick('groups')} >
                        Groups
                    </Button>
                    </li>
                    {/* <li className="nav-item">
                    <Button className={`${baseStyles.createGroupButton}`}
                        onClick={() => handleTabClick('create-group')} >
                        Create Group
                    </Button>
                    </li> */}

                    {/* {boardType === 'invitations' && ( */}
                    <li className="nav-item">
                        <Button
                        variant={`outline-primary ${baseStyles.MsgBtnClick} ${activeTab === 'watchparties' ? baseStyles.MsgBtnClickActive : ''}`}
                        onClick={() => handleTabClick('watchparties')}
                        >
                        Watch Parties
                        </Button>
                    </li>
                    {/* )} */}
                    
                </ul>   
            </div>
            <div className='channel-lists'>

            <div className={`${baseStyles.MsglistCardBody}`}>
                {activeTab === 'users' ? (
                <div>
                    {channels.length === 0 || channels.every(channel => channel.customType !== 'dm') ? (
                        <div className={baseStyles.emptyUsersDiv}>
                            <h3>No chats available.</h3>
                        </div>
                    ) : (
                        <ul className="list-group">
                            {channels.map((channel, index) => (
                                channel.customType !== null && channel.customType === 'dm' ? (
                                    <li key={index} className={`list-group-item ${baseStyles.MsglistItem}`} 
                                        onClick={() => {
                                            const otherMemberIds = channel.members
                                                .filter(member => member.userId !== sendbirdId)
                                                .map(member => member.userId);
                                            
                                            handleJoinChannel(channel.url, otherMemberIds);
                                        }}
                                    >
                                        {channel.members !== null && channel.members.length > 0 && (
                                            <div className="listofUsersChats">
                                                {channel.members.filter(member => member.userId !== sendbirdId).map(filteredMember => (
                                                    <div 
                                                        key={filteredMember.userId} // Ensure this key is unique
                                                        className={`flexGap mb-2 mt-2`}
                                                     >
                                                        {filteredMember.plainProfileUrl !== null && filteredMember.plainProfileUrl !== "" ? (
                                                            <Image
                                                                src={filteredMember.plainProfileUrl}
                                                                roundedCircle
                                                                alt="MsgUser"
                                                                className="roundedUser mr-3 profileImage"
                                                            />
                                                        ) : (
                                                            <div className="profile-image-fallback roundedUser">
                                                                {filteredMember.nickname.charAt(0)}
                                                            </div>
                                                        )}

                                                        <div className="channel-list-item-name">
                                                            <h4 className={baseStyles.liveUsername}>{filteredMember.nickname}</h4>
                                                            <div className="last-message">{channel.lastMessage?.message}</div>
                                                        </div>
                                                        <div className="dateUnreaddiv">
                                                            {channel.lastMessage !== null && channel.lastMessage.createdAt !== null && <h6 className={baseStyles.liveUsercontent}>{timestampToTime(channel.lastMessage.createdAt)}</h6>}
                                                            {channel.unreadMessageCount > 0 ? (
                                                                <Badge pill bg="danger" className={baseStyles.unreadMessageCount}>
                                                                    {channel.unreadMessageCount}
                                                                </Badge>
                                                            ) : (
                                                                <Badge pill className={baseStyles.unreadMessageCounthide}>
                                                                    0
                                                                </Badge>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </li>
                                ) : null
                            ))}
                        </ul>
                    )}
                </div>

                ) : activeTab === 'groups' ? (
                <div>
                    {channels.length === 0 || channels.every(channel => channel.customType !== 'gm' && channel.customType !== 'agm') ? (
                        <div className={baseStyles.emptyUsersDiv}>
                            <h3>No chats available.</h3>
                        </div>
                    ) : (
                        <ul className="list-group">
                            {channels.map((channel, index) => (
                                channel.customType !== null && (channel.customType === 'gm' || channel.customType === 'agm') ? (
                                    <li key={index} className={`list-group-item ${baseStyles.MsglistItem}`} onClick={() => { handleJoinChannel(channel.url) }}>
                                        <div className="listofGroupChats">
                                            <div 
                                                key={channel.id} // Ensure this key is unique 
                                                className={`flexGap mb-2 mt-2`} 
                                            >
                                                {channel.coverUrl !== null && channel.coverUrl !== "" ? (
                                                    <Image
                                                        src={channel.coverUrl}
                                                        roundedCircle
                                                        alt="MsgUser"
                                                        className="roundedUser mr-3 profileImage"
                                                    />
                                                ) : (
                                                    <div className="profile-image-fallback roundedUser">
                                                        {channel.name.charAt(0)}
                                                    </div>
                                                )}
                                                <div className="channel-list-item-name">
                                                    <h4 className={baseStyles.liveUsername}>{channel.name}</h4>
                                                    <div className="last-message">{channel.lastMessage?.message}</div>
                                                </div>
                                                <div className="dateUnreaddiv">
                                                    {channel.lastMessage !== null && channel.lastMessage.createdAt !== null && (
                                                        <h6 className={baseStyles.liveUsercontent}>{timestampToTime(channel.lastMessage.createdAt)}</h6>
                                                    )}
                                                    {channel.unreadMessageCount > 0 ? (
                                                        <Badge pill bg="danger" className={baseStyles.unreadMessageCount}>
                                                            {channel.unreadMessageCount}
                                                        </Badge>
                                                    ) : (
                                                        <Badge pill className={baseStyles.unreadMessageCounthide}>
                                                            0
                                                        </Badge>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ) : null
                            ))}
                        </ul>
                    )}
                </div>
                
                ) : activeTab === 'watchparties' ? (
                <div>
                    {channels.length === 0 || channels.every(channel => channel.customType !== 'wpc') ? (
                        <div className={baseStyles.emptyUsersDiv}>
                            <h3>No watch parties available.</h3>
                        </div>
                    ) : (
                        <ul className="list-group">
                            {channels.map((channel, index) => (
                                channel.customType !== null && (channel.customType === 'wpc') ? (
                                    <li key={index} className={`list-group-item ${baseStyles.MsglistItem}`} onClick={() => { handleJoinChannel(channel.url) }}>
                                        <div className="listofGroupChats">
                                            <div 
                                                key={channel.id} // Ensure this key is unique 
                                                className={`flexGap mb-2 mt-2`} 
                                            >
                                                {channel.coverUrl !== null && channel.coverUrl !== "" ? (
                                                    <Image
                                                        src={channel.coverUrl}
                                                        roundedCircle
                                                        alt="MsgUser"
                                                        className="roundedUser mr-3 profileImage"
                                                    />
                                                ) : (
                                                    <div className="profile-image-fallback roundedUser">
                                                        {channel.name.charAt(0)}
                                                    </div>
                                                )}
                                                <div className="channel-list-item-name">
                                                    <h4 className={baseStyles.liveUsername}>{channel.name}</h4>
                                                    <div className="last-message">{channel.lastMessage?.message}</div>
                                                </div>
                                                <div className="dateUnreaddiv">
                                                    {channel.lastMessage !== null && channel.lastMessage.createdAt !== null && (
                                                        <h6 className={baseStyles.liveUsercontent}>{timestampToTime(channel.lastMessage.createdAt)}</h6>
                                                    )}
                                                    {channel.unreadMessageCount > 0 ? (
                                                        <Badge pill bg="danger" className={baseStyles.unreadMessageCount}>
                                                            {channel.unreadMessageCount}
                                                        </Badge>
                                                    ) : (
                                                        <Badge pill className={baseStyles.unreadMessageCounthide}>
                                                            0
                                                        </Badge>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ) : null
                            ))}
                        </ul>
                    )}
                </div>

                ) : (
                <div></div>
                )}
            </div>
            </div>
        </div>
    );
}


const getAllApplicationUsers = async () => {
    try {
        const userQuery = sb.createApplicationUserListQuery({ limit: 100 });
        const users = await userQuery.next();
        return [users, null];
    } catch (error) {
        return [null, error];
    }
}

export default SendBirdChatLists;
