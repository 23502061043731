import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Row } from "react-bootstrap";
import signStyles from './signup.module.css';
import loginApiServices from '../../../Services/loginApiServices';


const OtpVerificationModal = ({ email, otpId, show, handleClose, onResendOtp, onVerifySuccess }) => {
    
    // console.log('Modal opened', email, otpId, show, handleClose);
    const [otp, setOtp] = useState(['', '', '', '']);
    const [otpError, setOtpError] = useState("");
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [timer, setTimer] = useState(60);
    const [canResend, setCanResend] = useState(false);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (show) {
            setOtp(['', '', '', '']);
            setOtpError("");
            setTimer(60);
            setCanResend(false);
            startCountdown();
        } else {
            clearInterval(intervalRef.current);
        }
    }, [show]);

    const startCountdown = () => {
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(intervalRef.current);
                    setCanResend(true);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);
    };
    
    // Initialize refs
    const inputRefs = Array.from({ length: 4 }).map(() => React.createRef());
    
    const applyErrorAnimation = () => {
      inputRefs.forEach((ref) => {
        if (ref.current) {
          ref.current?.classList.add('blink-error');
          setTimeout(() => {
            ref.current?.classList.remove('blink-error');
          }, 1000);
        }
      });
    };
    
    const handleChange = (index, value) => {
        const digit = value.replace(/\D/g, '');
        setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = digit;
        return newOtp;
        });
        // Move to the previous input if backspacing in an empty input
        if (digit === '' && index > 0) {
        if (inputRefs[index - 1] && inputRefs[index - 1].current) {
            inputRefs[index - 1].current.focus(); // Move to the previous input
        }
        }
        // Move to the next input
        if (digit !== '' && index <= 3) {
        if (inputRefs[index + 1] && inputRefs[index + 1].current) {
            inputRefs[index + 1].current.focus(); // Move to the next input
        // } else if (index === 2) {
        //   if (inputRefs[index + 1] && inputRefs[index + 1].current) {
        //     inputRefs[index + 1].current?.focus();
        //    }
        }
        }
    };
    
    const handleKeyDown = (index, event) => {
      // Move to the previous input on backspace
      if (event.key === 'Backspace' && index > 0) {
        setOtp((prevOtp) => {
          const newOtp = [...prevOtp];
          newOtp[index] = ''; // Clear the current input
          return newOtp;
        });
        // Move the focus to the previous input
        if (inputRefs[index - 1] && inputRefs[index - 1].current) {
          inputRefs[index - 1].current.focus();
        }
      }
    };
    
    const verifyOtp = async () => {
      setOtpError('');
      
      if(email === null && email.length === 0){
          setOtpError('Please enter your email id to proceed');
          return;
      }else if (otpId === null && otpId.length === 0) {
          setOtpError('Enter the OTP received on email');
          return;
      }
  
      const otpString = otp.join("");
  
      if(otpString.length !== 4){
          setOtpError('Please enter the correct OTP');
          setOtp(['', '', '', '']);
          applyErrorAnimation();
          return;
      }
  
      try {
        
        const response = await loginApiServices.verifyEmailOtp(email, otpId, otpString);
        
        if (response.statusCode === 200 || response.statusCode === 0) {
            if (response.data && response.data.length > 0 && response.data[0].verified) {
                setIsOtpVerified(true);
                onVerifySuccess(true, response.data[0].sessionId); // Call parent function
            } else {
              setOtp(['', '', '', '']);
              setIsOtpVerified(false);
              setOtpError(response.data[0].message);
              applyErrorAnimation();
            }
        } else {
          setOtp(['', '', '', '']);
          setIsOtpVerified(false);
          setOtpError(response.message);
          applyErrorAnimation();
        }
      } catch (error) {
        setOtp(['', '', '', '']);
        setIsOtpVerified(false);
        console.error("verifyOtp Error:", error);
      }
    };
    
    useEffect(() => {
        const otpString = otp.join('');
        if (otpString.length === 4 && !isOtpVerified) {
        verifyOtp();
        }
    });

    // Format timer as MM:SS
    const formatTimer = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };


    const handleResendOtp = async () => {
        if (onResendOtp) {
          await onResendOtp(); // Call the function from the parent
          setCanResend(false);
          setTimer(60);
          startCountdown();
        }
      };

    

    return (
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton className={signStyles.otpModalHeader}>
            </Modal.Header>
            <Modal.Body>
            <div className={signStyles.otpModalBodyContainer}>
                <div className="text-center">
                    <h4 className={signStyles.otpBaseHeaderTag} > We sent you a code </h4>
                    <p className={signStyles.otpBaseLabelTag} >Please, enter it below to verify your email </p>
                </div>
                <br />

                <div className="d-flex align-items-center justify-content-between g-3">
                    <label className={signStyles.OtpLabeltext}> One Time Password </label> &nbsp;
                    {otp.map((digit, index) => (
                    <Form.Control
                        key={index}
                        type="tel"
                        className={`${signStyles.OtpBox} ${otpError ? `blink-error ${signStyles['blinkerror']}` : ''}`}
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        ref={inputRefs[index]}
                    />
                    ))}
                </div>
                <div>
                    {otpError && (
                        <Form.Text className="text-danger" align="center">
                        {otpError}
                        </Form.Text>
                    )}
                </div>
                <br />

                <Row>
                    <p className={`text-center`}>
                        {canResend ? (
                            <Button variant="link" className={signStyles.resendOtpButtonStyles} onClick={handleResendOtp}>Resend Otp</Button>
                        ) : (
                            <span className={`text-muted ${signStyles.resendOtpLabelText}`}>Resend otp in {formatTimer(timer)}</span>
                        )}
                    </p>
                </Row>

                <Row className="d-flex align-items-center">
                    <Button variant="primary" className={signStyles.validateOtpButtonStyles} onClick={verifyOtp}>
                        Verify OTP
                    </Button>
                </Row>

            </div>
            </Modal.Body>
        </Modal>
    );
};

export default OtpVerificationModal;
