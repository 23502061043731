import { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import profileStyles from './Profile.module.css';
import AboutProfile from "../BaseComponents/AboutProfile";
import UserProfile from "../BaseComponents/UserProfile";
import BankDetails from "./BankDetails";
import ProfileDashboard from './ProfileDashboard';
import UploadProfile from './UploadProfile';
import BasicDetails from './BasicDetails';
import Bio from './Bio';
import ContactDetails from './ContactDetails';
import Interests from './Interests';
import experienceServices from '../../Services/experienceServices';


const UpdateProfilePage = ({ profileStatus }) => {
  
  const [isStripe, setIsStripe] = useState(false);

  useEffect(() => {
    async function checkStripeAccountStatus() {
      const onboardingStarted = localStorage.getItem("stripeOnboardingStarted");

      if (onboardingStarted === "true") {
        try {
          const statusResponse = await experienceServices.validateSellerBankDetails();

          if (statusResponse?.statusCode === 200 && statusResponse.data?.[0]?.available) {
            // Update localStorage if verification is complete
            const profileData = localStorage.getItem("userProfileData");

            if (profileData) {
              const parsedData = JSON.parse(profileData);
              parsedData.stripeAccountExists = true;
              localStorage.setItem("userProfileData", JSON.stringify(parsedData));
            }
          }

          // Remove the onboarding flag after checking
          localStorage.removeItem("stripeOnboardingStarted");

        } catch (error) {
          console.error("Error checking Stripe account status:", error);
        }
      }

      // After checking Stripe, update isStripe state
      updateStripeState();
    }

    function updateStripeState() {
      const profileData = localStorage.getItem("userProfileData");
      if (profileData) {
        try {
          const parsedData = JSON.parse(profileData);
          setIsStripe(parsedData?.stripeAccountExists || false);
        } catch (error) {
          console.error("Error parsing userProfileData:", error);
        }
      }
    }

    // First check Stripe status, then update isStripe state
    checkStripeAccountStatus();

  }, []);


  return (
    <Container fluid className="updateProfilePageSection">
      <main className="MainpartSetup viewportHt">

        <Row className={`mainPad`}>
          <Col md={3} className={`${profileStyles.responsiveDisplay}`} >
            <UserProfile />
            <br />
            <AboutProfile isStripe={isStripe} />
          </Col>

          <Col md={9}>
            {profileStatus === 'profile-dashboard' && <ProfileDashboard />}
            {profileStatus === 'update_profile_picture' && <UploadProfile />}
            {profileStatus === 'update_basic_details' && <BasicDetails />}
            {profileStatus === 'update_bio' && <Bio />}
            {profileStatus === 'update_contact_details' && <ContactDetails />}
            {profileStatus === 'stripe-account-details' && <BankDetails isStripe={isStripe} />}
            {profileStatus === 'update_interests' && <Interests />}
          </Col>
        </Row>

      </main>
    </Container>
  );
}


export default UpdateProfilePage;