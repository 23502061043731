import React, { useState } from 'react';
import Header from './Navbar/Header';
import { useParams } from 'react-router-dom';
import SellerPublicProfilePage from './SellerDashboard/PublicProfile/SellerPublicProfilePage';


const SellerPublicProfile = () => {

  const [loginStatus] = useState('loggedIn');
  const { username } = useParams();

  return (

    <div className="SellarDashboardDiv">
      
      <Header loginStatus={loginStatus} />
      
      <SellerPublicProfilePage username={username} />
       
      <footer/>
    </div>

  );
}

export default SellerPublicProfile;
