import { useState, useEffect, useContext } from 'react';
import { useCart } from '../Navbar/CartContext';
import axiosAPI from '../../Services/axiosAPI';
import styles from './product.module.css';
import { Row, Col, Image, Button, Toast, Container, Spinner, Modal } from 'react-bootstrap';
import StarRating from '../BaseComponents/StarRating';
// import LoadingOverlay from '../BaseComponents/LoadingOverlay';
import cartServices from '../../Services/cartServices';
import userServices from '../../Services/userServices';
// import MessagingServices from '../../Services/MessagingServices';
import { Link } from "react-router-dom";
import { FaAngleDown, FaCopy } from "react-icons/fa";
import AuthContext from '../Context/AuthContext';
import CopyUrlButton from '../BaseComponents/CopyUrlButton';
import Tooltip from '../BaseComponents/Tooltip';


const ProductDetailspage = ({ contentType, contentId, refferalId, openProductChat }) => {

  // const navigate = useNavigate();
  // console.log("productDetails ::: ", productDetails);
  // console.log("FFF::", feedId, "REff::", refferalId);

  const { dispatch } = useCart();
  const { isLoading, setIsLoading } = useContext(AuthContext);
  const [productDetails, setProductDetails] = useState([]);
  const [buyerReviews, setBuyerReviews] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);
  // Maintain referralId in state
  const [currentReferralId, setCurrentReferralId] = useState(refferalId || null);

  useEffect(() => {
    if (contentId) {
      async function fetchExperienceDetailsFeedId(contentId) {
        try {
          setIsLoading(true);
          let response;

          if (contentType?.toLowerCase() === "boast") {
            response = await cartServices.getExperienceDetailsbyBoast(contentId);
          } else {
            response = await cartServices.getExperienceDetailsbyFeed(contentId);
          }
          
          if (response.statusCode === 200) {
            if (response.data && response.data.length > 0 && response.data[0] !== null) {
              setProductDetails(response.data);

              const productId = contentType?.toLowerCase() === 'boast' ? response.data[0].fId : contentId; 
              fetchBuyerReviewsbyFeed(productId); // ✅ Now this will work

              if (contentType?.toLowerCase() === "boast" && response.data[0].referralKey) {
                const referralKeyFromResponse = response.data[0].referralKey;
                setCurrentReferralId(prevId => prevId ? prevId : referralKeyFromResponse);
              }

            } else {
              console.log("Response Error:", response.message);
            }
          } else {
            console.log("Response Error:", response.message);
          }

        } catch (error) {
          console.error("API Error:", error);
        } finally {
          setIsLoading(false);
        }
      }

      fetchExperienceDetailsFeedId(contentId);
    }
  }, [contentId, contentType, setIsLoading]); // Keep dependencies minimal

    // Move this function outside of the useEffect
    const fetchBuyerReviewsbyFeed = async (productId) => {
      try {
        const reviewsResponse = await cartServices.fetchBuyerReviewsbyFeed(productId);
        if (reviewsResponse.statusCode === 200 && reviewsResponse.data && reviewsResponse.data.length > 0) {
          setBuyerReviews(reviewsResponse.data);
        }
      } catch (error) {
        console.error("Reviews API Error:", error);
      }
    };

  const [showDescription, setShowDescription] = useState(true);
  const [showMaterial, setShowMaterial] = useState(true);

  const toggleShowDescription = () => setShowDescription(!showDescription);
  const toggleShowMaterial = () => setShowMaterial(!showMaterial);


  const AddItemtoCart = async (feedId) => {
    setIsLoading(true);

    // let promoCode = null;
    // if(currentReferralId && currentReferralId !== 'undefined') {
    //   promoCode = currentReferralId;
    // }

    try {
      const response = await cartServices.validateCartandCount(feedId, currentReferralId);
      // console.log("API Response:", response);
      if (response.statusCode === 200) {
        if(response.data[0].isAdded){
          if(productDetails !== null && productDetails.length >0){
              dispatch({ type: 'SET_CART_COUNT', payload: response.data[0].totalCartItems });
              productDetails[0].isAddedToCart = true;
              setProductDetails(productDetails);
            }
          }
          setSuccessMessage(response.message);
          // Set timeout to clear the message after 5 seconds
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const gotoStripePayment = async (checkOuttype, feedId, paymentAmt, currency, promoApplied, promoCode, promoId, discount) => {
    try {
      setIsLoading(true);
      
      let parsedPayment = parseFloat(paymentAmt);
      paymentAmt = parseFloat(parsedPayment.toFixed(2));
      if(currentReferralId && currentReferralId !== 'undefined') {
        promoCode = currentReferralId;
        promoApplied = true;
      }

      const response = await cartServices.stripePaymentDirect(checkOuttype, feedId, paymentAmt, currency, promoApplied, promoId, promoCode, discount);
      
      if (response.statusCode === 200) {
        if (response.data[0].path !== null) {
          // navigate(response.data[0].path);
          window.location.href = response.data[0].path;
        } else {
          setErrorMessage(response.message);
        }
      }else {
          setErrorMessage(response.message);
      }
    } catch (error) {
      console.error('API Error:', error);
    } finally {
      setIsLoading(false);
    }
};


const FollowHandler = async(userId, type) => {

  if(userId === "" || userId === null){
    console.log("Please provide the UserId first.");
    return;
  }

  if(type){
    try {
      await userServices.followUser(userId);
      
    } catch (error) {
      console.error("API Error:", error);
    }

    } else {
      try {
         await userServices.unfollowUser(userId,true);
        
      } catch (error) {
        console.error("API Error:", error);
      }
    }

    let updatedProductDetails = [...productDetails];
    let currentFollowStatus = updatedProductDetails[0].isFollowing;
    updatedProductDetails[0].isFollowing = !currentFollowStatus;
    setProductDetails(updatedProductDetails);
  
  };


  // const getRoomtoChat = async (sellerId) => {
  //   try {
  //     setIsLoading(true);
  //     const response = await MessagingServices.fetchRoomIdtoChat(sellerId);
  //     // console.log("API Response:", response);
  //       if(response && response.statusCode === 200 && response.message !== null && response.message !== undefined && 
  //         response.message.channel_url !== null && response.message.channel_url !== undefined){
  //           openProductChat(response.message.channel_url);
  //         } else {
  //           setErrorMessage(response.message || "Error while opening the chat");
  //         }

  //   } catch (error) {
  //     console.error("API Error:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  
  const BoastLikeOptimisticHandler = async(fId, bId, type) => {
    const accessToken = localStorage.getItem('accessToken');
    const token = `Bearer ${accessToken}`;
    const URL = type ? `feedback/blike/${fId}/${bId}` : `feedback/blike/${fId}/${bId}?revoke=true`
    await axiosAPI.axiosAuth.get(URL, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
      });
  }
  
  const MutateBoastLikeStatus = (fId, bId) => {
  
    let boastsArr = [...buyerReviews]
    let foundBoast = boastsArr.find(i => i.id === bId)
    let currentLikeStatus = foundBoast.isLikedByMe
    foundBoast.isLikedByMe = !currentLikeStatus
    
    if(currentLikeStatus === false){
      foundBoast.likesCount=foundBoast.likesCount+1
      BoastLikeOptimisticHandler(fId, bId, true)
    }else{
      foundBoast.likesCount=foundBoast.likesCount-1
      BoastLikeOptimisticHandler(fId, bId, false)
    }
    setBuyerReviews(boastsArr)
  }

  const BoastFollowHandler = (userId, bId, type) => {
    let boastsArr = [...buyerReviews]
    let foundBoast = boastsArr.find(i => i.id === bId)
    let currentFollowStatus = foundBoast.isFollowing
    foundBoast.isFollowing = !currentFollowStatus
    FollowHandler(userId, type)
    setBuyerReviews(boastsArr)
  }

  const [showModal, setShowModal] = useState(null); // State to track which share's modal is open
  let refferalCode = null;
  let isStripe = false;
  const storedRefferalCode = localStorage.getItem('userReferralCode');
  if (storedRefferalCode && storedRefferalCode !== 'undefined') {
    refferalCode = storedRefferalCode;
  }
  if(localStorage.getItem('stripeAccountExists')) {
    isStripe = localStorage.getItem('stripeAccountExists');
  }

  const handleModalOpen = (feedId) => {
    setShowModal(feedId);
  };

  const handleModalClose = () => {
    setShowModal(null);
  };

  return (

    <Container className={`boxBody`}>
      <Row className={styles.beforePurchase}>

      <Col lg={12} md={12} sm={12} xs={12}>
        <div>
          {productDetails.length > 0 ? (
            productDetails.map((product, index) => (
              <div key={index}>
                
                {errorMessage ? <div  className={styles.productErrorStyles} >{errorMessage}</div> : null}
                {successMessage ? <div className={styles.productSuccessStyles} >{successMessage}</div> : null}

                <Col lg={12} md={12} sm={12} xs={12}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                        {product.postedByUserImg ? (
                          <Link to={`/seller/public-profile/${product.postedByUsername}`}>
                            <Image src={product.postedByUserImg} style={{ marginRight: '10px' }} width={45} height={45} roundedCircle alt="Profile" />
                          </Link>
                          ) : (
                          <Link to={`/seller/public-profile/${product.postedByUsername}`}>
                            <Image src="/Content/Images/empty_user.jpg" style={{ marginRight: '10px' }} width={45} height={45} roundedCircle alt="Profile" />
                          </Link>
                          )}
                          <Link to={`/seller/public-profile/${product.postedByUsername}`} style={{display: 'inline-grid'}}>
                            <h3 className={styles.productSellername}>{product.postedByUsername}</h3>
                            <label className={styles.productSellerdesc}>{product.userBio}</label>
                          </Link>
                      </div>
                        {(!product.contentOwner && product.isFollowing) ? (
                            <div>
                              <Button className={`${styles.ProductSellerBtns}`}
                                onClick={() => { FollowHandler(product.postedByUserId, false) }} >
                                Following
                              </Button>
                            </div>
                          ) : ((!product.contentOwner && !product.isFollowing) ? (
                            <div>
                              <Button className={`${styles.ProductSellerBtns}`}
                                onClick={() => { FollowHandler(product.postedByUserId, true) }}>
                                Follow
                              </Button>
                            </div>
                          ) :
                          null
                        )}
                      
                      
                      {/* {!product.contentOwner && (
                        <>
                        <Button className={`${styles.ProductSellerUnlockBtns}`} onClick={() => { getRoomtoChat(product.postedByUserId) }} >
                        <img className={`${styles.lockedImage}`}
                            src="/Content/Images/icons/locked.png"
                            alt="locked"
                        />Unlock to chat</Button>
                        </>
                      )} */}

                  </div>
                </Col>
            
                <Col lg={12} md={12} sm={12} xs={12} className="mt-2">
                  <div>
                    <h3 className={styles.productCaption}>{product.title}</h3>
                    {/* &nbsp;&nbsp;
                     {product.desc && (
                      <Badge bg="info" className={styles.productDescription}>
                        {product.desc.trim().split(' ').slice(0, 3).join(' ')}
                      </Badge>
                    )} */}
                  </div>
                </Col>

                <Col lg={12} md={12} sm={12} xs={12} className='mt-2'>
                  <div className={styles.productContainerdiv}>
                  {product.content ? (
                    <img src={product.content} className={styles.ProductImageBox}
                      alt="thumbnail"
                    />
                    ) : (
                    <img
                      className={`d-block w-100 ${styles.ProductImageBox}`}
                      src="/Content/Images/SellerExpStudio/noImageBG.jpeg"
                      alt="thumbnail"
                    />
                  )}
                
                  <div className={styles.productContent}>
                    {!product.contentOwner && !product.isPurchased && (
                    <Row className={`justify-content-end ${styles.cartBoxMargin}`}>
                        <Col xs="auto" className={styles.cartBoxdiv}>   
                          {product.isAddedToCart ? (
                              <Button className={styles.ProductAddCartBtns} disabled>
                                <img className={`${styles.innerIconImg}`}
                                    src="/Content/Images/icons/cartblue.png"
                                    alt="cart" />
                                    <br />
                                    Added to Cart
                              </Button>
                            ) : (
                              <Button className={styles.ProductAddCartBtns} onClick={() => AddItemtoCart(product.fId)}>
                                <img className={`${styles.innerIconImg}`}
                                    src="/Content/Images/icons/add_shopping.png"
                                    alt="cart" />
                                    <br />
                                    Add to Cart
                              </Button>
                            )}
                            &nbsp;&nbsp;
                        </Col>

                        <Col xs="auto" className={styles.cartBoxdiv}>
                            <Button 
                              className={styles.ProductBuynowBtn}
                              onClick={() => gotoStripePayment("direct", product.fId, product.price, "aud", false, null, "", 0)} 
                              >
                            <img className={`${styles.innerIconImg}`}
                                src="/Content/Images/icons/icons-buy.png"
                                alt="thumbnail" />
                                <br />
                              &nbsp; Buy now &nbsp;
                            </Button>
                        </Col>
                    </Row>
                    )}
                  </div>

                </div>
                </Col>
          
                <Row className={`mt-4`}>
                <Row>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <div className={`overlap-group ${styles.productDetailsBox}`}>
                      <div className={`mb-3 ${styles.ProductDetailsNameTagSetup}`}>
                        <label className={styles.ProductBoxLabel}>Duration</label>
                          <div className={styles.IconSetup}>
                            <Image
                                src='/Content/Images/icons/icons-sundial.png'
                                alt="duration_icon"
                                className={styles.IconSetImage}   
                              />
                          </div>
                      </div>
                      <p className={styles.ProductBoxlabelValue}>{product.duration}&nbsp;Mins</p>
                    </div>
                  </Col>
        
                  <Col xs={4} sm={4} md={4} lg={4}>
                  <div className={`overlap-group ${styles.productDetailsBox}`}>
                      <div className={`mb-3 ${styles.ProductDetailsNameTagSetup}`}>
                        <label className={styles.ProductBoxLabel}>Price</label>
                          <div className={styles.IconSetup}>
                            <Image
                                src='/Content/Images/icons/icons-dollar-coin.png'
                                alt="price_icon"
                                className={styles.IconSetImage}   
                              />
                          </div>
                      </div>
                      <p className={styles.ProductBoxlabelValue}>{product.currency}&nbsp;{product.price}</p>
                  </div>
                      
                  </Col>
        
                  <Col xs={4} sm={4} md={4} lg={4}>
                      <div className={`overlap-group ${styles.productDetailsBox}`}>
                      <div className={`mb-3 ${styles.ProductDetailsNameTagSetup}`}>
                        <label className={styles.ProductBoxLabel}>Rating</label>
                          <div className={styles.IconSetup}>
                            <Image
                                src='/Content/Images/icons/icons-rating.png'
                                alt="rating_icon"
                                className={styles.IconSetImage}   
                              />
                          </div>
                      </div>
                      <StarRating value={product.ratings} />
                  </div>
                  </Col>
                </Row>
              </Row>
          
                <br /><br />
                <Row>
                  <Col>
                    <div className={styles.toggleDropdowndiv}>
                      <label className={styles.accordionHeader}>Description</label>
                      <Button onClick={toggleShowDescription} className={`${styles.toggleDropdownClick}`}>
                        <FaAngleDown />
                      </Button>
                    </div>
                    <Toast show={showDescription} onClose={toggleShowDescription} className={styles.toggleToastStyles}>
                      <p className={styles.accordionDescription}>{product.desc} &nbsp;&nbsp;&nbsp;&nbsp;</p>
                    </Toast>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <div className={styles.toggleDropdowndiv}>
                      <label className={styles.accordionHeader}>Extra Material</label>
                      <Button onClick={toggleShowMaterial} className={`${styles.toggleDropdownClick}`}>
                        <FaAngleDown />
                      </Button>
                    </div>
                    <Toast show={showMaterial} onClose={toggleShowMaterial} className={styles.toggleToastStyles}>
                      <p className={styles.accordionDescription}>{product.extraInfo} &nbsp;&nbsp;&nbsp;&nbsp;</p>
                    </Toast>
                  </Col>
                </Row>


              {isLoading ? (
                <div className={styles.boastLoadContent}>
                  <Spinner animation="grow" className='text-center' />
                </div>

              ) : ( buyerReviews && buyerReviews.length > 0 ? (
                <div className='mt-4'>
                  <label className={styles.accordionHeader}>Boasts</label>
                  
                      <Row>
                        <Col md={9}>
                          <div className={styles.boastPostsdiv}>
                            {buyerReviews.map((review, reviewIndex) => (
                              <div key={reviewIndex} className={`${styles.feedContainerdiv}`}>
                              {review.contents.map((content, contentIndex) => (
                                  contentIndex === 0 && (
                                    <Image 
                                      key={contentIndex} 
                                      src={content ? content : "/Content/Images/General/no_thumbnail.png"} 
                                      alt="review" 
                                      fluid
                                      className={styles.reviewThumbnail} 
                                    />
                                  )
                                ))}
                                <div className={styles.feedContent}>
                                  <h3 className={styles.feedContentCommentText}>Comment: </h3>
                                  <p className={styles.feedContentText}>{review.comments}</p>
                                </div>
                                <div className={styles.feedContentSide}>
                                  <div className={styles.feedUserProfilediv}>
                                    <Image src={product.content ? product.content : "/Content/Images/empty_user.jpg"} roundedCircle className={styles.feedUserImg} />
                                  </div>
                                  <div className={styles.feedContentdiv}>

                                  <div className={styles.feedIconwithCountsdiv}>
                                    <Tooltip content={review.isFollowing ? `Unfollow ${review.username}` : `Follow ${review.username}`}>
                                      <Link to={`/seller/public-profile/${review.username}`}>
                                        <Image
                                          src={review.userProfilePic ? review.userProfilePic : "/Content/Images/empty_user.jpg"}
                                          className={styles.feedIconFollowImg}
                                        />
                                      </Link>
                                    </Tooltip>
                                    {!review.contentOwner && (
                                      <Link
                                        onClick={() => {
                                          BoastFollowHandler(review.userId, review.id, !review.isFollowing);
                                        }}
                                      >
                                        <Image
                                          src={review.isFollowing ? "/Content/Images/icons/icons-cancel.png" : "/Content/Images/icons/icons-plus.png"}
                                          className={styles.feedIconFollowAddIcon}
                                        />
                                      </Link>
                                    )}
                                  </div>

                                    <div className={styles.feedIconwithCountsdiv}>
                                        <Tooltip content="Rating">
                                          <Image src="/Content/Images/icons/icons-rating.png" className={styles.feedIconImg} />
                                        </Tooltip>
                                        {review.contents.map((content, contentIndex) => (
                                          contentIndex === 0 && (
                                            <label key={contentIndex} className={styles.feedUserContentFont}>
                                              {review.rating ? (review.rating).toFixed(0) : 0}
                                            </label>
                                          )
                                        ))}
                                    </div>
                                    <div className={styles.feedIconwithCountsdiv}>
                                        <Tooltip content="Like">
                                        <Image src={review.isLikedByMe ? '/Content/Images/icons/icons-fireRed.png' : '/Content/Images/icons/icons-fire.png' }
                                          onClick={()=>{MutateBoastLikeStatus(review.feedId, review.id)}}
                                          className= {`${styles.boastIconImg} ${review.isLikedByMe ? styles.animateFire : styles.animateDisfire}`}
                                          />
                                        </Tooltip>
                                          <label className={styles.boastUserContentFont}>
                                            {review.likesCount ?? 0}
                                            {/* handles both null and undefined */}
                                          </label>
                                        
                                    </div>

                                    <div className={styles.feedIconwithCountsdiv}>
                                        {(refferalCode && isStripe) ? (
                                          <Tooltip content="Share">
                                            <Image
                                              src="/Content/Images/icons/icons-share2.png" 
                                              className={styles.feedIconImg} 
                                              onClick={() => handleModalOpen(review.id)} 
                                            />
                                          </Tooltip>
                                        ) : (
                                          <Tooltip content="You don't have a Stripe account.">
                                          <Image
                                              src="/Content/Images/icons/icons-share2.png"
                                              className={`${styles.feedIconImg} ${styles.disabled}`}
                                              style={{ pointerEvents: 'none', opacity: 0.5 }}
                                            />
                                        </Tooltip>
                                        )}
                                        <label className={styles.feedUserContentFont} style={{visibility: 'hidden'}}>&nbsp;</label>
                                    </div> 

                                            {/* Modal */}
                                            {showModal === review.id && (
                                              <Modal show={true} onHide={handleModalClose} centered>
                                                <Modal.Header closeButton>
                                                  <Modal.Title className={styles.shareCopyText}>Copy your invitation link to share </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body className="d-flex flex-column align-items-center justify-content-center text-center">
                                                  <button
                                                    type="button"
                                                    className={`${styles.URLlinkshow} btn btn-link`}
                                                    onClick={() => {
                                                      navigator.clipboard.writeText(`${window.location.origin}/product-details/boast/${review.id}?ref=${refferalCode}`);
                                                      alert('URL copied to clipboard!');
                                                    }}
                                                  >
                                                    {`${window.location.origin}/product-details/boast/${review.id}?ref=${refferalCode}`}
                                                  </button>
                                                  <button className={styles.ClickCopyBtn}>
                                                    <FaCopy className="ml-2" />
                                                    <CopyUrlButton name="Copy" url={`${window.location.origin}/product-details/boast/${review.id}?ref=${refferalCode}`} />
                                                  </button>
                                                </Modal.Body>
                                              </Modal>
                                            )}

                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Col>
                      </Row>
                  
                </div>
              
                ) : (
                <Row className='mt-3 mb-3'>
                    <br /><br />
                      <div className={styles.marquee_container}>
                        <div className={styles.marquee_content}>No boast posts available.</div>
                      </div>
                    <br />
                </Row>
                  )
                )}

              </div>
            ))
          ) : (
            <p style={{textAlign: 'center', fontWeight: '700'}}>No product details available.</p>
          )}
        </div>
      </Col>

      </Row>
    </Container>
  );
};


export default ProductDetailspage;