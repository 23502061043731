import { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import cartStyles from './cart.module.css';
import DeleteExperiencePopup from './DeleteExperiencePopup';
// import { useNavigate } from 'react-router-dom';
import cartServices from '../../../Services/cartServices';
import LoadingOverlay from '../../BaseComponents/LoadingOverlay';
import SearchPromoCode from './SearchPromoCode';


const ViewCart = () => {
  
  // const navigate = useNavigate();
  const [cartData, setCartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);

  const handleDeleteRender = (value) => {
    if (value) {
      setIsDeleteSuccess(value);
    } else {
      setIsDeleteSuccess(value);
    }
  };

useEffect(() => {
  fetchCartData();
  if(isDeleteSuccess){
    fetchCartData();
  }
}, [isDeleteSuccess]);

const fetchCartData = async () => {
  try {
    setIsLoading(true); // Start loading
    const response = await cartServices.viewCartData();

    if (response.statusCode === 200) {
      if (response.data !== null && response.data.cartItems && response.data.cartItems.length > 0) {
        setCartData(response.data.cartItems);
        // dispatch({ type: 'SET_CART_COUNT', payload: response.data.noOfItems });
        // console.log("CartPop", response.data.cartItems)
      } else {
        setCartData([]);
      }
    } else {
      setCartData([]);
    }

  } catch (error) {
    console.error('API Error:', error);
  }finally {
    setIsLoading(false); // Stop loading
    setIsDeleteSuccess(false); // Reset after fetching data
  }
};

const calculateSubtotal = () => {
  const subtotal = cartData.reduce((total, item) => total + parseFloat(item.amount), 0);
  return parseFloat(subtotal.toFixed(2));
};

const [onDeleteshow, setOnDeleteshow] = useState(null);

const handleDeleteClick = (expFeedId) => {
  setOnDeleteshow(expFeedId);
  };

const gotoStripePayment = async (checkOuttype, cartSize, paymentAmt, currency) => {
    try {
      setIsLoading(true);
      let parsedPayment = parseFloat(paymentAmt);
      paymentAmt = parseFloat(parsedPayment.toFixed(2));
      const response = await cartServices.stripePayment(checkOuttype, cartSize, paymentAmt, currency);
      
      if (response.statusCode === 200) {
        if (response.data[0].path !== null) {
          // navigate(response.data[0].path);
          window.location.href = response.data[0].path;
        } else {
          setErrorMessage(response.message);
        }
      }else {
          setErrorMessage(response.message);
      }
    } catch (error) {
      console.error('API Error:', error);
    } finally {
      setIsLoading(false);
    }
};


  return (

    <main className={`MainpartSetup HomeViewportHt stickySidebar`}>

      {isLoading ? (
      <LoadingOverlay />
      ) : (
        
        <Row className={`mainPad`}>
          {cartData.length === 0 ? (
            <div className={`boxBody ${cartStyles.ItemDetails}`} style={{ color: 'red', textAlign: 'center',fontSize: '1.0em', fontWeight: 'bold',padding: '4em', marginLeft: '1em' }}>
              The cart is empty. Please add workshops to continue ...
            </div>
          ) : (
          <Row>
          <Col lg={9} md={9} sm={9} xs={12} >
          <Container fluid className='noPadding'>
            <Row className={`${cartStyles.Detailsdiv}`}>
              {errorMessage ? <div style={{ color: 'red',textAlign: 'center',fontWeight: 'bold',fontSize: '1.0em' }}>{errorMessage}</div> : null}
              {successMessage ? <div style={{ color: 'green',textAlign: 'center',fontWeight: 'bold',fontSize: '1.0em' }}>{successMessage}</div> : null}
              
              <br/>
              <h5 className={cartStyles.h2Namestyle}>Your Cart</h5>
              {/* <label className={cartStyles.freeOrderslabel}>Your order is eligible for free delivery.</label> */}
              <br/>

            <div className={cartStyles.cartItemsDatadiv}>
            {cartData.map((experience, index) => (
              <Row key={index} className='mt-3 mb-3'>
                  <Col lg={2} md={2} sm={2} xs={3} >
                    <img
                      className={`d-block ${cartStyles.CartImageSetup}`}
                      src={experience.coverImage || '/Content/Images/SellerExpStudio/noImageBG.jpeg'}
                      alt="Item"
                    />
                  </Col>
                  
                  <Col lg={3} md={3} sm={3} xs={4} >
                      <div className="item-details">
                        <h4 className={cartStyles.cartCaptionlabel}>{experience.caption}</h4>
                        <label className={cartStyles.subtotalspan}>$ {experience.amount}</label>
                      </div>
                  </Col>

                  <Col lg={3} md={3} sm={3} xs={0} className={`${cartStyles.responsiveDisplay}`} >
                      <div className="item-details">
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', marginBottom: '0.5em' }}>
                          <Image src={experience.sellerprofilePic || `/Content/Images/empty_user.jpg`} className={`roundedUser ${cartStyles.CartSellerProfile}`} roundedCircle alt="Profile" />
                          <h5 className={cartStyles.cartSellernamelabel}>{experience.sellerUsername}</h5>
                        </div>
                      </div>
                  </Col>

                  <Col lg={3} md={3} sm={3} xs={4} >
                      <Row className={`promo-details`}>
                        <SearchPromoCode key={experience.feedId} productId={experience.feedId} referralId={experience.promoCode} isPromoApplied={experience.promoApplied} />
                      </Row>
                  </Col>

                  <Col lg={1} md={1} sm={1} xs={2} className="text-end">
                      {/* <FaTrashAlt className={cartStyles.CartDeletebtn} onClick={() => handleDeleteClick(experience.feedId)} />  */}
                      <Button className={cartStyles.CartDeletebtn} onClick={() => handleDeleteClick(experience.feedId)} > &nbsp;&nbsp;
                        <img className={`roundedCircle ${cartStyles.cartDeleteImage}`}
                          src={'/Content/Images/icons/icons-delete.png'}
                          alt="Item"
                        />
                      </Button>
                  </Col>
                    <DeleteExperiencePopup
                      onDeleteshow={onDeleteshow}
                      closeDeleteModal={() => setOnDeleteshow(null)}
                      setErrorMessage={setErrorMessage}
                      setSuccessMessage={(message) =>
                      setSuccessMessage(message)}
                      onDeleteSuccess={handleDeleteRender}
                    />
              </Row>

            ))}
            </div>
            <Row className="d-flex justify-content-between align-items-center">
                <Col className="text-end">
                  <Button className={cartStyles.checkoutBtn}
                  onClick={() => gotoStripePayment("cart",cartData.length, calculateSubtotal(), "aud")}
                  > Buy Now </Button>
                </Col>
              </Row>
            <br/><br/>
            </Row>
          </Container>
          </Col>
      
            <Col lg={3} md={3} sm={3} xs={12} >
            <Container className={cartStyles.backCartBox}>
            <Row className={`${cartStyles.Detailsdiv} ${cartStyles.PriceDetails}`}>
              <br/>
              <h5 className={cartStyles.h2NamestylePricelabel}>Price Details</h5>
              <br/>

                <Row>
                  <Col xs={6} className={cartStyles.cartPriceDatalabel}>Price (3 Items) </Col>
                  <Col xs={6} className={cartStyles.valueTag}>$ {calculateSubtotal()}</Col>
                </Row>
                <Row>
                  <Col xs={6} className={cartStyles.cartPriceDatalabel}> Discount </Col>
                  <Col xs={6} className={cartStyles.valueTag}> - </Col>
                </Row>
                <Row style={{visibility: 'none', opacity: 0.1}}>
                  <Col xs={6} className={cartStyles.cartPriceDatalabel}>Delivery charges </Col>
                  <Col xs={6} className={cartStyles.valueTag}> - </Col>
                </Row>
                <Row style={{visibility: 'none', opacity: 0.1}}>
                  <Col xs={6}className={cartStyles.cartPriceDatalabel}>Promotions </Col>
                  <Col xs={6} className={cartStyles.valueTag}> - </Col>
                </Row>
                <Row>
                  <Col xs={6}className={cartStyles.cartPriceDatalabel}> &nbsp; </Col>
                  <Col xs={6} className={cartStyles.valueTag}> &nbsp; </Col>
                </Row>
                <Row>
                  <hr />
                </Row>

                <Row className="fw-bold">
                  <Col xs={6} className={cartStyles.cartTotalLabel}>Sub Total</Col>
                  <Col xs={6} className={cartStyles.cartTotalValueTag} >$ {calculateSubtotal()}</Col>
                </Row>

                <Row>
                  <hr />
                </Row>

                <br/><br/>

              </Row>
              </Container>
            </Col>
          </Row>
          )}
        </Row>
      )}
        
      <br/><br/>
  </main>
  
  );
}

export default ViewCart;
