import { useState, useEffect, useCallback, useMemo } from "react";
import cartServices from "../../../Services/cartServices";
import { debounce } from "lodash";
import cartStyles from './cart.module.css';
import { Container, ListGroup, Spinner, FormControl, Image } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";


const SearchPromoCode = ({ productId, referralId, isPromoApplied }) => {
  const [promoCodes, setPromoCodes] = useState([]);
  const [selectedPromoCode, setSelectedPromoCode] = useState(referralId || "");
  const [searchInput, setSearchInput] = useState("");
  const [isEdit, setIsEdit] = useState(!isPromoApplied);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch promo codes based on search input
  const fetchPromoCodes = useCallback(async (input) => {
    setIsLoading(true);
    try {
      const response = await cartServices.fetchPromoCodes(input);
      if (response.statusCode === 200 && response.data) {
        setPromoCodes(response.data);
      } else {
        setPromoCodes([]); // No data or error, set to empty
      }
    } catch (error) {
      console.error('API Error:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Debounce the fetchPromoCodes function to avoid calling API on every keystroke
  const debouncedFetchPromoCodes = useMemo(() =>
    debounce((input) => {
      if (input.trim()) {
        fetchPromoCodes(input);
      } else {
        setPromoCodes([]); // Clear promo codes if input is empty
      }
    }, 500), [fetchPromoCodes]
  );

  useEffect(() => {
    // Trigger the debounced function when search input changes
    debouncedFetchPromoCodes(searchInput);
    return () => {
      debouncedFetchPromoCodes.cancel();
    };
  }, [searchInput, debouncedFetchPromoCodes]);

  // Apply promo code
  const applyPromoCode = useCallback(async (value) => {
    try {
      setIsLoading(true);
      const response = await cartServices.applyPromoCode(productId, value);
      if (response.statusCode === 200) {
        setIsEdit(false);
        setSelectedPromoCode(value);
      }
    } catch (error) {
      console.error('API Error:', error);
    }  finally {
      setIsLoading(false);
    }
  }, [productId]);

  // Apply promo on load if referralId is present
  useEffect(() => {
    if (referralId) {
      applyPromoCode(referralId);
    }
    if (isPromoApplied) {
      setIsEdit(false);
    }
  }, [referralId, applyPromoCode, isPromoApplied]);

  
  return (
    <Container fluid>
      {isLoading ? (
        <Spinner animation="border" variant="dark" />
      ) : (
        <div className={cartStyles.promoCodesDiv}>
            {isEdit ? (
              <div className="input-wrapper">
                <FormControl
                  type="text"
                  value={searchInput}
                  placeholder="Search Promo Codes"
                  onChange={(e) => setSearchInput(e.target.value)}
                  className={cartStyles.promoCodesInput}
                />
                {/* Loading spinner */}
                {isLoading && (
                  <div className="spinner-div">
                    <Spinner animation="border" className="spinner" />
                  </div>
                )}
                {/* Promo code select box */}
                {promoCodes.length > 0 && !isLoading && (
                  <ListGroup as="ol" className={cartStyles.promoCodesSelect}>
                    {promoCodes.map((promo, index) => (
                      <ListGroup.Item
                        as="li"
                        key={index}
                        onClick={() => {
                          applyPromoCode(promo.referralKey);
                        }}
                        className={cartStyles.promoCodesItem}
                      >
                        
                        <div className="promo-user-details">
                          <div style={{ flex: 1, display: 'flex', alignItems: 'center', marginBottom: '0.5em' }}>
                            <Image src={promo.image_url || `/Content/Images/empty_user.jpg`} className={`roundedUser ${cartStyles.CartSellerProfile}`} roundedCircle alt="Profile" />
                            <div>
                              <h5 className={cartStyles.promoUserNamelabel}>{promo.referralKey}</h5>
                              <label className={cartStyles.promoUserDesclabel}>Ref ID: {promo.referralKey}</label>
                            </div>
                          </div>
                        </div>
                
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </div>

            ) : (
              <div className={cartStyles.promoCodeLabelDiv}>
                {/* Display the selected promo code with an Edit button */}
                <label className={cartStyles.promoCodeLabel}>{selectedPromoCode}</label> &nbsp;&nbsp;
                <button
                  type="button"
                  className={cartStyles.promoCodesEditBtn}
                  onClick={() => setIsEdit(true)}
                >
                  <FiEdit />
                </button>
              </div>
            )}
        </div>
      )}
    </Container>
  );
};

export default SearchPromoCode;
