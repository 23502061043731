import { useState, useEffect } from 'react';
import signStyles from './signup.module.css';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import loginApiServices from '../../../Services/loginApiServices';
import LoadingOverlay from '../../BaseComponents/LoadingOverlay';
import SmallFooter from '../../BaseComponents/SmallFooter';
// import StarRating from '../../BaseComponents/StarRating';
import countries from "i18n-iso-countries";
// Import the languages you want to use
import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";
// import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
// import { allCountries } from 'country-telephone-data';
import { TiEdit } from "react-icons/ti";
import { FaCheckCircle } from "react-icons/fa";



const SignupProfile = ({ userData, updateUserData }) => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [profileURL, setProfileURL] = useState("");
  const [bio, setBio] = useState("");
  const [country, setCountry] = useState("AU");
  // const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState('');  
  const [formErrors, setFormErrors] = useState({
        profileError: "",
        formUsername: "",
        formEmailId: "",
        formPassword: "",
        formDob: "",
  });


  // for Image upload ...
  const handleImageChange = (event) => {
    setProfilePicture(event.target.files[0]);
    //console.log("Resp:: ", userPicture);
  };
  
  useEffect(() => {
    if (profilePicture !== "") {
      const uploadProfileSignup = async (e) => {
        setErrorMessage('');
        
        const errors = {
            profileError: "",
        };
        setFormErrors(errors);
    
        if (!profilePicture) {
          errors.profileError  = "Invalid file format.";
           return;
        }

        const formData = new FormData();
        formData.append('profilePicture', profilePicture);
        // formData.append('profilePicture', userPicture.name);
        // formData.append('type', userPicture.type);
        
        // To see the contents of FormData, you can convert it to an object
        const formDataObject = {};
        for (let [key, value] of formData.entries()) {
          formDataObject[key] = value;
        }
        //console.log(formDataObject); 
        setIsLoading(true); // Start loading 
    
        try {
    
          const response = await loginApiServices.userUploadProfileSingup(formDataObject);
    
          if (response.statusCode === 200) {
            if(response.data[0] !== null && response.data[0].uploadedPath !== null){
                setProfileURL(response.data[0].uploadedPath);
            } else {
              setErrorMessage(response.message);
            }
          
          } else {
            setErrorMessage(response.message);
          }
        
        } catch (error) {
          console.error("API Error:", error);
        } finally {
          setIsLoading(false); // Stop loading
        }
      };
      uploadProfileSignup();
    }
  }, [profilePicture]);

 
  
  // const getPhoneCodeForCountry = (countryValue) => {
  //   const country = allCountries.find((c) => c.iso2 === countryValue);
  //   return country ? country.dialCode : '';
  // };

  // const [selectedCountryData, setSelectedCountryData] = useState({
  //   flag: 'au',
  //   phoneCode: getPhoneCodeForCountry('AU'),
  // });

  // for Select Countries
  
  // Have to register the languages you want to use
  countries.registerLocale(enLocale);
  countries.registerLocale(itLocale);

  // Returns an object not a list
  const countryObj = countries.getNames("en", { select: "official" });
  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key,
      alpha2: key.toLowerCase(),
    };
  });

  const filterCountries = (input) => {
    return countryArr.filter(({ label }) =>
      label.toLowerCase().startsWith(input.toLowerCase())
    );
  };

  const handleKeyDown = (e) => {
    if (e.key.length === 1) {
      const filteredCountries = filterCountries(country + e.key);
      if (filteredCountries.length === 1) {
        setCountry(filteredCountries[0].value);
        e.preventDefault();
      }
    }
  };

   const selectCountryHandler = (selectedCountry) => {
    setCountry(selectedCountry);
    // const countryData = countryArr.find((country) => country.value === selectedCountry);
    // setSelectedCountryData({
    //   flag: countryData.alpha2,
    //   phoneCode: getPhoneCodeForCountry(selectedCountry), // Using country-telephone-data
    // });
  };

  const validateSignupProfile = async (e, btnType) => {
        setErrorMessage('');
        
        e.preventDefault();
        const errors = {
            profileError: "",
        };
        setFormErrors(errors);

    if(btnType !== 'skip'){   

    // if (!profileURL) {
    //   errors.profileError = "Please upload your profile picture.";
    //   setFormErrors(errors);
    //   return;
    // }

    // if ((phone === "" || phone === null || phone.length === 0)) {
    //   errors.phoneError = "Please enter your mobile number.";
    //   setFormErrors(errors);
    //   return;
    // }

    if (bio === "" || bio === null) {
      errors.bioError = "Please enter your bio.";
      setFormErrors(errors);
      return;
     } else if (bio.length > 200) {
      errors.bioError = "Bio can't exceed 200 characters.";
      setFormErrors(errors);
      return;
     }

    }

    setIsLoading(true); // Start loading

    updateUserData({
      ...userData,
      profileURL,
      country,
      // phone,
      bio,
    });

    navigate('/signup/interest_setup');
    setIsLoading(false); // Stop loading
  
  };


  return (
    
    <Container fluid className='noPadding'>
        <Row className={`${signStyles.signBackground}`}>
            <Row className={`mainPad d-flex align-items-center justify-content-between ${signStyles.viewportBoxProfile}`}>
                <Col xs={0} lg={5} className={signStyles.responsiveDisplay}>
                  <Row className={signStyles.signleftContent} >
                    <label className={`${signStyles.signWelcomeline}`}>Profile SETUP</label>
                    <h1 className={signStyles.signBigContentline} >Create your profile </h1>
                    <p className={signStyles.signParaContent}>Upload your profile picture and write a
                     snappy bio to help the Hapifyr community get to know you.</p>
                  </Row>
                  {/* <Row className={`d-flex align-items-center`} >
                    <StarRating value={4} />
                  </Row>   */}
                </Col>
                <Col xs={0} lg={2} className={signStyles.responsiveDisplay}></Col>
                <Col xs={12} lg={5}>
                
                <div className={`${signStyles.signupSection}`}>
                  
                  <Form onSubmit={(e) => validateSignupProfile(e, 'submit')}>
                    <Row className="mb-3 d-flex align-items-center justify-content-between">
                        <Col xs="auto">
                          <label className={`${signStyles.BaseNameTag}`}>Profile Details*</label>
                        </Col>
                        <Col xs="auto">
                          <Button className={signStyles.btnSkipStyles}
                          onClick={(e) => validateSignupProfile(e, 'skip')} >SKIP NOW</Button>
                        </Col>
                    </Row>
                    <Form.Text className="text-danger" align="center">{errorMessage}</Form.Text>

                    <Row className={`d-flex align-items-center justify-content-between`}>
                    
                        <Col xs="auto">
                            <Row className={`d-flex justify-content-between  ${signStyles.progressframe}`} >
                            <Col className={signStyles.topline}>
                                <div className={signStyles.rectangle} />
                            </Col>
                            <Col>
                            <FaCheckCircle className={signStyles.circleCompleted} />
                            </Col>
                            <Col className={signStyles.bottomline}>
                                <div className={signStyles.strline} />
                            </Col>
                            </Row>
                            <Row>
                                <p className={signStyles.NameTag} >Basic details</p>
                            </Row>
                        </Col>
                        
                        <Col xs="auto">
                            <Row className={signStyles.progressframe}>
                            <Col className={signStyles.topline}>
                                <div className={signStyles.strline} />
                            </Col>
                            <Col>
                              <div className={signStyles.currentRunIcon}>
                                <div className={signStyles.blueBot}></div>
                              </div>
                            </Col>
                            <Col className={signStyles.bottomline}>
                                <div className={signStyles.strline} />
                            </Col>
                            </Row>
                            <Row>
                                <p className={signStyles.NameTag} >&nbsp;Profile&nbsp;</p>
                            </Row>
                        </Col>
                        

                        <Col xs="auto">
                            <Row className={signStyles.progressframe}>
                            <Col className={signStyles.topline}>
                                <div className={signStyles.strline} />
                            </Col>
                            <Col>
                            <div className={signStyles.indicator2} />
                            </Col>
                            <Col className={signStyles.bottomline}>
                                <div className={signStyles.strline} />
                            </Col>
                            </Row>
                            <Row>
                                <p className={signStyles.NameTag} >Interests</p>
                            </Row>
                        </Col>           
                        
                    </Row>
                    
                    <Row className="d-flex align-items-end">
                        <Col lg={9}>
                                
                          {isLoading && <LoadingOverlay />}
                        
                          <div className="profile-picture mb-3" align="start">
                          <Row className='align-center'>
                            <Col lg={4}>
                            <div className="editable-image-container">
                                <Image
                                  src={profilePicture ? URL.createObjectURL(profilePicture) : '/Content/Images/empty_user.jpg'}
                                  alt="Profile" width={70} height={70} roundedCircle className={signStyles.roundedImage} />
                                <label htmlFor="uploadInput" className="edit-icon">
                                  <TiEdit />
                                </label>
                                <input type="file"
                                  id="uploadInput"
                                  style={{ display: 'none' }}
                                  onChange={handleImageChange}
                                  accept="image/*"
                                />
                            </div>
                            </Col>
                            <Col lg={8}>
                            <Form.Label className='m-0' style={{color: '#40637D'}}>Showcase your personality. Upload your profile picture.</Form.Label>
                            </Col>
                          </Row>
                          <Form.Text className={signStyles.ErrorInvalid}>{formErrors.profileError}</Form.Text>
                          </div>

                          <Form.Group className="mb-2" controlId="formGroupEmail">
                          <Form.Label className='m-0'>Country*</Form.Label>
                          <Form.Select defaultValue={country} onChange={(e) => selectCountryHandler(e.target.value)}
                                onKeyDown={handleKeyDown} >
                                {!!countryArr?.length && countryArr.map(({ label, value }) => (
                                  <option key={value} value={value}> {label} </option>
                                ))}
                          </Form.Select>
                          </Form.Group>

                          {/* <Form.Group className="mb-2" controlId="formGroupPassword">
                            <Form.Label className='m-0'>Phone number*</Form.Label>
                            <PhoneInput
                              country={selectedCountryData.flag}
                              value={selectedCountryData.phoneCode} // Display the country code
                                onChange={(value) => {
                                setSelectedCountryData((prevData) => ({ ...prevData, phoneCode: value }));
                                setPhone(value); // Update the rest of the phone number
                              }}
                              inputStyle={{ width: '100%' }}
                              initialValue={selectedCountryData.phoneCode} // Set the initial value to the country code
                            />
                            <Form.Text className={signStyles.ErrorInvalid}>{formErrors.phoneError}</Form.Text>
                          </Form.Group> */}

                          <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
                            <Form.Label className='m-0'>Fill in your bio and connect with more people!</Form.Label>
                            <Form.Control 
                              as="textarea" 
                              rows={3}
                              value={bio}
                              placeholder="Enter details upto 200 chars"
                              onChange={(e) => setBio(e.target.value)} />
                            <Form.Text className={signStyles.ErrorInvalid}>{formErrors.bioError}</Form.Text>
                          </Form.Group>
                        </Col>
                        <Col lg={3}></Col> 
                    </Row>

                    <Row className="d-flex align-items-end">
                        <Col lg={9}></Col>
                        <Col lg={3} className='text-end'>
                            <Button className={signStyles.SubmitBtn} type="submit" >&nbsp;&nbsp; Next &nbsp;&nbsp;</Button>
                        </Col>
                    </Row>
                  </Form>
                </div>
                </Col>
 
            </Row>

            <Row className={signStyles.smfooters}>
              <SmallFooter />
            </Row>

        </Row>
        
    </Container>
    
  );
};

export default SignupProfile;
