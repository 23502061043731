import { useRef, useEffect, useState } from 'react';
import { Container, Row, Col, Button, InputGroup, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './ExperienceStudio.module.css';
import { SlArrowLeft } from "react-icons/sl";
import experienceServices from '../../../Services/experienceServices';
// import { FaCheckCircle } from "react-icons/fa";
import LoadingOverlay from '../../BaseComponents/LoadingOverlay';
import Resizer from 'react-image-file-resizer';


const ExperienceDetails = ({ onStatusChange, selectedSequence }) => {

  const thumbnailRef = useRef(null);
  const failureErrorRef = useRef(null);
  const [failureError, setFailureError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  
  const [categoriesFetched, setCategoriesFetched] = useState(false);
  const [allCategoryInterests, setAllCategoryInterests] = useState([]);

  useEffect(() => {
    if (!categoriesFetched) {
      async function fetchAllCategoryInterests() {
        try {
          const response = await experienceServices.getAllCategoryInterests();
          //console.log("categoriesFetched Response:", response);
          if (response.statusCode === 200) {
            setAllCategoryInterests(response.data);
            setCategoriesFetched(true);
          }
        } catch (error) {
          console.error("getAllCategoryInterests error:", error);
        }
      }
      fetchAllCategoryInterests();
    }
  }, [categoriesFetched]);

  useEffect(() => {
    if (failureError && failureErrorRef.current) {
          failureErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          failureErrorRef.current.style.backgroundColor = '#ffdddd'; // Highlight error message
        }
    }, [failureError]);


  const handleInterestChange = (e) => {
    const selectedValues = Array.from(e.target.selectedOptions, (option) => option.value);
    setSelectedInterests(selectedValues);
    setSelectedInterests((updatedInterests) => {
      setInterestsString(updatedInterests.join(','));
      return updatedInterests;
    });
  };

  // const UploadThumbnail = (event) => {
  //   const files = event.target.files;
  //   if (files && files.length > 0) {
  //     const thumbnail = URL.createObjectURL(files[0]);
  //     setSelectedThumbnail(thumbnail);
  //     setThumbnailCover(files[0]);
  //   }
  // };
  //console.log("In ExperienceDetails ::", selectedSequence);


  const UploadThumbnail = (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    setThumbnailCover(file); // Store the original file object
  
    // Resize to 1080x1920 (9:16)
    Resizer.imageFileResizer(
      file,
      1080,
      1920,
      'JPEG',
      80,
      0,
      (uri) => {
        setSelectedThumbnail(uri); // Set optimized image URI
      },
      'base64'
    );
  };
  

  function processHashtags(hashtags) {
    
    if (typeof hashtags !== 'string') {
      return "";
    }
    if (hashtags && hashtags !== "") {
      // Use a regular expression to find words that start with #
      const wordsWithHashtags = hashtags.match(/#\w+/g);
      
      if (wordsWithHashtags) {
        // Remove the # from each word and join them with a comma
        const processedWords = wordsWithHashtags.map(word => word.substring(1)).join(', ');
        return processedWords;
      } else {
        return ""; // No hashtags found
      }
    }
    return ""; // hashtags is empty or undefined
  }


  const [experienceTitle, setExperienceTitle] = useState('');
  const [description, setDescription] = useState('');
  const [extraMaterial, setExtraMaterial] = useState('');
  const [noOfContents, setNoOfContents] = useState(parseInt(selectedSequence.length) || '');
  const [productDuration, setProductDuration] = useState('');
  const [experiencePrice, setExperiencePrice] = useState('');
  const [hashtags, setHashtags] = useState('');
  const [contentsArray, setContentsArray] = useState(null);
  const [thumbnailCover, setThumbnailCover] = useState('');
  const [selectedInterests, setSelectedInterests] = useState('');
  const [interestsString, setInterestsString] = useState('');
  
  const [experienceTitleError, setExperienceTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [extraMaterialError, setExtraMaterialError] = useState('');
  const [experienceCategoryError, setExperienceCategoryError] = useState('');
  const [productDurationError, setProductDurationError] = useState('');
  const [noOfContentsError, setNoOfContentsError] = useState('');
  const [experiencePriceError, setExperiencePriceError] = useState('');
  const [thumbnailCoverError, setThumbnailCoverError] = useState('');
  
  const experienceTitleRef = useRef('');
  const descriptionRef = useRef('');
  const extraMaterialRef = useRef('');
  const experiencePriceRef = useRef('');
  const noOfContentsRef = useRef('');
  const thumbnailCoverRef = useRef('');

  const validateForm = () => {
    let isValid = true;
   
    if (experienceTitle.trim() === '') {
      setExperienceTitleError('Workshop title is required');
      isValid = false;
      if (experienceTitleRef.current) {
        experienceTitleRef.current.focus();
      }
      return;
    } else {
      setExperienceTitleError('');
    }

    if (description.trim() === '') {
      setDescriptionError('Please add some description about your workshop');
      isValid = false;
      if (descriptionRef.current) {
        descriptionRef.current.focus();
      }
     return;
    } else {
      setDescriptionError('');
    }

    if (extraMaterial.trim() === '') {
      setExtraMaterialError('Please add some required materials for your workshop.');
      isValid = false;
      if (extraMaterialRef.current) {
        extraMaterialRef.current.focus();
      }
      return;
    } else {
      setExtraMaterialError('');
    }

    if (interestsString.trim() === '') {
      setExperienceCategoryError('Please select your category');
      isValid = false;
      return;
    } else {
      setExperienceCategoryError('');
    }

    if (noOfContents === '') {
      setNoOfContentsError('Please enter no of contents value');
      isValid = false;
      if (noOfContentsRef.current) {
        noOfContentsRef.current.focus();
      }
      return;
    } else {
      setNoOfContentsError('');
    }

    if (productDuration === '') {
        setProductDurationError('Please enter duration');
        isValid = false;
        return;
      } else {
        setProductDurationError('');
      }

    if (experiencePrice === '') {
      setExperiencePriceError('Please enter price for your workshop');
      isValid = false;
      if (experiencePriceRef.current) {
        experiencePriceRef.current.focus();
      }
      return;
    } else {
      setExperiencePriceError('');
    }

    if (thumbnailCover === '') {
      setThumbnailCoverError('Thumbnail is required');
      isValid = false;
      if (thumbnailCoverRef.current) {
        thumbnailCoverRef.current.focus();
      }
      return;
    } else {
      setThumbnailCoverError('');
    }
    return isValid;
  };
  
  
  const saveWorkshop = async () => {
    setIsLoading(true);
    setFailureError("");

    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    let formData = new FormData();
    const resultTags = processHashtags(hashtags);
    // console.log("resultTags :: ", resultTags);

    formData.append('caption', experienceTitle);
    formData.append('description', description);
    formData.append('additionalInfo', extraMaterial);
    formData.append('currency', '$');
    formData.append('price', experiencePrice);
    formData.append('duration', productDuration);
    formData.append('template', thumbnailCover);
    formData.append('feedSize', noOfContents);
    formData.append('interests', interestsString);
    formData.append('tags', resultTags);

    // console.log('saveWorkshop FormData:', formData);
    let formDataObject = Object.fromEntries(formData.entries());
    formDataObject.contentsArray = selectedSequence;
    
    if(formDataObject.contentsArray === null || formDataObject.contentsArray.length === 0) {
      formDataObject.contentsArray = contentsArray;
      formDataObject.feedSize = contentsArray.length;
    }
    
    try {
      const response = await experienceServices.saveDraftWorkshop(formDataObject);
      //console.log("saveWorkshop Response:", response);
      if (response !== null && response.statusCode === 200 &&  response.data !== null && response.data[0].success) {
        onStatusChange('publish-experience');
        localStorage.setItem('draftWID', response.data[0].feedId);
      } else {
        onStatusChange('experience-details');
        // Ensure error message is always a string
        const errorMessage = response && typeof response.message === "string" ? response.message : "Internal error, Please contact support.";
        setFailureError(errorMessage);
      }
    } catch (error) {
      console.error("saveWorkshop Error:", error);
      setFailureError("Internal error, Please contact for support.");
    } finally {
      setIsLoading(false);
    }
  };

  const backtoPrevious = () => {
    onStatusChange('create-workshop');
  };

  useEffect(() => {
    const getDraftWorkshop = async (id) => {
      setFailureError("");
      try {
        setIsLoading(true);
        const response = await experienceServices.getDraftWorkshop(id);
        if (response.statusCode === 200) {
          if (response.data && response.data[0] !== null) {
            const workshopData = response.data[0];
            setExperienceTitle(workshopData.caption);
            setDescription(workshopData.desc);
            setExtraMaterial(workshopData.addInfo);
            setExperiencePrice(workshopData.price);
            setProductDuration(workshopData.duration);
            // setThumbnailCover(workshopData.icon);
            // setSelectedThumbnail(workshopData.icon);
            setHashtags(workshopData.tags);
            setContentsArray(workshopData.contents.content);
            localStorage.removeItem('isEditWorkshop');
          } else {
            setFailureError(response.message || "Workshop not found, Please try after some time.");
          }
        } else {
          setFailureError(response.message || "Workshop not found, Please try after some time.");
        }
      } catch (error) {
        console.error("API Error:", error);
        setFailureError("API error occurred, Please contact support.");
      } finally {
        setIsLoading(false);
      }
    };

    const draftId = localStorage.getItem('draftWID');
    
    if (localStorage.getItem('isEditWorkshop') && draftId) {
      getDraftWorkshop(draftId);
    }
  });

  
  return (
    <Container fluid className="mainPad">

      <Row className={styles.ESWhiteBackdrop}>
        <Row className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <Link onClick={backtoPrevious}>
                <SlArrowLeft className={styles.HoverBackOption} /> &nbsp;
              </Link>
              <p className={styles.labelname}> Workshop details </p>
            </div>
            <div className={`mb-3 ${styles.labelContent}`}> Add details to your product to attract buyers </div>
            {failureError && <div ref={failureErrorRef} key={failureError} className={styles.errorMessage} style={{textAlign: 'center'}}>{failureError}</div>}
        </Row>

        {/* <Row>
        <Col md={1} xs={3}>
        <Row className={`d-flex justify-content-between  ${styles.progressframe}`} >
          <Col className={styles.topline}>
            <div className={styles.strline} />
          </Col>
          <Col>
          <FaCheckCircle className={styles.circleCompleted} />
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
          <p className={styles.NameTag} >Start</p>
        </Row>
        </Col>

        <Col md={1} xs={3}>
        <Row className={styles.progressframe}>
          <Col className={styles.topline}>
            <div className={styles.strline} />
          </Col>
          <Col>
          <FaCheckCircle className={styles.circleCompleted} />
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={styles.NameTag} >Select Media </p>
          </Row>
        </Col>

        <Col md={1} xs={3}>
        <Row className={styles.progressframe}>
          <Col className={styles.topline}>
            <div className={styles.rectangle} />
          </Col>
          <Col>
            <div className={styles.currentRunIcon}>
              <div className={styles.blueBot}></div>
            </div>
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={styles.NameTag} >Product Details</p>
        </Row>
        </Col>
        
        <Col md={1} xs={3}>
        <Row className={styles.progressframe}>
          <Col className={styles.topline}>
            <div className={styles.strline} />
          </Col>
          <Col>
          <div className={styles.indicator2} />
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={styles.NameTag} >Publish </p>
          </Row>
        </Col>
        </Row> */}
      
        <br /><br />
        
        {isLoading ? (
            <LoadingOverlay />
          ) : (
          <Row>
          <Col md={4}>
          
          <Form.Group controlId="experienceTitle" className='mb-2'>
            <Form.Label className={styles.formlabel}>Workshop title*</Form.Label>
            <Form.Control 
              required
              className={styles.formControl}
              type="text"
              placeholder=""
              value={experienceTitle}
              onChange={(e) => setExperienceTitle(e.target.value)}
              ref={experienceTitleRef}
            />
            {experienceTitleError && <div className={styles.errorMessage}>{experienceTitleError}</div>}
          </Form.Group>

          <Form.Group controlId="experienceDescription" className='mb-2'>
            <Form.Label className={styles.formlabel}>Description*</Form.Label>
            <InputGroup>
              <Form.Control 
              required
              className={styles.formControl}
              as="textarea" 
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              aria-label="With textarea"
              placeholder="Make sure you provide as much information as possible to help your customers understand what they are buying. If your workshop is a series of videos, make sure you include this detail."
              rows={5}
               />
            </InputGroup>
            {descriptionError && <div className={styles.errorMessage}>{descriptionError}</div>}
          </Form.Group>

          <Form.Group controlId="extraMaterial" className='mb-2'>
            <Form.Label className={styles.formlabel}>Materials required for workshop*</Form.Label>
            <InputGroup>
              <Form.Control 
              required
              as="textarea"
              className={styles.formControl}
              value={extraMaterial}
              onChange={(e) => setExtraMaterial(e.target.value)}
              aria-label="With textarea"
              rows={4}
               />
            </InputGroup>
            {extraMaterialError && <div className={styles.errorMessage}>{extraMaterialError}</div>}
          </Form.Group>

          <Form.Group controlId="Category" className='mb-2'>
          <Form.Label className={styles.formlabel}>Category*</Form.Label>
          <Form.Control
            required
            as="select"
            value={selectedInterests}
            className={styles.formSelect}
            onChange={handleInterestChange}
          >
            <option value="" disabled>Please select category</option>
            {allCategoryInterests.flatMap((category) => category.interests).map((interest) => (
              <option key={interest} value={interest}>
                {interest}
              </option>
            ))}
            <option value="others">Others</option>
          </Form.Control>
          <div>
          </div>
          {experienceCategoryError && <div className={styles.errorMessage}>{experienceCategoryError}</div>}
          </Form.Group>
          
          <Form.Group controlId="noOfContents" className='mb-2'>
            <Form.Label className={styles.formlabel} >No. of videos*</Form.Label>
              <Form.Control
                required
                type="text"
                className={styles.formControl} 
                value={noOfContents}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    setNoOfContents(value);
                  }
                }}
                aria-label="noOfContents" />
            {noOfContentsError && <div className={styles.errorMessage}>{noOfContentsError}</div>}
          </Form.Group>

          <Form.Group controlId="productDuration" className='mb-2'>
            <Form.Label className={styles.formlabel}>Duration (minutes)*</Form.Label>
            <Form.Control
              required
              type="text"
              className={styles.formControl}
              value={productDuration}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  setProductDuration(value);
                }
              }}
            />
            {productDurationError && <div className={styles.errorMessage}>{productDurationError}</div>}
          </Form.Group>

          <Form.Group controlId="price" className='mb-2'>
            <Form.Label className={styles.formlabel} >Price*</Form.Label>
            <InputGroup>
              <InputGroup.Text className={styles.formControl}>$</InputGroup.Text>
              <Form.Control
                type="text"
                required
                className={styles.formControl} 
                value={experiencePrice}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    setExperiencePrice(value);
                  }
                }}
                aria-label="Dollar" />
            </InputGroup>
            {experiencePriceError && <div className={styles.errorMessage}>{experiencePriceError}</div>}
          </Form.Group>
          
          <Form.Group controlId="Experiencehashtags" className='mb-2'>
            <Form.Label className={styles.formlabel}>Add hashtags (upto 10) </Form.Label>
            <InputGroup>
              <Form.Control 
                as="textarea"
                value={hashtags} 
                className={styles.formControl}
                onChange={(e) => setHashtags(e.target.value)}
                aria-label="With textarea"
                rows={4} // Set the number of rows (visible lines)
              />
            </InputGroup>
          </Form.Group>

          </Col>

          <Col md={2}></Col>
          <Col md={6}>
            <Row className={`mb-3`}>
            {selectedThumbnail ? (
                <img
                  className={styles.ThumbnailVisibleImagesSize}
                  alt="Thumbnail"
                  src={selectedThumbnail}
                />
              ) : (
                <img
                  className={styles.ThumbnailVisibleImagesSize}
                  alt="Empty Thumbnail"
                  src="/Content/Images/General/no-thumbnail-bg.png"
                />
              )}

              {thumbnailCoverError && <div className={`mb-3 mt-3 ${styles.errorMessage}`}>{thumbnailCoverError}</div>}
            </Row>
            
            <Row>
              <Col md={4} className="d-flex justify-content-start">
                <Row className="mt-3 d-flex justify-content-center align-items-center">
                  <input
                    type="file"
                      accept="image/*"
                      onChange={UploadThumbnail}
                      style={{ display: 'none' }}
                    ref={thumbnailRef}
                  />
                  <div className={`${styles.uploadThumbnailSection}`} onClick={() => thumbnailRef.current.click()}>
                      <div className={`${styles.uploadIcondiv}`}>
                        <img
                          src="/Content/Images/General/document-upload.png"
                          className={`${styles.uploadbtnImage}`}
                          alt="upload"
                        />
                      </div>
                      <label htmlFor="fileInput" className={`${styles.uploadNameTag}`}>
                        Upload an image
                      </label>
                  </div>
                </Row>
              </Col>
              <Col md={8}>
                <Form.Label className={`mt-3 ${styles.imageContentNoteTextStyle}`}>
                  This image will appear in the product catalogue and in the User Feed on hapifyr. 
                  Optimal image size is <strong>1080 x 1920 px (9:16)</strong>.
                </Form.Label>
              </Col>
            </Row>
          </Col>
          </Row>
          )}

        <br /><br />

        <Row className="justify-content-end">
          <Col xs="auto">
            <Link onClick={backtoPrevious}>
              <Button variant="outline-primary" >&nbsp;&nbsp; Back &nbsp;&nbsp;</Button>&nbsp;&nbsp;
            </Link>
          </Col>
          <Col xs="auto">
            <Button variant="primary" onClick={saveWorkshop} >&nbsp;&nbsp; Save &nbsp;&nbsp;</Button>
          </Col>
        </Row>
       
        <br /><br />

      </Row>
    <br/><br/><br/> 
    </Container>
  );
};

export default ExperienceDetails;