import { useState, useEffect } from 'react';
import { BeatLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { Container, Row, Button, Form, Modal } from 'react-bootstrap';
import styles from './crp.module.css';
import CopyUrlButton from '../BaseComponents/CopyUrlButton';
import Tooltip from '../BaseComponents/Tooltip';
import { FaCopy } from 'react-icons/fa';
import cartServices from '../../Services/cartServices';


const RefferalProgram = () => {
    // Initialize referral code
    let referralCode = null;
    const storedReferralCode = localStorage.getItem("userReferralCode");

    if (storedReferralCode && storedReferralCode !== "undefined") {
        referralCode = storedReferralCode;
    }

    // State variables
    const [isStripe, setIsStripe] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPromoApplied, setIsPromoApplied] = useState(false);
    const [promoKey, setPromoKey] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    // Functions to handle modal visibility
    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    // Fetch user profile data from localStorage
    useEffect(() => {
        const profileData = localStorage.getItem("userProfileData");

        if (profileData) {
            try {
                const parsedData = JSON.parse(profileData);
                if (parsedData?.stripeAccountExists) {
                    setIsStripe(true);
                }
            } catch (error) {
                console.error("Error parsing userProfileData:", error);
            }
        }
    }, []);

    // Fetch user promotions
    useEffect(() => {
        const fetchUserPromotions = async () => {
            try {
                setIsLoading(true);
                const response = await cartServices.fetchReferralPromotions();

                if (
                    response &&
                    response.statusCode === 200 &&
                    response.data?.length > 0 &&
                    response.data[0].promoKey
                ) {
                    setPromoKey(response.data[0].promoKey);
                    setIsPromoApplied(true);
                } else {
                    setIsPromoApplied(false);
                }
            } catch (error) {
                console.error("API Error:", error);
                setIsPromoApplied(false);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserPromotions();
    }, []);

    // Activate referral program
    const activateReferrals = async () => {
        setErrorMessage("");
        setSuccessMessage("");

        if (!promoKey || promoKey === "undefined") {
            setErrorMessage("Enter your referral code to start earning rewards!");
            return;
        }

        try {
            setIsLoading(true);
            const response = await cartServices.ActivateReferralPromotions(promoKey);

            if (response && response.statusCode === 200) {
                setPromoKey(response?.data[0]?.promoKey);
                setIsPromoApplied(true);
                setSuccessMessage(response?.message || "Referral program activated successfully.");

                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                setIsPromoApplied(false);
                setErrorMessage(response?.message || "Internal response error. Please try again later.");
            }
        } catch (error) {
            console.error("API Error:", error);
            setIsPromoApplied(false);
            setErrorMessage("Internal response error. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

  return (
    <Container fluid className='minPadding'>
        
        <Row>
            <div className={styles.viewActivityDiv}>
                <Link to="/crp/activity-board" className={styles.viewActivitiesLabel}>
                    <label> View Your Activities </label>
                </Link>
            </div>

            <div className={`boxBody ${styles.crprogramDiv}`}>
                <Row>
                    <h2 className={styles.headerTextTemplate}>Community Referral program</h2>
                    <label className={`${styles.ProgramTextStyle}`} style={{display: 'none'}}>Join us on an incredible journey of creativity, connection, and personal growth. 
                        Whether you want to try a new hobby, connect with friends, or simply find inspiration, 
                        hapifyr is here to help you achieve your goals. Together, we can ignite happiness in ourselves and those around us, one meaningful workshop at a time.
                        Welcome to hapifyr - it's time to Ignite Happiness!
                    </label>
                    <label className={`mt-2 ${styles.ProgramTextStyle}`}>We're building a community who connect through common creative interests, 
                        and by referring hosts and workshop participants, our community is rewarded. 
                        We distribute 10% of the Hapifyr services fee to community growth initiatives, including referral of hosts and workshop purchases.
                    </label>
                    <label className={`mt-2 mb-3 ${styles.ProgramTextStyleBold}`}>
                        Copy your unique link below and share it with your family and friend so they can join you on hapifyr.
                    </label>
                    <div className={styles.ShareOptionDiv}>
                        <div className={styles.SocialMediaIconsDiv}>
                            <label className={styles.sharewithSocialText} onClick={handleModalOpen}>Share on social platforms</label>
                            <Modal show={showModal} onHide={handleModalClose} centered>
                                    <Modal.Header closeButton className={styles.referralURLModalHeader}>
                                        {/* <Modal.Title className={styles.shareCopyText}>Copy your invitation link to share</Modal.Title> */}
                                    </Modal.Header>
                                    <Modal.Body className="text-center">
                                    <Row>
                                    <div className={styles.referralURLTextformdiv}>
                                        <p className={styles.referralTextform}>
                                        Hi. I am using a great new platform that will help us connect around creative interests and Click here to learn more and sign up 
                                            <button
                                                type="button"
                                                className={`${styles.referralClickHereButton} btn btn-link`}
                                                onClick={() => {
                                                    const referralUrl = `${window.location.origin}/?ref=${referralCode}`;
                                                    navigator.clipboard.writeText(referralUrl);
                                                    alert('Referral link copied! Share it with your friends.');
                                                }}
                                            >
                                                {`${window.location.origin}?ref=${referralCode}`}
                                            </button>
                                        </p>
                                        <br />
                                        <button className={styles.ClickCopyBtn}>
                                            <FaCopy className="ml-2" />
                                            <CopyUrlButton name="Copy" url={`${window.location.origin}?ref=${referralCode}`} referralText=" Hi. I am using a great new platform that will help us connect around creative interests and Click here to learn more and sign up " />
                                        </button>
                                    </div>
                                    </Row>
                                    </Modal.Body>
                            </Modal>
                        </div>

                        <div className={styles.SocialMediaIconsDiv}>
                            {isStripe ? (
                                <div>
                                    <Button className={styles.invitewithMailBtn} as={Link} to="/profile/stripe-account-details">
                                        Stripe Connect Dashboard
                                    </Button>
                                    <Button className={styles.invitewithMailBtn}>
                                        <CopyUrlButton name="Copy your invitation link" url={`${window.location.origin}?ref=${referralCode}`} />
                                    </Button>
                                </div>
                            ) : (
                                <>
                                <div>
                                    <Button className={styles.invitewithMailBtn} as={Link} to="/profile/stripe-account-details">
                                        Sign up with Stripe
                                    </Button>
                                </div>
                                <div>
                                    <Tooltip content="Sign up with Stripe to unlock exclusive referral program benefits!">
                                        <Button className={styles.invitewithMailBtn} disabled>
                                            Copy your invitation link
                                        </Button>
                                    </Tooltip>
                                </div>
                                </>
                            )}
                        </div>
                    </div>
                </Row>
            </div>

            <div className={styles.ActiviteIdDiv}>
                {isLoading ? (
                    <div className='BeatLoaderContainerBox'>
                        <BeatLoader color="#2E637D" loading={true} height={10} width={200} />
                        <label>Loading...</label>
                    </div>
                ) : (
                    <Row>
                        <h2 className={styles.headerTextTemplate}>Enter your Referral ID</h2>
                        <label className={styles.labelTextStyle}>Link your Referral ID to get extra rewards</label>
                        
                        <label className={`mt-2 mb-1 ${styles.ProgramTextStyleBold}`}>Host Referrals*</label>
                        <label className={styles.ProgramTextStyle}>To be rewarded for referring a host to the hapifyr platform, 
                            simply share your unique link with the prospective host. 
                            If they sign up to hapifyr using your shared link, and go on to create and sell workshops on hapifyr,
                            you will receive 5% of the workshop price for all future workshops.
                        </label>

                        <label className={`mt-3 mb-1 ${styles.ProgramTextStyleBold}`}>Buyer Referrals*</label>
                        <label className={`mb-3 ${styles.ProgramTextStyle}`}>Our community can be rewarded for generating interest in workshops they participate in.
                            Simply complete a ‘Boast' at the end of a workshop you buy. A boast includes a Rating and Review,
                            and a photo of what you created in your workshop. We'll post your ‘Boast' in the community feed, 
                            and if a hapifyr user clicks on your boast, and goes on to buy the workshop in that session, you will receive 5% of the workshop price. 
                            Share your ‘boast' on other social platforms, and if a post goes on to generate a sale for that workshop in the same user session, you will receive 5% of the workshop price.
                        <br />
                        <br />
                            *<Link to="/info/terms-and-conditions" className={styles.programTextStyleTCs}>Terms and Conditions </Link>apply.
                        </label>

                        <div className='text-center'>

                        {errorMessage ? <label style={{ color: 'red',textAlign: 'center',fontWeight: 'bold',fontSize: '1.0em' }}>{errorMessage}</label> : null}
                        {successMessage ? <label style={{ color: 'green',textAlign: 'center',fontWeight: 'bold',fontSize: '1.0em' }}>{successMessage}</label> : null}

                        <Form.Group className="mb-3 mt-3" controlId="promoInput">
                        <Form.Label style={{display: 'none', visibility: 'none'}}>Referral ID</Form.Label>
                        {isPromoApplied ? (
                            <Form.Control 
                                size="lg"
                                type="text" 
                                value={promoKey} 
                                readOnly 
                                disabled 
                            />
                        ) : (
                            <Form.Control 
                                size="lg"
                                type="text" 
                                placeholder="Enter Referral ID"
                                value={promoKey}  
                                onChange={(e) => setPromoKey(e.target.value)}  
                            />
                        )}
                        </Form.Group>
                        </div>
                        <div className={styles.ShareOptionDiv}>
                            <div className={styles.SocialMediaIconsDiv}></div>
                            <div className={styles.SocialMediaIconsDiv}>
                            {isPromoApplied ? (
                                <Button className={styles.promoCodeActivateBtn} disabled> Promo Code Applied </Button>
                            ) : (
                                <Button className={styles.promoCodeActivateBtn} onClick={activateReferrals}> Activate </Button>
                            )}
                            </div>
                        </div>

                    </Row>
                )}
            </div>
        </Row>
    </Container>            
  );
};

export default RefferalProgram;
