import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Image, Toast } from 'react-bootstrap';
import { Link } from "react-router-dom";
import styles from './ExperienceStudio.module.css';
import { FaAngleDown } from "react-icons/fa";
import experienceServices from "../../../Services/experienceServices";
import LoadingOverlay from '../../BaseComponents/LoadingOverlay';
// import { ExperienceContext } from "./ExperienceContext";


const PublishExperiencewithStripe = ({ stripeAvailable, onStatusChange, previewExperienceForm, onPublishSuccess }) => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isPublishChecked, setIsPublishChecked] = useState(false);
    const [showDescription, setShowDescription] = useState(true);
    const [showMaterial, setShowMaterial] = useState(true);
    const [experienceForm, setExperienceForm] = useState(null);
    const [userProfileData, setUserProfileData] = useState(null);
  
    const toggleShowDescription = () => setShowDescription(!showDescription);
    const toggleShowMaterial = () => setShowMaterial(!showMaterial);
    
    useEffect(() => {
      const getDraftWorkshop = async (id) => {
        try {
          setIsLoading(true);
          const response = await experienceServices.getDraftWorkshop(id);
            if (response !== null && response.statusCode === 200) {
              if (response.data && response.data[0] !== null) {
                setExperienceForm(response.data[0]);
              } else {
                setErrorMessage(response.message || "Internal error, Please contact support.");
              }
            } else {
              setErrorMessage(response.message || "Internal error, Please contact support.");
            }

        } catch (error) {
          console.error("API Error:", error);
          setErrorMessage("API error occurred, Please contact support.");
        } finally {
          setIsLoading(false);
        }
      };
  
      const draftId = localStorage.getItem('draftWID');
      if (draftId) {
        getDraftWorkshop(draftId);
      }
  
      const profileData = localStorage.getItem('userProfileData');
      if (profileData) {
        setUserProfileData(JSON.parse(profileData));
      }
  
    }, [onStatusChange, setExperienceForm]);


    const publishingWorkshop = async () => {
      setErrorMessage("");
      const draftId = localStorage.getItem('draftWID');
      if (!stripeAvailable) {
        setErrorMessage('You must check payment details to proceed.');
        return;
      } else if (!draftId && draftId !== "") {
        setErrorMessage('You must have feed ID to proceed, Contact support team.');
        return;
      } else {
        setErrorMessage("");
      }

      if (!isPublishChecked) {
        setErrorMessage('Please agree to the terms & conditions before you publish.');
        return;
      } else {
        setErrorMessage('');
      }


      try {
        setIsLoading(true);
        // console.log("publishingExperience Request::", experienceForm);
        const response = await experienceServices.publishWorkshop(draftId);
        // console.log("publishingExperience Response::", response);
        if (response.statusCode === 200) {
          if(response.data !== null && response.data[0] !== null && response.data[0].published){
            previewExperienceForm(experienceForm);
            localStorage.removeItem('draftWID');
            onPublishSuccess(true);
            onStatusChange('success-preview');
          } else {
            onStatusChange('publish-experience-with-stripe');
            setErrorMessage(response.message || "Publish failed, Please try after some time.");
          }
        } else {
          onStatusChange('publish-experience-with-stripe');
          setErrorMessage(response.message || "Publish failed, Please try after some time.");
        }
    
      } catch (error) {
        onStatusChange('publish-experience-with-stripe');
        console.error("publishingWorkshop Error::", error);
        setErrorMessage('Internal error, Workshop creation failed.');
      } finally {
        setIsLoading(false);
      }
    };
    
    const editWorkshop = () => {
      localStorage.setItem('isEditWorkshop', true);
      onStatusChange('experience-details');
    };
  
  return (
    <Container fluid className="mainPad">

      {isLoading ? (
            <LoadingOverlay />
          ) : (
        <Row className="mt-2 mb-2">
        <Col md={8} xs={12}>
        <Row className={styles.ESWhiteBackdrop}>
          
          {errorMessage && <div className={styles.errorMessage} style={{ textAlign: "center" }}>{errorMessage}</div>}

              <Row>
                <div className="d-flex align-items-center">
                {userProfileData && userProfileData.imageUrl ? (
                    <>
                    <Image
                        src={userProfileData.imageUrl ? userProfileData.imageUrl : '/Content/Images/empty_user.jpg'}
                        alt="Profile"
                        roundedCircle
                        className={`roundedUser ${styles.previewProfileImg}`}
                    />
                    <div className={`${styles.flexStyleforUserdiv}`}>
                        <h3 className={styles.productSellername}>{userProfileData.name}</h3>
                        <label className={styles.productSellerdesc}>{userProfileData.bio}</label>
                    </div>
                    </>
                ) : (
                    <div className={styles.productSellername} >No user data available</div> // Optional: Add a fallback in case userProfileData or imageUrl is not available
                )}
                </div>
                </Row>
                
                {experienceForm && Object.keys(experienceForm).length > 0 ? (
                <>
                <Row className="mt-3 mb-1">
                    <h3 className={styles.productCaption}>{experienceForm.caption}</h3>
                </Row>

                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className={styles.workshopContainerdiv}>
                  {experienceForm.icon ? (
                    <img src={experienceForm.icon ? experienceForm.icon : "/Content/Images/SellerExpStudio/noImageBG.jpeg"}
                        className={styles.workshopThumbnailImage}
                        alt="thumbnail"
                    />
                    ) : (
                    <img
                        className={`d-block w-100 ${styles.workshopThumbnailImage}`}
                        src="/Content/Images/SellerExpStudio/noImageBG.jpeg"
                        alt="thumbnail"
                    />
                  )}
                  </div>
                </Col>
          
                <Row className={`mt-4`}>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <div className={`overlap-group ${styles.productDetailsBox}`}>
                      <div className={`mb-3 ${styles.ProductDetailsNameTagSetup}`}>
                        <label className={styles.ProductBoxLabel}>Duration</label>
                          <div className={styles.IconSetup}>
                            <Image
                                src='/Content/Images/icons/icons-sundial.png'
                                alt="duration_icon"
                                className={styles.IconSetImage}   
                              />
                          </div>
                      </div>
                      <label className={styles.ProductBoxlabelValue}>{experienceForm.duration}&nbsp;Mins</label>
                    </div>
                  </Col>
        
                  <Col xs={4} sm={4} md={4} lg={4}>
                  <div className={`overlap-group ${styles.productDetailsBox}`}>
                      <div className={`mb-3 ${styles.ProductDetailsNameTagSetup}`}>
                        <label className={styles.ProductBoxLabel}>Price</label>
                          <div className={styles.IconSetup}>
                            <Image
                                src='/Content/Images/icons/icons-dollar-coin.png'
                                alt="price_icon"
                                className={styles.IconSetImage}   
                              />
                          </div>
                      </div>
                      <label className={styles.ProductBoxlabelValue}>{experienceForm.currency}&nbsp;{experienceForm.price}</label>
                  </div>
                  </Col>
        
                  <Col xs={4} sm={4} md={4} lg={4}>
                      <div className={`overlap-group ${styles.productDetailsBox}`}>
                      <div className={`mb-3 ${styles.ProductDetailsNameTagSetup}`}>
                        <label className={styles.ProductBoxLabel}>Rating</label>
                          <div className={styles.IconSetup}>
                            <Image
                                src='/Content/Images/icons/icons-rating.png'
                                alt="rating_icon"
                                className={styles.IconSetImage}   
                              />
                          </div>
                      </div>
                      <label className={styles.ProductBoxlabelValue}>N/A</label>
                  </div>
                  </Col>

                </Row>
          
                <br /><br />
                <Row className={`mt-4 mb-4`}>
                  <Col>
                    <div className={styles.toggleDropdowndiv}>
                      <label className={styles.accordionHeader}>Description</label>
                      <Button onClick={toggleShowDescription} className={`${styles.toggleDropdownClick}`}>
                        <FaAngleDown />
                      </Button>
                    </div>
                    <Toast show={showDescription} onClose={toggleShowDescription} className={styles.toggleToastStyles}>
                      <label className={styles.accordionDescription}>{experienceForm.desc} &nbsp;&nbsp;&nbsp;&nbsp;</label>
                    </Toast>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <div className={styles.toggleDropdowndiv}>
                      <label className={styles.accordionHeader}>Extra Material</label>
                      <Button onClick={toggleShowMaterial} className={`${styles.toggleDropdownClick}`}>
                        <FaAngleDown />
                      </Button>
                    </div>
                    <Toast show={showMaterial} onClose={toggleShowMaterial} className={styles.toggleToastStyles}>
                      <label className={styles.accordionDescription}>{experienceForm.addInfo} &nbsp;&nbsp;&nbsp;&nbsp;</label>
                    </Toast>
                  </Col>
                </Row>

                </>
                ) : (
                    <Row className="mt-2 mb-2">
                        <h3 className={styles.errorMessage} style={{textAlign: 'center'}}> Workshop data is not available.</h3>
                    </Row>
                )}
                
        </Row>
        </Col>
        <Col md={4} xs={12}>
          <div className="mb-2 mt-2">
            <Col xs="auto">
              <div className={styles.PaymentTermsdiv}>
                        <label className={styles.PaymentTermsLabel}>
                          <input 
                            type="checkbox"
                            name="PublishCheck"
                            id="inline-checkbox-1"
                            checked={isPublishChecked}
                            onChange={(e) => setIsPublishChecked(e.target.checked)}
                            className={styles.CustomCheckbox}
                          />
                          <label>
                            By publishing my workshop I agree to the 
                              &nbsp;
                              <Link 
                                className={styles.LinkTagDirect} 
                                to="/info/terms-and-conditions" 
                                target='_blank' 
                                rel='noopener noreferrer'
                              >
                                Terms and Conditions
                              </Link> 
                              &nbsp;
                            including payment terms available at Hapifyr.
                          </label>
                        </label>
              </div>
            </Col>
          </div>
          <Row className={`${styles.PaddingSetupRow}`}>
          <Row className={`justify-content-end`}>
            <Col xs="auto">
                <Button className={styles.PublishSubmitButtonEdit} onClick={editWorkshop} >&nbsp; Edit &nbsp;</Button>&nbsp;&nbsp;
            </Col>
            <Col xs="auto">
              <Button onClick={publishingWorkshop} className={styles.PublishSubmitButtons} >&nbsp; Publish &nbsp;</Button>
            </Col>
          </Row>
        </Row>
        </Col>
        
        </Row>

      )}

    <br/><br/><br/>
    </Container>
  );
};

export default PublishExperiencewithStripe;
