import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from './Navbar/Header';
import CreateWorkshop from './SellerDashboard/ExperienceStudio/CreateWorkshop';
import CreateExperience from './SellerDashboard/ExperienceStudio/CreateExperience';
import SelectMedia from './SellerDashboard/ExperienceStudio/SelectMedia';
import MediaSequence from './SellerDashboard/ExperienceStudio/MediaSequence';
import ExperienceDetails from './SellerDashboard/ExperienceStudio/ExperienceDetails';
import PublishExperience from './SellerDashboard/ExperienceStudio/PublishExperience';
import SellerAccountCreation from './SellerDashboard/ExperienceStudio/SellerAccountCreation';
import AccountSuccess from './SellerDashboard/ExperienceStudio/AccountSuccess';
import AccountFailure from './SellerDashboard/ExperienceStudio/AccountFailure';
import ScrollToTop from './BaseComponents/ScrollToTop';
import PublishExperiencewithStripe from './SellerDashboard/ExperienceStudio/PublishExperiencewithStripe';
import { ExperienceProvider } from './SellerDashboard/ExperienceStudio/ExperienceContext';
import SuccessPublishPreview from './SellerDashboard/ExperienceStudio/SuccessPublishPreview';


const SellerExperiences = () => {

  const { currentStatus } = useParams();  // for successful stripe verification
  const navigate = useNavigate();
  const [loginStatus] = useState('loggedIn');
  const [expStudioStatus, setExpStudioStatus] = useState(currentStatus || 'create-experience'); // Initial status
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [selectedMediaData, setSelectedMediaData] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState([]);
  const [isStripeAvailable, setIsStripeAvailable] = useState(false);
  const [previewForm, setPreviewForm] = useState(null);

  useEffect(() => {
    const isAccSuccess = sessionStorage.getItem('isAccSuccess');
    if (isAccSuccess && currentStatus === 'publish-experience-with-stripe') {
      setExpStudioStatus('publish-experience-with-stripe');
      setIsStripeAvailable(true);
      sessionStorage.setItem('isAccSuccess', false);
    } else if (currentStatus === 'publish-experience'){
      setExpStudioStatus('publish-experience');
      sessionStorage.setItem('isAccSuccess', false);
    } else {
      sessionStorage.setItem('isAccSuccess', false);
    }
  }, [currentStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
    navigate(`/seller-experiences/${expStudioStatus}`);
  }, [expStudioStatus, navigate]);

  const handleStatusChange = (newStatus) => {
    setExpStudioStatus(newStatus);
  };

  const appendtoMediaLibrary = (selectedMedia) => {
    setSelectedMedia(selectedMedia);
  };

  const selectedMediatoSequence = (selectedMediatoSequence) => {
    setSelectedMediaData(selectedMediatoSequence);
  };

  const appendSelectedSequence = (appendSelectedSequence) => {
    setSelectedSequence(appendSelectedSequence);
  };

  const previewExperienceForm = (previewExperienceForm) => {
    setPreviewForm(previewExperienceForm);
  };

  const [isPreviewExperience, setIsPreviewExperience] = useState(false);
  const isPublished = (isPublished) => {
    
    setIsPreviewExperience(isPublished);
  };


  return (
    <div className="SellerExperiencesDiv">
      <ScrollToTop />
      <Header loginStatus={loginStatus} />

      <main className="MainpartSetup viewportHt">
      <ExperienceProvider>
        {expStudioStatus === 'create-workshop' && <CreateWorkshop onStatusChange={handleStatusChange} appendSelectedSequence={appendSelectedSequence} />}

        {expStudioStatus === 'create-experience' && <CreateExperience onStatusChange={handleStatusChange} appendtoMediaLibrary={appendtoMediaLibrary} />}
        {expStudioStatus === 'select-media' && <SelectMedia onStatusChange={handleStatusChange} selectedMedia={selectedMedia} selectedMediatoSequence={selectedMediatoSequence} />}
        {expStudioStatus === 'setting-sequence' && <MediaSequence onStatusChange={handleStatusChange} selectedMediaData={selectedMediaData} appendSelectedSequence={appendSelectedSequence} />}
        {expStudioStatus === 'experience-details' && <ExperienceDetails onStatusChange={handleStatusChange} selectedSequence={selectedSequence} />}
        {expStudioStatus === 'publish-experience' && <PublishExperience onStatusChange={handleStatusChange} previewExperienceForm={previewExperienceForm} onPublishSuccess={isPublished} />}
        {expStudioStatus === 'create-account' && <SellerAccountCreation />}
        {expStudioStatus === 'account-success' && <AccountSuccess onStatusChange={handleStatusChange} />}
        {expStudioStatus === 'account-failed' && <AccountFailure onStatusChange={handleStatusChange} />}
        {expStudioStatus === 'publish-experience-with-stripe' && <PublishExperiencewithStripe stripeAvailable={isStripeAvailable} onStatusChange={handleStatusChange} previewExperienceForm={previewExperienceForm} onPublishSuccess={isPublished} />}
        {expStudioStatus === 'success-preview' && <SuccessPublishPreview onPublishSuccess={isPreviewExperience} previewForm={previewForm} />}
      </ExperienceProvider>
      </main>

      {/* Add more conditions as needed */}
    </div>

  );
}


export default SellerExperiences;