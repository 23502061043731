import React from 'react';
import { Container, Image, Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";
import styles from './publicProfile.module.css';


const SellerBoasts = ({ posts, UserFollowHandler, MutateBoastLikeStatus }) => {

  // console.log("Boasts::", posts);

  return (
    <Container className={`mb-4 noPadding`}>
        <div className="pb-2">
            <h5 className={`mt-3 mb-2 ${styles.mainTitleHeader}`}>
                Boasts &nbsp;
                {posts && (
                    <Badge pill className={styles.workshopsCountBg}>
                        {posts.length}
                    </Badge>
                )}
            </h5>
        </div>

    {posts && posts.length > 0 ? (
        posts.map((post, index) => (
            <React.Fragment key={index}>
                <div className={`mb-4 ${styles.FeedBody}`}>
                    <div className={styles.feedContainerdiv} key={post.content.id}>
                        {post.boast ? (
                            post.boast.contents.map((boast, boastIndex) => (
                                <React.Fragment key={boastIndex}>
                                    <Link to={`/product-details/boast/${post.boast.boastId}`}>
                                        <Image src={boast.path || '/Content/Images/General/no_thumbnail.png'} alt="thumbnail" fluid roundedCircle className={styles.feedThumbnail} />
                                    </Link>
                                    <div className={styles.boastContent}>
                                        <p className={styles.boastReviewText}>{post.boast.comments}</p>
                                    </div>
                                    <div className={styles.feedContentSide}>
                                        <div className={styles.feedUserProfilediv}>
                                        <Link to={`/product-details/workshop/${post.content.id}`}>
                                            <Image src={post.content.cover || '/Content/Images/General/no_thumbnail.png'} alt="content" roundedCircle className={styles.feedUserImg} />
                                        </Link>
                                        </div>
                                        <div className={styles.feedContentdiv}>
                                            {post.userDetails && (
                                            <Link to={`/seller/public-profile/${post.userDetails.username}`}>
                                                <div className={styles.feedIconwithCountsdiv}>
                                                <div className={styles.feedIconFollowAdddiv}>
                                                <Image 
                                                    src={post.userDetails.imageUrl ? post.userDetails.imageUrl : '/Content/Images/empty_user.jpg'} 
                                                    className={styles.feedIconFollowImg} 
                                                    />
                                                    {!post.userDetails.contentOwner && (
                                                    <Link onClick={() => { UserFollowHandler(post.userDetails.id, 'boast') }}>
                                                    <Image 
                                                        src={post.userDetails.isFollowing ? "/Content/Images/icons/icons-cancel.png" : "/Content/Images/icons/icons-plus.png"} 
                                                        className={styles.feedIconFollowAddIcon} 
                                                    />
                                                    </Link>
                                                    )}
                                                </div>
                                                </div>
                                            </Link>
                                            )}

                                            <div className={styles.feedIconwithCountsdiv}>
                                                <Image src="/Content/Images/icons/icons-rating.png" className={styles.feedIconImg} />
                                                <label className={styles.feedUserContentFont}>{post.boast.rating}</label>
                                            </div>
                                            <div className={styles.boastIconwithCountsdiv}>
                                                <Image src={post.boast.isLikedByMe ? '/Content/Images/icons/icons-fireRed.png' : '/Content/Images/icons/icons-fire.png' }
                                                onClick={()=>{MutateBoastLikeStatus(post.content.id, post.boast.boastId)}}
                                                className= {`${styles.boastIconImg} ${post.boast.isLikedByMe ? styles.animateFire : styles.animateDisfire}`} />
                                                <label className={styles.boastUserContentFont}>
                                                {post.boast.likesCount ?? 0}
                                                {/* handles both null and undefined */}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))
                        ) : null}
                    </div>
                </div>
            </React.Fragment>
        ))
    ) : (
        <div className={`${styles.FeedBody}`}>
            <p style={{ textAlign: 'center', margin: '0', fontWeight: 600, }}>No boasts are available.</p>
        </div>
    )}
    </Container>
    
  );
};

export default SellerBoasts;