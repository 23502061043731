import { useState } from 'react';
import { Card, Container, Image, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import styles from './Feed.module.css';
import Tooltip from '../../BaseComponents/Tooltip';
import CopyUrlButton from '../../BaseComponents/CopyUrlButton';
import { FaCopy } from 'react-icons/fa';


const ProductComponent = ({ feed, FollowFeedUser, AddItemtoCart }) => {

  // console.log(feed);
  let refferalCode = null;
  let isStripe = false;
  const storedRefferalCode = localStorage.getItem('userReferralCode');
  if (storedRefferalCode && storedRefferalCode !== 'undefined') {
    refferalCode = storedRefferalCode;
  }
  if(localStorage.getItem('stripeAccountExists')) {
    isStripe = localStorage.getItem('stripeAccountExists');
  }

  const [showModal, setShowModal] = useState(null); // State to track which feed's modal is open

  const handleModalOpen = (feedId) => {
    setShowModal(feedId);
  };

  const handleModalClose = () => {
    setShowModal(null);
  };

  return (
    <Card className={`mb-4 ${styles.FeedBody}`}>
      <Container className='noPadding'>
        <div className={`${styles.feedContainerdiv}`} key={feed.fId}>
                      <Link to={`/product-details/${feed.feedType.toLowerCase()}/${feed.fId}`} key={feed.fId}>
                        <Image src={feed.content || '/Content/Images/General/no_thumbnail.png'} alt="feed_thumbnail" fluid roundedCircle className={styles.feedThumbnail} />
                      </Link>
                        <div className={styles.feedContent}>
                          <Image src="/Content/Images/icons/document-text.png" className={styles.feedDescTextImgSize} />
                            &nbsp;
                          <p className={styles.feedContentTextColorFont}>{feed.desc}</p>
                        </div>
                        <div className={styles.feedContentSide}>
                            <div className={styles.feedContentdiv}>
                              <div className='followUsersetupDiv mb-2' >
                                <Tooltip content={feed.isFollowing ? `Unfollow ${feed.postedByUsername}` : `Follow ${feed.postedByUsername}`} >
                                <Link to={`/seller/public-profile/${feed.postedByUsername}`}>
                                  <div className={styles.feedIconwithCountsdiv}>
                                    <div className={styles.feedIconFollowAdddiv}>
                                      <Image
                                        src={feed.postedByUserImg ? feed.postedByUserImg : '/Content/Images/empty_user.jpg'} 
                                        className={styles.feedIconFollowImg} 
                                        />
                                      {!feed.contentOwner && (
                                      <div onClick={(e) => { 
                                              e.preventDefault(); 
                                              e.stopPropagation(); 
                                              FollowFeedUser(feed.fId); 
                                            }}
                                            className={styles.feedIconFollowAddIconContainer}
                                       >
                                        <Image 
                                          src={feed.isFollowing ? "/Content/Images/icons/icons-cancel.png" : "/Content/Images/icons/icons-plus.png"} 
                                          className={styles.feedIconFollowAddIcon} 
                                        />
                                      </div>
                                      )}
                                    </div>
                                  </div>
                                </Link>
                                </Tooltip>
                              
                              </div>

                                <div className={styles.feedIconwithCountsdiv}>
                                  <Tooltip content="Rating">
                                    <Image src="/Content/Images/icons/icons-rating.png" className={styles.feedIconImg} />
                                  </Tooltip>
                                  <label className={styles.feedUserContentFont}>{feed.ratings ? (feed.ratings).toFixed(0) : 0}</label>
                                </div>
                              {/* <div className={styles.feedIconwithCountsdiv}>
                                <Image src={feed.isLikedByMember ? '/Content/Images/icons/icons-fireRed.png' : '/Content/Images/icons/icons-fire.png' }
                                 onClick={()=>{MutateFeedLikeStatus(feed.fId)}}
                                 className= {`${styles.feedIconImg} ${feed.isLikedByMember ? styles.animateFire : styles.animateDisfire}`} />
                                <label className={styles.feedUserContentFont}>{feed.totalLikes}</label>
                              </div> */}
                              <div className={styles.feedIconwithCountsdiv}>
                                <Tooltip content="Sales">
                                  <Image src="/Content/Images/icons/totalsalesCount.png" className={styles.feedIconImg} />
                                </Tooltip>
                                <label className={styles.feedUserContentFont}>{feed.totalSold ?? 0}</label>
                              </div>
                              <div className={styles.feedIconwithCountsdiv}>
                                <Tooltip content="Price">
                                  <Image src="/Content/Images/icons/icons-dollar-coin.png" className={styles.feedIconImg} />
                                </Tooltip>
                                <label className={styles.feedUserContentFont}>{feed.currency ?? '$'}&nbsp;{feed.price ?? 0}</label>
                              </div>
                              <div className={styles.feedIconwithCountsdiv}>
                                  {!(feed.contentOwner || feed.isPurchased || feed.isAddedToCart) ? (
                                    <Tooltip content="Add to cart">
                                    <Image
                                      src="/Content/Images/icons/icons-buy.png"
                                      className={styles.feedIconImg}
                                      onClick={() => AddItemtoCart(feed.fId)}
                                    />
                                    </Tooltip>
                                  ) : (
                                    <Image
                                      src="/Content/Images/icons/add_shopping.png"
                                      className={`${styles.feedIconImg} ${styles.disabled}`}
                                      style={{ pointerEvents: 'none', opacity: 0.5 }}
                                    />
                                  )}
                                <label className={styles.feedUserContentFont} style={{visibility: 'hidden'}}>{feed.cartCount ?? 0}</label>
                              </div>
                              <div className={styles.feedIconwithCountsdiv}>
                                {(refferalCode && isStripe) ? (
                                  <Tooltip content="Share">
                                    <Image
                                      src="/Content/Images/icons/icons-share2.png" 
                                      className={styles.feedIconImg} 
                                      onClick={() => handleModalOpen(feed.fId)} 
                                    />
                                   </Tooltip>
                                ) : (
                                  <Tooltip content="You don't have a Stripe account.">
                                  <Image
                                      src="/Content/Images/icons/icons-share2.png"
                                      className={`${styles.feedIconImg} ${styles.disabled}`}
                                      style={{ pointerEvents: 'none', opacity: 0.5 }}
                                    />
                                </Tooltip>
                                )}
                                <label className={styles.feedUserContentFont} style={{visibility: 'hidden'}}>&nbsp;</label>
                              </div>
                            </div>
                        </div>


{/* Modal */}
{showModal === feed.fId && (
  <Modal show={true} onHide={handleModalClose} centered>
    <Modal.Header closeButton>
      <Modal.Title className={styles.shareCopyText}>Copy your invitation link to share </Modal.Title>
    </Modal.Header>
    <Modal.Body className="d-flex flex-column align-items-center justify-content-center text-center">
      <button
        type="button"
        className={`${styles.URLlinkshow} btn btn-link`}
        onClick={() => {
          navigator.clipboard.writeText(`${window.location.origin}/product-details/${feed.feedType.toLowerCase()}/${feed.fId}?ref=${refferalCode}`);
          alert('URL copied to clipboard!');
        }}
      >
        {`${window.location.origin}/product-details/${feed.feedType.toLowerCase()}/${feed.fId}?ref=${refferalCode}`}
      </button>
      <button className={styles.ClickCopyBtn}>
        <FaCopy className="ml-2" />
        <CopyUrlButton name="Copy" url={`${window.location.origin}/product-details/${feed.feedType.toLowerCase()}/${feed.fId}?ref=${refferalCode}`} />
      </button>
    </Modal.Body>
  </Modal>
)}

        </div>

      </Container>
    </Card>
  );
};

export default ProductComponent;