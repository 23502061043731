import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './SellerStyle.module.css';
// import SellerGraph from './SellerGraph';
import TotalSalesCount from '../BaseComponents/TotalSalesCount';
// import UserRefferalsCount from '../BaseComponents/UserRefferalsCount';
import FollowersCount from '../BaseComponents/FollowersCount';
import userServices from '../../Services/userServices';




const SellerDBmidContent = () => {

  const [userProfileData, setUserProfileData] = useState(null);
  const [totalFollowers, setTotalFollowers] = useState(0);
  const [totalSales, setTotalSales] = useState(0);

  useEffect(() => {
    const profileData = localStorage.getItem('userProfileData');
    if (profileData) {
      const parsedData = JSON.parse(profileData);
      setUserProfileData(parsedData);
    }

    async function fetchFollowersCount() {
      try {
        const response = await userServices.fetchFollowersCount();
        // console.log("fetchFollowersCount Response:", response);
        if (response !== null && response.statusCode === 200) {
          if (
            response.data !== null &&
            response.data !== "" &&
            response.data[0] !== null &&
            response.data[0].count !== null
          ) {
            setTotalFollowers(response.data[0].count);
          }
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    }
    fetchFollowersCount();

    async function fetchSalesCount() {
      try {
        const response = await userServices.fetchTotalSalesCount();
        // console.log("fetchSalesCount Response:", response);
        if (response !== null && response.statusCode === 200) {
          if (
            response.data !== null &&
            response.data !== "" &&
            response.data[0] !== null &&
            response.data[0].count !== null
          ) {
            setTotalSales(response.data[0].count);
          }
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    }
    fetchSalesCount();

  }, []);



  return (
    
    <Container>
    <Row className={`d-flex flex-column align-items-right`}>
      <Col xs="auto">
      {userProfileData ? (
        <>
        <div className="p-3">
          <h1 className={`mb-3 ${styles.SellerNameStyle}`} >Hi, {userProfileData.userId}</h1>
          <label className={styles.SellerBioStyle}>{userProfileData.bio}</label>
        </div>
        </>
        ):(
          <p className="text-muted">
            <b className={`${styles.SellerNameStyle}`} >Hi _</b>
          </p>
      )}
      </Col>
    </Row>

    <Row>
      <Col xs={4} md={4} lg={4}>
        <TotalSalesCount count={totalSales} />
      </Col>
      
      <Col xs={4} md={4} lg={4}>
        <FollowersCount count={totalFollowers} />
      </Col>
      
      <Col xs={4} md={4} lg={4}>
        {/* <UserRefferalsCount count='0' /> */}
      </Col>

    </Row>
    <br />
    <Row>
      {/* <SellerGraph /> */}
    </Row>
    <br />
    <Row>
      <Col md={6}>
      {/* <SellerRating rating={5} /> */}
      
      </Col>
      <Col md={6}>
      {/* <SellerReviews reviews={reviews} /> */}
      </Col>
    </Row>

  </Container>
      
  );
};

export default SellerDBmidContent;