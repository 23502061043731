import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Image } from 'react-bootstrap';
import styles from './followers.module.css'; // Adjust the path according to your project


const FollowingsList = ({ followings, handleFollowers }) => {
  
    const [openMenuIndex, setOpenMenuIndex] = useState(null); // To track which menu is open
    const menuRefs = useRef([]); // Reference array for each follower

    // Toggle menu visibility
    const handleMenuToggle = (index) => {
        setOpenMenuIndex(openMenuIndex === index ? null : index); // Toggle the menu for the clicked user
    };

    // Close the menu if clicked outside
    const handleClickOutside = (event) => {
        if (menuRefs.current) {
            const isOutsideClick = !menuRefs.current.some(
                (menuRef) => menuRef && menuRef.contains(event.target)
            );
            if (isOutsideClick) {
                setOpenMenuIndex(null); // Close any open menu
            }
        }
    };

    // Add event listener for outside clicks
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Handle followers action and close the menu
    const handleMenuItemClick = (userId, type) => {
        handleFollowers(userId, 'followings', type); // Trigger the followers action
        setOpenMenuIndex(null); // Close the menu after action
    };


    return (
        <Row className={styles.followerCard}>
            
            {followings.length > 0 ? (
                followings.map((following, index) => (
                    <div key={index} className={styles.followerInfodiv}>
                        <Link to={`/seller/public-profile/${following.username}`}>
                            <div className={styles.userDataContainer}>
                                <Image
                                    src={following.profileImage || '/Content/Images/empty_user.jpg'}
                                    className={styles.inviteUserImg}
                                    roundedCircle
                                    alt={`Profile ${index + 1}`}
                                />
                                <div className={styles.followerDataDiv}>
                                    <h3 className={styles.inviteusername}>{following.username}</h3>
                                    <label className={styles.inviteuserdesc}>{following.userBio}</label>
                                </div>
                            </div>
                        </Link>
                        <div
                            className={styles.menuContainer}
                            ref={(el) => (menuRefs.current[index] = el)} // Assign each ref dynamically
                        >
                            <button className={styles.threeDotButton} onClick={() => handleMenuToggle(index)}>
                                &#x22EE;
                            </button>

                            {/* Dropdown menu */}
                            {openMenuIndex === index && (
                                <ul className={styles.menuDropdown}>
                                    <li onClick={() => handleMenuItemClick(following.userId, 'unfollow')}>Unfollow</li>
                                    <li onClick={() => handleMenuItemClick(following.userId, 'block')}>Block</li>
                                </ul>
                            )}
                        </div>
                    </div>
                ))
            ) : (
                <p className={styles.noUsersFoundMsg}>No followings data found.</p>
            )}
            <br />

        </Row>
    );
};

export default FollowingsList;
