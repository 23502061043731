// import { useState } from 'react';
import UserProfile from '../BaseComponents/UserProfile';
import HomeOverview from '../BaseComponents/HomeOverview';
import styles from './crp.module.css';
import { Link } from 'react-router-dom';


const ReferralLHNBar = ({ type }) => {

  return (
    <div className={styles.referralStickySidebar}>
      <div className={styles.referralLeftComponentScroll}>

        <UserProfile />

        <div className='mt-4'>
          <Link to="/seller-experiences/create-workshop">
              <button className={`LHNYellowButton`} >
                Create Workshop
              </button>
          </Link>
        </div>

        <HomeOverview type={type} />
        <br /><br /><br />
      
      </div>
    </div>
    
  );
};

export default ReferralLHNBar;