import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { BeatLoader } from 'react-spinners';

const PrivateRoute = () => {
  const location = useLocation();
  
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      setIsAuthenticated(true);
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div className='BeatLoaderContainerBox'>
        <BeatLoader color="#2E637D" loading={true} size={20} />
        <label>Loading...</label>
      </div>
    );
  }

  if (!isAuthenticated) {
    // Store the current full URL before redirecting to login
    localStorage.setItem("redirectAfterLogin", location.pathname + location.search);

    return <Navigate to={`/signin/form`} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
