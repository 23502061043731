import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './SellerStyle.module.css';
import UserProfile from '../BaseComponents/UserProfile';
import SendBird from '../SendBird';
import SellerdbActions from './SellerdbActions';
import UnpublishedWorkshops from './UnpublishedWorkshops';


const UnpublishedContent = ({ type }) => {


  return (
      <main className="MainpartSetup">
        <Row className={`mainPad`}>
            <Col md={3} className={`${styles.responsiveDisplay}`}>
                <UserProfile />
                <SellerdbActions type={type} />
            </Col>
            <Col md={6}>
                <UnpublishedWorkshops  />
            </Col>
            <Col md={3} className={`${styles.responsiveDisplay}`}>
                <SendBird />
            </Col>
        </Row>
      </main>

  );
}

export default UnpublishedContent;
