import axiosAPI from "./axiosAPI";
import config from "../../src/config/config.js";

const stripeKey = config.stripeKey;
const cartServices = {

      getExperienceDetailsbyFeed: async (feedId) => {
        try {   
          await axiosAPI.validateToken();       
          const response = await axiosAPI.axiosAuth.get(`/experiences/feeds?story=${feedId}`);
          //console.log("getExperienceDetailsbyFeedId: ", response);
          return response.data;

        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      getExperienceDetailsbyBoast: async (boastId) => {
        try {   
          await axiosAPI.validateToken();       
          const response = await axiosAPI.axiosAuth.get(`/experiences/feeds?bId=${boastId}`);
          //console.log("getExperienceDetailsbyFeedId: ", response);
          return response.data;

        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      fetchBuyerReviewsbyFeed: async (feedId) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/feedback/review/${feedId}`);
          // console.log("getExperienceDetailsbyFeedId: ", response);
          return response.data;

        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },


      validateCartandCount: async (feedId, promoCode) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.post(`/cart/item`, {
            story: feedId,
            promoCode : promoCode,
          });
          //console.log("validateCartandCount: ", response);
          return response.data;

        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },


      deleteExperiencefromCart: async (feedId) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.delete(`/cart/item/${feedId}`);
          //console.log("deleteExperiencefromCart: ", response);
          return response.data;

        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      viewCartData: async () => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/cart/item`);
          //console.log("viewCartDetails: ", response);
          return response.data;

        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },


      stripePayment: async (checkOuttype, cartSize, paymentAmt, currency) => {
        try {
          await axiosAPI.validateToken();
          
          let refferalCode = null;
          let promoCode = null;
          let promoApplied = false;
          const storedRefferalCode = localStorage.getItem('userReferralCode');
          if (storedRefferalCode && storedRefferalCode !== 'undefined') {
            refferalCode = storedRefferalCode;
          }
          if(refferalCode && refferalCode !== 'undefined') {
            promoCode = refferalCode;
            promoApplied = true;
          }
          const response = await axiosAPI.axiosAuth.post(`/upm/payment`, {
            key: stripeKey, 
            ctype : checkOuttype,
            cartSize : cartSize,
            reqAmt : paymentAmt,
            reqCur : currency,
            PromoCode: promoCode,
            promoApplied: promoApplied,
          });
          // console.log("Key::", config.get("stripeKey"));
          // console.log("stripePayment: ", response.data.path);
          return response.data;

        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      stripePaymentDirect: async (checkOuttype, feedId, paymentAmt, currency, promoApplied, promoId, promoCode, discount) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.post(`/upm/payment`, {
            key: stripeKey, 
            ctype : checkOuttype,
            fid : feedId,
            reqAmt : paymentAmt,
            reqCur : currency,
            promoApplied: promoApplied,
            promoId: promoId,
            PromoCode: promoCode,
            discount: discount,
          });
          // console.log("Key::", config.get("stripeKey"));
          // console.log("stripePaymentDirect: ", response.data.path);
          return response.data;

        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },

      fetchReferralPromotions: async () => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/promo/signup-key`);
          //console.log("fetchReferralPromotions: ", response);
          return response.data;

        } catch (error) {
          console.error("fetchReferralPromotions API Error:", error);
          throw error;
        }
      },

      ActivateReferralPromotions: async (referralId) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.post(`/promo/signup-key`, {
            referralKey: referralId,
          });
          //console.log("ActivateReferralPromotions: ", response);
          return response.data;

        } catch (error) {
          console.error("ActivateReferralPromotions API Error:", error);
          throw error;
        }
      },

      fetchReferralTrackingDetails: async () => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/promo/status`);
          //console.log("fetchReferralPromotions: ", response);
          return response.data;

        } catch (error) {
          console.error("fetchReferralPromotions API Error:", error);
          throw error;
        }
      },


      fetchPromoCodes: async (searchCriteria) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.get(`/promo/profiles/${searchCriteria}`);
          //console.log("fetchPromoCodes: ", response);
          return response.data;

        } catch (error) {
          console.error("fetchPromoCodes API Error:", error);
          throw error;
        }
      },


      applyPromoCode: async (feedId, promoCode) => {
        try {
          await axiosAPI.validateToken();
          const response = await axiosAPI.axiosAuth.put(`/promo/apply/${feedId}/${promoCode}`);
          //console.log("applyPromoCode: ", response);
          return response.data;

        } catch (error) {
          console.error("applyPromoCode API Error:", error);
          throw error;
        }
      },

};


export default cartServices;