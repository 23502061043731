import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { CartProvider } from './Components/Navbar/CartContext';
import { AuthProvider } from './Components/Context/AuthContext';
import AuthPage from './Components/AuthPage';
import Home from './Components/Home';
import CRP from './Components/CRP';
import Signin from './Components/User/Login/Signin';
import Signup from './Components/User/Signup/Signup';
import Loader from './Components/BaseComponents/Loader';
import SellarDashboard from './Components/SellarDashboard';
import SellerExperiences from './Components/SellerExperiences';
import ManageExperiences from './Components/ManageExperiences';
import ProductDetails from './Components/ProductDetails';
import ViewCartDetails from './Components/ViewCartDetails';
import ExperienceHubDashboard from './Components/ExperienceHubDashboard';
import SellerBankDetails from './Components/SellerBankDetails';
import ExperienceHubItem from './Components/ExperienceHubItem';
import PaymentStatus from './Components/PaymentStatus';
import UpdateUserProfile from './Components/UpdateUserProfile';
import GeneralPages from './Components/GeneralPages';
import AccountStatus from './Components/AccountStatus';
import EducationDetails from './Components/EducationDetails';
import SellerPublicProfile from './Components/SellerPublicProfile';
import RepublishContents from './Components/SellerDashboard/Republish/RepublishContents';
import ScrollToTop from './Components/BaseComponents/ScrollToTop';
import ResponsiveMessaging from './Components/ResponsiveMessaging';
import CommonSuccess from './Components/BaseComponents/CommonSuccess';
import PrivateRoute from './Components/Context/PrivateRoute';
import CreateInvitation from './Components/Engage/Invitations/CreateInvitation';
import WatchPartySection from './Components/Engage/WatchParty/WatchPartySection';

const ReferralHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const refCode = params.get("ref");
  
    if (refCode) {
      const token = localStorage.getItem("accessToken");
  
      sessionStorage.setItem("referralCode", refCode);
      // Remove `?ref` only if it's a signup-related page
      if (token && !location.pathname.includes("/product-details")) {
        navigate(location.pathname, { replace: true });  
      }
    }
  }, [location, navigate]);
  
  return null;
};


const App = () => {


  return (
    <AuthProvider>
    <CartProvider>
      <Loader />
      <Router>
        <Loader />
        <ReferralHandler /> {/* This component handles referral codes */}
        <ScrollToTop />
        <div className="App">
          <Routes>
            <Route path="/" element={<AuthPage />} />
            <Route path="/signin/:loginstatus" element={<Signin />} />
            <Route path="/signup/:signupstatus/" element={<Signup />} />

            {/* Protected routes */}
            {/* Use PrivateRoute here to wrap all protected routes */}
            <Route element={<PrivateRoute />}>
              
              <Route path="/:homestatus/:substatus?" element={<Home />} />
              <Route path="/chat" element={<ResponsiveMessaging />} />
              <Route path="/seller/:sellerStatus" element={<SellarDashboard />} />
              <Route path="/seller-experiences/:currentStatus" element={<SellerExperiences />} />
              <Route path="/workshops/:type" element={<ManageExperiences />} />
              <Route path="/product-details/:contentType/:contentId" element={<ProductDetails />} />
              <Route path="/education-details/:educationObj" element={<EducationDetails />} />
              <Route path="/cart/:cartStatus" element={<ViewCartDetails />} />
              <Route path="/engage/:engageType" element={<ExperienceHubDashboard />} />
              <Route path="/invitations/:invitationType" element={<CreateInvitation />} />
              <Route path="/watch-party/:partyType/:invitationId" element={<WatchPartySection />} />
              <Route path="/experiencehub/experience/:pwId" element={<ExperienceHubItem />} />
              <Route path="/sellerbank/:bankStatus" element={<SellerBankDetails />} />
              <Route path="/stripe/:paymentStatus" element={<PaymentStatus />} />
              <Route path="/profile/:profileStatus" element={<UpdateUserProfile />} />
              <Route path="/seller-account/:accountStatus" element={<AccountStatus />} />
              <Route path="/seller/public-profile/:username" element={<SellerPublicProfile />} />
              <Route path="/host/:republishStatus/:republishId" element={<RepublishContents />} />
              <Route path="/crp/:CRPStatus" element={<CRP />} />
              <Route path="/hapifyr/:commonStatus" element={<CommonSuccess />} />
              <Route path="/engage/invitation/:inviteType" element={<CreateInvitation />} />
              
                {/* Protected version of /generalType */}
                <Route path="/general/:generalType" element={<GeneralPages />} />
            </Route>

              {/* Non-protected route for /generalType */}
              {/* Moved this to the bottom to avoid interfering with other routes */}
              <Route path="/info/:generalType" element={<GeneralPages />} />
          </Routes>
        </div>
      </Router>
    </CartProvider>
    </AuthProvider>
  );
}

export default App;
