import { Row, Col } from 'react-bootstrap';
import ReferralLHNBar from './ReferralLHNBar';
import RefferalProgram from './RefferalProgram';
import ActivityDashboard from './ActivityDashboard';
import SendBird from '../SendBird';
import styles from './crp.module.css';


const CRPDashboard = ({ status }) => {
  
    

  return (
    <main className="MainpartSetup HomeViewportHt">
       
      <Row className={`mainPad stickySidebar`}>
        <Col xs={12} lg={3} className={styles.responsiveDisplay}>
          <ReferralLHNBar type={status} />
        </Col>

        <Col xs={12} lg={6}>
            {status === 'refferal-board' && <RefferalProgram />}
            {status === 'activity-board' && <ActivityDashboard />}
        </Col>

        <Col xs={12} lg={3} className={styles.responsiveDisplay}>
          <SendBird />
        </Col>
      </Row>

    </main>
    
  );
};

export default CRPDashboard;