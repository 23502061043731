import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SellerLHNSidebar from './SellerLHNSidebar';
import SendBird from '../../SendBird';
import SellersProfiePage from './SellersProfiePage';
import styles from './publicProfile.module.css';


const SellerPublicProfilePage = ({ username }) => {

  
  return (
      <main className="MainpartSetup HomeViewportHt">
        <Row className={`mainPad stickySidebar`}>
          <Col md={3} xs={0} lg={3} className={`stickySidebar ${styles.responsiveDisplay}`} >
            <SellerLHNSidebar />
          </Col>
          
          <Col md={6} xs={12} lg={6} >
            <SellersProfiePage username={username} />
          </Col>
          
          <Col md={3} xs={0} lg={3} className={`${styles.responsiveDisplay}`}>
            <SendBird />
          </Col>
        </Row>
      </main>

  );
}

export default SellerPublicProfilePage;
