import axiosAPI from "./axiosAPI";
import { encryptRequest, decryptResponse } from "../Utils/commonUtils";


const loginApiServices = {

  validateEmailId: async (email, sendOtpflag) => {
    try {
      const response = await axiosAPI.baseInstance.post("/profile/valid-email", {
        email: email,
        sendOtp: sendOtpflag,
      });
      
      return response.data;

    } catch (error) {
      console.error("API Error in validateEmailId :", error);
      throw error;
    }
  },

  resendOtp: async (email, sendOtpflag, resendOtpflag) => {
    try {
      const response = await axiosAPI.baseInstance.post("/profile/valid-email", {
        email: email,
        sendOtp: sendOtpflag,
        resendOption: resendOtpflag,
      });
      
      return response.data;

    } catch (error) {
      console.error("API Error in resendOtp :", error);
      throw error;
    }
  },

  verifyEmailOtp: async (email, otpId, otp) => {
    try {
      
      const response = await axiosAPI.baseInstance.post("/profile/verify-email", {
        otp: otp,
        otpId: otpId,
        emailId: email,
      });
      
      return response.data;

    } catch (error) {
      console.error("API Error in verifyEmailOtp:", error);
      throw error;
    }
  },

  userUploadProfileSingup: async (formData) => {
 
    try {
      
       const response = await axiosAPI.baseInstance.post('/profile/display-pic', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'accept': 'application/json', // You may include this if needed
        },
    });

      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },


  checkUsernameAvailability: async (username) => {
    try {
      
      const response = await axiosAPI.baseInstance.post(`/profile/valid-uid`, {
        username: username,
      });

      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },


  userDataSingup: async (bio) => {
    try {
      
      const response = {
        statusCode: 200,
        message: "Success",
      }

      return response;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  userContactSingup: async (country, phone) => {
    try {
      
      
      const response = {
        statusCode: 200,
        message: "Success",
      }
      
      return response;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  getAllUserInterests: async () => {
    try {
      
      const response = await axiosAPI.baseInstance.get(`/common/user-interests`, {
      headers: {
        'Content-Type': 'application/json',
      },
      });
      //console.log("Interests: :", response);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  completefinalSignup: async (formData) => {
    try {
      
      //console.log("Request payload: ", formData);

      let response = await axiosAPI.baseInstance.post(`/profile/users`, encryptRequest({
        sessionId: formData.sessionId,
        signupMethod: formData.methodType,
        email: formData.email,
        password: formData.password,
        agreedTnC:formData.flag,
        // name: formData.name,
        username: formData.username,
        country: formData.country,
        countryCode: "+91",
        phone: formData.phone,
        bio: formData.bio,
        dob: formData.formattedDate,
        interests: formData.interests,
        imageUrl: formData.profileURL,
        promoCode: "",
        role:"user",
        referralId: formData.referralId,
      }));

      //console.log("Response::SignUp User :", response.data);
      if(response.data.data){
        response.data = decryptResponse(response.data.data);
      }

      if(response.data != null && response.data.data != null && response.data.data[0].token != null){
       const receivedToken = response.data.data[0].token; // Replace with the actual received token
       // const receivedToken = response.data.data[0].token;
       localStorage.setItem('accessToken', receivedToken);
       localStorage.setItem('userProfileData', JSON.stringify(response.data.data[0]));
       localStorage.setItem('sendbirdUserId', response.data.data[0].id);
       localStorage.setItem('sendbirdNickname', response.data.data[0].userId);
       localStorage.setItem('userProfilePicture', response.data.data[0].imageUrl);
       localStorage.setItem('userReferralCode', response.data.data[0].referralKey);
       localStorage.setItem('stripeAccountExists', response.data.data[0].stripeAccountExists);
       
        // const refreshInterval = 110 * 60 * 1000; // 110 minutes in milliseconds
        // const refreshInterval = 20 * 60 * 1000; // 20mins
        // const refreshInterval = 2 * 60 * 1000; // 2mins
        // const refreshInterval = 170 * 60 * 1000; // 170 minutes in milliseconds
        const refreshedAt = Date.now();
       localStorage.setItem('refreshedAt', refreshedAt.toString());
      }
      // // Clear tokens on logout
      // Cookies.remove('accessToken');

      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  // User Login Authentication
  userLogInAuth: async (email, password, isAgreed) => {
    try {
      let response = await axiosAPI.baseInstance.post("/profile/login", encryptRequest({
        email: email,
        password: password,
      }));
      
      //console.log(response.data)
      if(response.data.data){
        response.data = decryptResponse(response.data.data);
      }
      
      if(response.data != null && response.data.data != null && response.data.data[0].token != null) {
        const accessToken = response.data.data[0].token;
        const refreshToken = response.data.data[0].refreshToken;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('userProfileData', JSON.stringify(response.data.data[0]));
        localStorage.setItem('sendbirdUserId', response.data.data[0].id);
        localStorage.setItem('sendbirdNickname', response.data.data[0].userId);
        localStorage.setItem('userProfilePicture', response.data.data[0].imageUrl);
        localStorage.setItem('userReferralCode', response.data.data[0].referralKey);
        localStorage.setItem('stripeAccountExists', response.data.data[0].stripeAccountExists);
          // const refreshInterval = 170 * 60 * 1000; // 170 minutes in milliseconds
          // const refreshInterval = 20 * 60 * 1000; // 20mins
          // const futureRefreshTime = Date.now() + refreshInterval;
          // localStorage.setItem('refreshInterval', refreshedAt.toString());
          // const refreshInterval = 90 * 1000; // 1 min 30 secs
        const refreshedAt = Date.now();
        localStorage.setItem('refreshedAt', refreshedAt.toString());
        
      }

      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  userLogout: async () => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.delete("/auth/logout");
      //console.log(response.data)
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },


  refreshingTokens: async () => {
    try {
      const response = await axiosAPI.refreshTokens();
      //console.log(response.data)
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },
  socialGoogleSingup: async () => {
    try {
      const response = await axiosAPI.baseInstance.get("/google-profile");
      //console.log(response.data)
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  validateForgetPassword: async (emailId) => {
    try {
      
      const response = await axiosAPI.baseInstance.post(`/auth/forgot-password`, {
        emailId: emailId,
      });
      //console.log("ForgotPd :", response);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  validatingResetPwdOtp: async (otp,otpId,emailId) => {
    try {
      
      const response = await axiosAPI.baseInstance.post(`/auth/otp-validation`, {
        otp: otp,
        otpId: otpId,
        emailId: emailId,
      });
      //console.log("resetPd :", response);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  validateResetPassword: async (otpId,otp,emailId,password) => {
    try {
      
      const response = await axiosAPI.baseInstance.patch(`/auth/reset-password`, {
        otp: otp,
        otpId: otpId,
        emailId: emailId,
        password: password,
      });
      //console.log("resetPd :", response);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  
  // Other API functions...


};


export default loginApiServices;
