import React, { useState, useEffect, useContext, useRef } from "react";
import ReactPlayer from 'react-player';
import { Row, Button, Toast } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import styles from "./hub.module.css";
import { useParams } from 'react-router-dom';
import axiosAPI from "../../Services/axiosAPI.js";
import BuyerCreateReview from "./BuyerCreateReview.js";
import userServices from "../../Services/userServices.js";
import { Carousel } from 'react-responsive-carousel';
import { FaAngleDown } from "react-icons/fa";
import MessagingServices from "../../Services/MessagingServices.js";
import { Link } from "react-router-dom";
import AuthContext from "../Context/AuthContext.js";


export default function ExperienceHubItemContent({ openProductChat }) {

  const { pwId } = useParams();
  const [experienceDetails, setExperienceDetails] = useState([]);
  const [currentGroupChannel, setCurrentGroupChannel] = useState(null);
  const { setIsLoading } = useContext(AuthContext);
  const [showDescription, setShowDescription] = useState(true);
  const [showMaterial, setShowMaterial] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [experienceId, setExperienceId] = useState(null);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const imageTimeoutRef = useRef(null);
  const isFirstLoad = useRef(true); // Track first load to prevent unnecessary calls
  

  useEffect(() => {
    const getExperienceDetails = async () => {
      try {
        setIsLoading(true);
        const response = await axiosAPI.axiosAuth.get(`/experiences/items/${pwId}`);
        const experienceData = response?.data?.data;

        if (response?.data?.statusCode === 200 && experienceData?.length > 0) {
          setExperienceDetails(experienceData);
          
          const groupChatUrl = experienceData[0].groupChatUrl;
          setCurrentGroupChannel(groupChatUrl || null);
        }
        
      } catch (error) {
        console.error("API Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getExperienceDetails();

    return () => {
      if (imageTimeoutRef.current) clearTimeout(imageTimeoutRef.current);
    };
  }, [pwId, setIsLoading]); // Runs only on page load or reload

  // Call openProductChat only once after page load
  useEffect(() => {
    if (isFirstLoad.current && currentGroupChannel) {
      openProductChat(currentGroupChannel);
      isFirstLoad.current = false; // Ensure it runs only once
    }
  }, [currentGroupChannel, openProductChat]);


  const toggleShowDescription = () => setShowDescription(!showDescription);
  const toggleShowMaterial = () => setShowMaterial(!showMaterial);

  const FollowHandler = async (userId, type) => {
    setErrorMessage("");
    if (!userId) {
      console.log("Please provide the User ID first.");
      return;
    }
    setIsLoading(true);

    try {
      if (type) {
        await userServices.followUser(userId);
      } else {
        await userServices.unfollowUser(userId, true);
      }

      let updatedExperienceDetails = [...experienceDetails];
      updatedExperienceDetails[0].isFollowing = !updatedExperienceDetails[0].isFollowing;
      setExperienceDetails(updatedExperienceDetails);
      
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getRoomtoChat = async (sellerId) => {
    setErrorMessage("");
    try {
      setIsLoading(true);
      const response = await MessagingServices.fetchRoomIdtoChat(sellerId);
      if (response.statusCode === 200 && response.message?.channel_url) {
        setTimeout(() => {
          openProductChat(response.message.channel_url);
        }, 5000);
      } else {
        setErrorMessage(response.message || "Error while opening the chat");
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }
  };


  const handleOpenReviewModal = (id) => {
    setShowReviewModal(true);
    setExperienceId(id);
  };

  const handleCloseReviewModal = () => {
    setShowReviewModal(false);
  };

  const renderArrowPrev = (onClickHandler, hasPrev, label) => (
    hasPrev && (
      <button type="button" onClick={onClickHandler} title={label} className={`${styles.arrow} ${styles.arrowLeft}`}>
        &#9664;
      </button>
    )
  );

  const renderArrowNext = (onClickHandler, hasNext, label) => (
    hasNext && (
      <button type="button" onClick={onClickHandler} title={label} className={`${styles.arrow} ${styles.arrowRight}`}>
        &#9654;
      </button>
    )
  );

  const [playingIndex, setPlayingIndex] = useState(null); // Track which video is playing

  const handlePlay = (index) => {
    setPlayingIndex(index); // Set the playing video index
  };

  const handleContentCompleted = (index, sellerUsername, pwId) => {
    
    const contentLength = experienceDetails[0]?.content?.length || 0;
    const lastIndex = contentLength - 1;
    const profileData = JSON.parse(localStorage.getItem('userProfileData')); 
    const userId = profileData?.userId; // Extract userId from local storage
  
    if (index === lastIndex && sellerUsername !== userId) {
      handleOpenReviewModal(pwId); // Open modal with pwId
    }
  };
  
  const handleVideoEnd = (index, sellerUsername, pwId) => {
    handleContentCompleted(index, sellerUsername, pwId);
  };
  
  const handleImageLoad = (index, sellerUsername, pwId) => {
    imageTimeoutRef.current = setTimeout(() => {
      handleContentCompleted(index, sellerUsername, pwId);
    }, 5000);
  };

  const renderContent = (item, index, sellerUsername, pwId) => {
    const baseUrl = item.contentUrl.split('?')[0];
  
    const imageFormats = /\.(jpg|jpeg|heic|png|gif|webp|bmp|svg|tiff|tif)$/i;
    const videoFormats = /\.(mp4|mkv|flv|mpeg|wmv|avi|mov|webm|3gp)$/i;
  
    if (imageFormats.test(baseUrl)) {
      return (
        <img
          src={item.contentUrl}
          alt={`Feed ${index}`}
          className={styles.Exp_reactplayer}
          onLoad={() => handleImageLoad(index, sellerUsername, pwId)}
          loading="eager"
          fetchpriority="high"
          crossOrigin="anonymous"
        />
      );
    } else if (videoFormats.test(baseUrl)) {
      return (
        <div>
          <ReactPlayer
            className={styles.Exp_reactplayer}
            url={item.contentUrl}
            controls
            playing={playingIndex === index}
            onPlay={() => handlePlay(index)}
            onEnded={() => handleVideoEnd(index, sellerUsername, pwId)}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                  preload: 'auto',
                  crossOrigin: 'anonymous',
                },
              },
            }}
          />
          <style>
            {`
              .carousel-status {
                display: none !important;
              }
            `}
          </style>
        </div>
      );
    } else {
      return null;
    }
  };
  
  
  
  return (
    <div className={`${styles.ExpHubItemContainer}`}>
      <Row className={`boxBody`}>

      {errorMessage ? <div className={styles.errorMessage} >{errorMessage}</div> : null}
          
        {/* {isLoading ? (
            <div className={styles.boastLoadContent}>
              <Spinner animation="grow" size="sm" className='text-center' />
              <Spinner animation="grow" className='text-center' />
              <Spinner animation="grow" size="sm" className='text-center' />
              <Spinner animation="grow" className='text-center' />
              <Spinner animation="grow" size="sm" className='text-center' />
            </div>
        ) : ( */}
          <>
            {experienceDetails.length > 0 ? (
              experienceDetails.map((experience, index) => (
                <div key={index} className={`workshopContentDiv ${styles.workshopContentDiv}`}>
                  <div className={`workshopProfile ${styles.ExpHubItem_ProfileCont}`}>
                    <div className={styles.ExpHubItem_ProfileLeft}>
                      <div>
                        <Link to={`/seller/public-profile/${experience.sellerUsername}`}>
                          <img src={experience.sellerProfileImg ? experience.sellerProfileImg : "/Content/Images/empty_user.jpg"}
                            alt="workshop_user"
                            className={styles.ExpHubItem_ProfileImg}
                          />
                        </Link>
                      </div>
                      <div>
                        <div className={styles.workshop_sellername}>{experience.sellerUsername}</div>
                        {/* <div className={styles.workshop_sellerdesc}>{experience.sellerBio}</div> */}
                      </div>
                    </div>

                    <div className={styles.ExpHubItem_ProfileRight}>
                      {(!experience.contentOwner && experience.isFollowing) ? (
                        <Button className={`${styles.ExpHubItem_ProfileBtn}`} onClick={() => FollowHandler(experience.sellerId, false)}>
                          Following
                        </Button>
                      ) : ((!experience.contentOwner && !experience.isFollowing) ? (
                        <Button className={`${styles.ExpHubItem_ProfileBtn}`} onClick={() => FollowHandler(experience.sellerId, true)}>
                          Follow
                        </Button>
                      ) :
                        null
                      )}

                      {!experience.contentOwner && (
                        <div style={{display: 'none'}}>
                          <button className={`${styles.ExpHubItem_ProfileChatBtn}`} onClick={() => getRoomtoChat(experience.sellerId)}>
                            <img src="/Content/Images/icons/icons-message.png" alt="hub_chat" className={styles.ExpHubItem_ChatImg} />
                            &nbsp; Chat with Host
                          </button>
                        </div>
                      )}

                      <div>
                        <Link to="/invitations/new-invitation" state={{ workshopId: experience.sellerId, workshopCaption: experience.caption, partyType: 'one' }} >
                          <button className={`${styles.ExpHubItem_WatchPartyBtn}`} >
                            Create Watch Party
                          </button>
                        </Link>
                      </div>

                    </div>
                  </div>

                  <Row className={`workshopDescData ${styles.ExpHubItem_TitleFlex}`}>
                    <div className={styles.ExpHubItem_Title}>{experience.caption}</div>
                  </Row>

                  <div className={styles.ExpHubItem_VideoCont}>
                    {experience.content.length > 1 ? (
                      <Carousel
                        showThumbs={false}
                        showIndicators={false}
                        renderArrowPrev={renderArrowPrev}
                        renderArrowNext={renderArrowNext}
                        className={styles.customCarousel}
                      >
                        {experience.content.map((item, index) => (
                          <div key={index} className={styles.carouselItem}>
                            {renderContent(item, index, experience.sellerUsername, pwId)}
                          </div>
                        ))}
                      </Carousel>
                    ) : (
                      renderContent(experience.content[0], 0, experience.sellerUsername, pwId)
                    )}
                  </div>

                  <Row className={`text-end mt-2`}>
                    {/* <div className={styles.workshopReviewSection}>
                      <Button className={`${styles.addFeedbackBtn}`} onClick={() => handleOpenReviewModal(experience.id)}>
                        Add Feedback
                      </Button>
                    </div> */}
                    <BuyerCreateReview show={showReviewModal} handleClose={handleCloseReviewModal} experienceId={experienceId} />
                  </Row>

                  <div className={styles.ExpHubItem_DescCont}>
                    <div className={styles.toggleDropdowndiv}>
                      <div className={styles.ExpHubItem_DescTitle}>Description</div>
                      <Button onClick={toggleShowDescription} className={`${styles.toggleDropdownClick}`}>
                        <FaAngleDown />
                      </Button>
                    </div>
                    <Toast show={showDescription} onClose={toggleShowDescription} className={styles.toggleToastStyles}>
                      <div className={styles.ExpHubItem_DescText}>{experience.desc}</div>
                    </Toast>
                  </div>

                  <div className={styles.ExpHubItem_ExtraMaterialCont}>
                    <div className={styles.toggleDropdowndiv}>
                      <div className={styles.ExpHubItem_DescTitle}>Material required for Workshop</div>
                      <Button onClick={toggleShowMaterial} className={`${styles.toggleDropdownClick}`}>
                        <FaAngleDown />
                      </Button>
                    </div>
                    <Toast show={showMaterial} onClose={toggleShowMaterial} className={styles.toggleToastStyles}>
                      <div className={styles.ExpHubItem_DescText}>{experience.extraInfo}</div>
                    </Toast>
                  </div>
                </div>
              ))
            ) : (
              <div>
                <p className={styles.noProductdata}>No product details available or expired.</p>
              </div>
            )}
          </>
        {/* )} */}
      </Row>

      <br />
    </div>
  );
}
