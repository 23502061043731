import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import navStyles from './header.module.css';
import { Container, Row, Offcanvas, Button } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";

const ResponsiveBHNCanvas = () => {
  const navigate = useNavigate(); // Hook for navigation
  let currentTab = localStorage.getItem("navigatorTab");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNavigation = (url) => {
    handleClose(); // Close offcanvas
    navigate(url); // Navigate to the provided URL
  };

  return (
    <Container fluid className={navStyles.BNContainer}>
      <div>
        <Button onClick={handleShow} className={navStyles.BHNButtonStyles}>
          <HiDotsVertical className={navStyles.BHNIconStyles} />
        </Button>

        <Offcanvas show={show} onHide={handleClose} placement="bottom" backdrop="static" className={navStyles.offcanvasCustomStyle}>
          <Offcanvas.Header closeButton className="p-2">
            <Offcanvas.Title className={`text-center ${navStyles.overviewHeading} invisible`}>Overview</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-2">
            <div>
              {currentTab === 'Host' ? (
                <div>
                  <Link to="/workshops/manage-workshops" className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/workshops/manage-workshops")}
                  >
                    My Workshops
                  </Link>
                  <Link to="/seller/unpublished-workshops" className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/seller/unpublished-workshops")}
                  >
                    Unpublished Workshops
                  </Link>
                  <Link to="/seller/education-hub" className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/seller/education-hub")}
                  >
                    Education Hub
                  </Link>
                  
                  <div>
                    <Link to="/seller-experiences/create-experience" className={navStyles.BHNButtonNavigators}
                      onClick={() => handleNavigation("/seller-experiences/create-workshop")}
                    >
                      <Row className={navStyles.createExperienceBtnRow}>
                        <button className={`${navStyles.createExperienceBtn}`} >
                          Create Workshop
                        </button>
                      </Row>
                    </Link>
                  </div>
                </div>

            ) : currentTab === 'Watch' ? (
                <div>
                  <Link
                    to="/engage/dashboard"
                    className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/engage/dashboard")}
                  >
                    Purchased Workshops
                  </Link>
                  <Link
                    to="/engage/past-workshops"
                    className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/engage/past-workshops")}
                  >
                    Past Workshops
                  </Link>
                  <Link
                    to="/engage/dashboard"
                    className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/engage/upcoming-workshops")}
                  >
                    Upcoming Workshops
                  </Link>
                  <Link
                    to="/invitations/new-invitation"
                    className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/invitations/new-invitation")}
                  >
                    Create Watch Party
                  </Link>
                  <Link
                    to="/engage/invitations"
                    className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/engage/invitations")}
                  >
                    Invitations
                  </Link>
                </div>

              ) : currentTab === 'Profile' ? (
                <div>
                  <Link
                    to="/profile/profile-dashboard"
                    className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/profile/profile-dashboard")}
                  >
                    Dashboard
                  </Link>
                  <Link
                    to="/profile/update_profile_picture"
                    className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/profile/update_profile_picture")}
                  >
                    Upload
                  </Link>
                  <Link
                    to="/profile/update_basic_details"
                    className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/profile/update_basic_details")}
                  >
                    Basic Details
                  </Link>
                  <Link
                    to="/profile/update_bio"
                    className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/profile/update_bio")}
                  >
                    Bio
                  </Link>
                  <Link
                    to="/profile/update_contact_details"
                    className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/profile/update_contact_details")}
                  >
                    Contact Details
                  </Link>
                  <Link
                    to="/profile/update_interests"
                    className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/profile/update_interests")}
                  >
                    Interests
                  </Link>
                </div>

              ) : (
                <div>
                  <Link
                    to="/engage/dashboard" className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/engage/dashboard")}
                  >
                    Purchased Workshops
                  </Link>
                  <Link to="/followers" className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/followers")}
                  >
                    Followers
                  </Link>
                  <Link to="/crp/refferal-board" className={navStyles.BHNButtonNavigators}
                    onClick={() => handleNavigation("/crp/refferal-board")}
                  >
                    Community Referral Program
                  </Link>
                  
                  <div>
                    <Link to="/seller-experiences/create-experience" className={navStyles.BHNButtonNavigators}
                      onClick={() => handleNavigation("/seller-experiences/create-workshop")}
                    >
                      <Row className={navStyles.createExperienceBtnRow}>
                        <button className={`${navStyles.createExperienceBtn}`} >
                          Create Workshop
                        </button>
                      </Row>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </Container>
  );
};

export default ResponsiveBHNCanvas;
