import React, { useState } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './base.module.css';

const CopyUrlButton = ({ name, url, referralText }) => {
  const [copied, setCopied] = useState(false);
  // const refferalCode = localStorage.getItem('userReferralCode');
  // Ensure https:// is always included
  // const formattedUrl = url.startsWith("https") ? url : `https://${url}`;
  const formattedUrl = url;

  // Add a space before the URL to help WhatsApp recognize it
  const urlToCopy = referralText ? `${referralText}\n\n ${formattedUrl}` : formattedUrl;

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000); // Reset the tooltip after 2 seconds
  };

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{copied ? 'Copied!' : 'Copy URL'}</Tooltip>}
    >
      <CopyToClipboard text={urlToCopy} onCopy={handleCopy}>
        <Button as="div" className={styles.CopyUrlBtn} >
          {name}
        </Button>
      </CopyToClipboard>
    </OverlayTrigger>
  );
};

export default CopyUrlButton;
