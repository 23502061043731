import { Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './base.module.css';
import { useParams } from 'react-router-dom';


 

 
const AboutProfile = ({ isStripe }) => {

    const { profileStatus } = useParams();

  return (
    <Container className='noPadding'>
        
    <Row className={`d-flex flex-column align-items-center boxBody `}>

        <h5 className={`mb-3 ${styles.ActionHeader}`}>About</h5>
        
        <Row>

            {profileStatus === 'profile-dashboard' ? (
                <Row className={`mb-3 ${styles.ProfileRowActive}`}>
                    <Link to="/profile/profile-dashboard">
                        <button className={`${styles.ProfileButtonActive}`}>
                            Dashboard
                        </button>
                    </Link>
                </Row>
            ) : (
                <Row className={`mb-3 ${styles.ProfileActionRow}`}>
                    <Link to="/profile/profile-dashboard">
                        <button className={`${styles.ProfileButtons}`}>
                            Dashboard
                        </button>
                    </Link>
                </Row>
            )}
            <br/>

            {profileStatus === 'update_profile_picture' ? (
                <Row className={`mb-3 ${styles.ProfileRowActive}`}>
                    <Link to="/profile/update_profile_picture">
                        <button className={`${styles.ProfileButtonActive}`}>
                            Upload
                        </button>
                    </Link>
                </Row>
            ) : (
                <Row className={`mb-3 ${styles.ProfileActionRow}`}>
                    <Link to="/profile/update_profile_picture">
                        <button className={`${styles.ProfileButtons}`}>
                            Upload
                        </button>
                    </Link>
                </Row>
            )}
            <br/>

            {profileStatus === 'update_basic_details' ? (
            <Row className={`mb-3 ${styles.ProfileRowActive}`}>
                <Link to="/profile/update_basic_details">
                    <button className={`${styles.ProfileButtonActive}`}>
                        Basic Details
                    </button>
                </Link>
            </Row>
            ) : (
            <Row className={`mb-3 ${styles.ProfileActionRow}`}>
                <Link to="/profile/update_basic_details">
                    <button className={`${styles.ProfileButtons}`}>
                        Basic Details
                    </button>
                </Link>
            </Row>
            )}
            <br/>

            {profileStatus === 'update_bio' ? (
            <Row className={`mb-3 ${styles.ProfileRowActive}`}>
                <Link to="/profile/update_bio">
                    <button className={`${styles.ProfileButtonActive}`}>
                        Bio
                    </button>
                </Link>
            </Row>
            ) : (
            <Row className={`mb-3 ${styles.ProfileActionRow}`}>
                <Link to="/profile/update_bio">
                    <button className={`${styles.ProfileButtons}`}>
                        Bio
                    </button>
                </Link>
            </Row>
            )}
            <br/>

            {profileStatus === 'update_contact_details' ? (
            <Row className={`mb-3 ${styles.ProfileRowActive}`}>
                <Link to="/profile/update_contact_details">
                    <button className={`${styles.ProfileButtonActive}`}>
                    Contact Details
                    </button>
                </Link>
            </Row>
            ) : (
            <Row className={`mb-3 ${styles.ProfileActionRow}`}>
                <Link to="/profile/update_contact_details">
                    <button className={`${styles.ProfileButtons}`}>
                        Contact Details
                    </button>
                </Link>
            </Row>
            )}
            <br/>

            {profileStatus === 'stripe-account-details' ? (
            <Row className={`mb-3 ${styles.ProfileRowActive}`}>
                <Link to="/profile/stripe-account-details">
                    <button className={`${styles.ProfileButtonActive}`}>
                        {isStripe ? "Stripe Connect Dashboard" : "Signup with Stripe"}
                    </button>
                </Link>
            </Row>
            ) : (
            <Row className={`mb-3 ${styles.ProfileActionRow}`}>
                <Link to="/profile/stripe-account-details">
                    <button className={`${styles.ProfileButtons}`}>
                        {isStripe ? "Stripe Connect Dashboard" : "Signup with Stripe"}
                    </button>
                </Link>
            </Row>
            )}
            <br/>

            {profileStatus === 'update_interests' ? (
            <Row className={`mb-3 ${styles.ProfileRowActive}`}>
                <Link to="/profile/update_interests">
                    <button className={`${styles.ProfileButtonActive}`}>
                        Interests
                    </button>
                </Link>
            </Row>
            ) : (
            <Row className={`mb-3 ${styles.ProfileActionRow}`}>
                <Link to="/profile/update_interests">
                    <button className={`${styles.ProfileButtons}`}>
                        Interests
                    </button>
                </Link>
            </Row>
            )}
            <br/>

        </Row>
            
    </Row>
    </Container>
  );
};

export default AboutProfile;
