import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Container, Toast, Image } from 'react-bootstrap';
import inviteStyles from './invite.module.css';
import { FiMoreVertical } from "react-icons/fi";
import { FaAngleDown } from "react-icons/fa";
import hubServices from '../../../Services/hubServices';


const CreatedInvitations = ({ invitations }) => {

    const [attendeesList, setAttendeesList] = useState({}); // Store attendees for each invitation by ID
    const [showToggle, setShowToggle] = useState({}); // Track visibility per invitation
  
    // Toggle visibility and fetch attendees if not loaded yet
    const toggleShowAttendees = async (invitationId) => {
      // Check if attendees for this invitationId are already loaded
      if (invitationId && (!attendeesList[invitationId] || attendeesList[invitationId].length === 0)) {
        await fetchAttendees(invitationId);
      }
      // Toggle the showToggle state for this specific invitation
      setShowToggle(prev => ({
        ...prev,
        [invitationId]: !prev[invitationId]
      }));
    };
  
    // Fetch attendees for a specific invitation
    const fetchAttendees = async (invitationId) => {
      try {
        const response = await hubServices.fetchAttendeesbyId(invitationId);
        setAttendeesList(prev => ({
          ...prev,
          [invitationId]: response.data
        }));
      } catch (error) {
        console.error("Error fetching attendees:", error);
      }
    };
  
    const getStatusLabel = (invitation) => {
      const now = new Date();
      const startTime = new Date(`${invitation.startDate}T${invitation.startTime}`);
      const endTime = new Date(`${invitation.endDate}T${invitation.endTime}`);
  
      if (now > endTime) return "Invitation Expired";
      if (now >= startTime) return "Join Now";
  
      const timeDiffMs = startTime - now;
      const timeDiffMinutes = Math.floor(timeDiffMs / (1000 * 60));
      
      if (timeDiffMinutes <= 5) return "Starts in 5 mins";
      if (timeDiffMinutes <= 30) return "Starts in 30 mins";
      if (timeDiffMinutes <= 60) return "Starts in 1 hour";
  
      return `Starts in ${Math.ceil(timeDiffMinutes / 60)} hours`;
    };
  
    const [showOptionsPopup, setShowOptionsPopup] = useState(false);
    const [isHoveringButton, setIsHoveringButton] = useState(false);
    const [isHoveringPopup, setIsHoveringPopup] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
  
    const navigate = useNavigate();
  
    const handleShowOptionsPopup = () => {
      setShowOptionsPopup(!showOptionsPopup);
    };
  
    const handleCopyLink = (invitationId) => {
      const link = `/live/${invitationId}`;
      navigator.clipboard.writeText(link).then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Show "Copied!" for 2 seconds
      });
      setShowOptionsPopup(false);
    };
  
    const handleEditRedirect = (invitationId) => {
      navigate(`/invitations/update-invitation`, { state: { invitationId: invitationId } });
    };
  
    const handleMouseLeave = () => {
      if (!isHoveringButton && !isHoveringPopup) {
        setShowOptionsPopup(false);
      }
    };

  return (
    <Container className={`noPadding`}>
        {invitations && invitations.length > 0 ? (
          invitations.map((invitation, index) => (
            <div key={index}>

                <Row className={`mt-2 mb-2 ${inviteStyles.createInvirationButtonRow}`}>
                    <Col xs="auto" style={{ position: 'relative' }}>
                        <button
                            className={inviteStyles.fiMoreHorizontalButton}
                            onClick={handleShowOptionsPopup}
                            onMouseEnter={() => setIsHoveringButton(true)}
                            onMouseLeave={() => setIsHoveringButton(false)}
                        >
                            <FiMoreVertical />
                        </button>
                        {(showOptionsPopup || isHoveringButton || isHoveringPopup) && (
                        <div
                            className={inviteStyles.optionsPopup}
                            onMouseEnter={() => setIsHoveringPopup(true)}
                            onMouseLeave={() => {
                            setIsHoveringPopup(false);
                            handleMouseLeave();
                            }}
                        >
                            <button onClick={() => handleCopyLink(invitation.invitationId)}>&nbsp; Copy Invitation </button>
                            <button onClick={() => handleEditRedirect(invitation.invitationId)}>&nbsp; Edit </button>
                        </div>
                        )}
                    </Col>
                </Row>

                {isCopied && (
                    <div className={inviteStyles.copiedPopup}>
                        Copied!
                    </div>
                )}
         
                <Row className={`mb-5 ${inviteStyles.createdInvitationsContainer}`} >
                    <Row className={`${inviteStyles.createdInvitationsRow}`}>
                    <h2 className={` mb-3 ${inviteStyles.SchedulingHeader}`}>Scheduling Details*</h2>

                    <Form>
                        <Row>
                            <Form.Group className="mb-3 row d-flex">
                                <div className="col-auto">
                                    <Image
                                        src={invitation.cover || '/Content/Images/General/no_thumbnail.png'}
                                        alt="PW_thumbnail"
                                        className={inviteStyles.createdInvitationsThumbnail}
                                    />
                                </div>
                                <div className="col">
                                    <Form.Control
                                        as="textarea"
                                        rows={3} // Will expand as needed
                                        className={inviteStyles.createdInviteTitleFormControl}
                                        placeholder="Enter Workshop Title"
                                        value={invitation.title}
                                        readOnly
                                    />
                                </div>
                            </Form.Group>

                        </Row>
                        <Row className="mb-3">
                        <label className={inviteStyles.inviteFormLabel}>Date & Time*</label>
                        <Col xs='auto' className={inviteStyles.startingWidth}>
                            <label className={inviteStyles.inviteMiddleLabel}>From</label>
                            <Row>
                                <Col xs={6}>
                                    <Form.Control
                                        type="text"
                                        className={`${inviteStyles.createdInviteFormControl} ${inviteStyles.createdInviteDateFormControl}`}
                                        placeholder="Start Date"
                                        value={invitation.startDate}
                                        readOnly
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Control
                                        type="text"
                                        className={`${inviteStyles.createdInviteFormControl} ${inviteStyles.createdInviteDateFormControl}`}
                                        placeholder="Start Time"
                                        value={invitation.startTime}
                                        readOnly
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs='auto' className={inviteStyles.middleWidth} >
                            <Row className='text-center'>
                                <label className={inviteStyles.inviteMiddleLabel} style={{display: 'none'}}>To</label>
                            </Row>
                        </Col>
                        <Col xs='auto' className={inviteStyles.endingWidth}>
                            <label className={inviteStyles.inviteMiddleLabel}>To</label>
                            <Row>
                                <Col xs={6} className={inviteStyles.endContents}>
                                    <Form.Control
                                        type="text"
                                        value={invitation.endDate}
                                        className={`${inviteStyles.createdInviteFormControl} ${inviteStyles.createdInviteDateFormControl}`}
                                        placeholder="End Date"
                                        readOnly
                                    />
                                </Col>
                                <Col xs={6} className={inviteStyles.endContents}>
                                    <Form.Control
                                        type="text"
                                        value={invitation.endTime}
                                        className={`${inviteStyles.createdInviteFormControl} ${inviteStyles.createdInviteDateFormControl}`}
                                        placeholder="End Time"
                                        readOnly
                                    />
                                </Col>
                            </Row>
                        </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className={inviteStyles.inviteFormLabel}>Timezone*</label>
                            <Form.Control
                                type="text"
                                value={invitation.tz}
                                className={inviteStyles.createdInviteFormControl}
                                placeholder="Start Time"
                                readOnly
                            />
                        </Row>

                        <Row key={index} className="mb-3">
                            <label className={inviteStyles.inviteFormLabel} style={{ display: 'none' }}>Timezone*</label>
                            <div className="selected-users mb-2">
                            <label 
                                onClick={() => toggleShowAttendees(invitation.invitationId)} 
                                className={`${inviteStyles.toggleDropdownClick}`}
                            >
                                Attendees {showToggle[invitation.invitationId] ? `(${attendeesList[invitation.invitationId]?.length || 0})` : ''} &nbsp; <FaAngleDown style={{fontSize: '20px'}} />
                            </label>
                            <Toast 
                                show={showToggle[invitation.invitationId] || false}
                                className={inviteStyles.createdInviteFormControl} 
                                onClose={() => toggleShowAttendees(invitation.invitationId)}
                            >
                                {attendeesList[invitation.invitationId]?.map((user, idx) => (
                                <div key={user.userId || idx} className={inviteStyles.AttendeesUserDiv}>
                                    <label className={`tag ${inviteStyles.tag}`}>{user.username}</label>
                                </div>
                                ))}
                            </Toast>
                            </div>
                        </Row>

                        <Row className={`mb-2 mt-2 ${inviteStyles.createInvirationButtonRow}`}>
                            <Col xs="auto">
                                {/* invitation.isPurchased === "true" //  add this if isPurchased key updated*/}
                                {getStatusLabel(invitation) === "Join Now" ? (
                                // <Link to={`/watch-party/start/${invitation.invitationId}`}>
                                    <label className={inviteStyles.joinButtonStyle}>&nbsp; Start Party &nbsp;</label>
                                // </Link>
                                ) : (
                                <span className={inviteStyles.invitationOptionText}>{getStatusLabel(invitation)}</span>
                                )}
                            </Col>
                        </Row>

                    </Form>
                </Row>
                </Row>

            </div>
          ))
        ) : (
          <div className={inviteStyles.noInvitationsContainer}>
            You have not created any invitations.
          </div>
        )}
    </Container>
  );
};

export default CreatedInvitations;
