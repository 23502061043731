import React, { useState, useEffect, useCallback } from 'react';
import MessagingServices from '../../../Services/MessagingServices';
import { Image, Row, Spinner, Container, Toast } from 'react-bootstrap';
import filterStyles from './invite.module.css';
import { FaAngleDown } from "react-icons/fa";

const SearchAttendees = ({ filterWithUserId, attendeesList = [] }) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [showAttendees, setShowAttendees] = useState(true);

  // Track initial mount to control `selectedUsers` updates
  const isInitialMount = React.useRef(true);

  useEffect(() => {
    // Only set selectedUsers on the initial mount or if attendeesList changes in an update scenario
    if (isInitialMount.current) {
      isInitialMount.current = false;
      setSelectedUsers(attendeesList);
    } else if (attendeesList.length > 0) {
      setSelectedUsers(attendeesList);
    }
  }, [attendeesList]);


  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setSearchResults([]);
    
    if (debounceTimer) clearTimeout(debounceTimer);

    if (value.trim().length > 2) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        searchUsers(value);
      }, 500);
      setDebounceTimer(timer);
    } else {
      setIsLoading(false);
    }
  };

  const searchUsers = async (query) => {
    try {
      const response = await MessagingServices.searchMessagingUsers(query, 'all');
      if (response.statusCode === 200 && response.data?.length > 0) {
        setSearchResults(response.data);
      } else {
        setSearchResults([]);
        setErrorMessage('No Users found with the given search');
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred while searching for users. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserIdFilter = useCallback((userId, username, name) => {
    if (!userId || !username) {
      setErrorMessage('User information is incomplete. Please select a valid user.');
      return;
    }
    // Check if we have reached the maximum limit of 10 users
    if (selectedUsers.length >= 10) {
      setErrorMessage('You can only add up to 10 attendees.');
      return;
    }
  
    setErrorMessage(''); // Clear any previous errors
    setSearchValue('');
    setSearchResults([]);
  
    setSelectedUsers((prevSelectedUsers) => {
      // Add the user if they're not already in the list and limit not reached
      if (!prevSelectedUsers.some((user) => user.userId === userId)) {
        const newUser = { username, userId, name};
        return [...prevSelectedUsers, newUser];
      }
      return prevSelectedUsers; // Do nothing if user already exists
    });
    
    filterWithUserId(userId);
  }, [filterWithUserId, selectedUsers]);
  
  
  const removeSelectedUser = useCallback((userId) => {
    setSelectedUsers((prevSelectedUsers) => 
      prevSelectedUsers.filter(user => user.userId !== userId)
    );
  }, []);

  const clearSearch = () => {
    setSearchValue('');
    setSearchResults([]);
    setErrorMessage('');
    setIsLoading(false);
  };

  const toggleShowAttendees = () => setShowAttendees(!showAttendees);


  return (
    <Container className='noPadding'>
      <Row>
        <div className="w-100">
          <div className="position-relative">
            <input
              type="text"
              value={searchValue}
              placeholder="Email address or User ID"
              onChange={handleSearchInputChange}
              className={`form-control ${filterStyles.inviteFormControl}`}
              style={{ paddingRight: '2rem' }}  // Add padding for clear button
            />
            {searchValue && (
              <button onClick={clearSearch} className={`btn-clear ${filterStyles.clearButton}`}>
                ✕
              </button>
            )}
          </div>

          {/* Search isLoading Loader */}
          {isLoading && (
            <div className={`${filterStyles.searchDropdown} text-center`}>
              <Spinner animation="grow" />
            </div>
          )}

          {/* Search results dropdown */}
          {searchResults.length > 0 ? (
            <div className={`${filterStyles.usersearchDropdown}`}>
              <ul className="list-group">
              {searchResults.map((user, index) => (
                  <li
                    key={user._id || index}
                    className={`list-group-item search-item ${filterStyles.UserDatalist}`}
                    onClick={() => handleUserIdFilter(user._id, user.username, user.name)}
                  >
                    <div className={`mb-2 mt-2 ${filterStyles.UserDetailsDiv}`}>
                      <Image
                        src={user.image_url ? user.image_url : '/Content/Images/empty_user.jpg'}
                        roundedCircle
                        alt="Profile"
                        className={`roundedUser mr-3 ${filterStyles.UserIcon}`}
                      />
                      <div className={filterStyles.UserBiodataDiv}>
                        <h6 className={filterStyles.UserNametag}>{user.username}</h6>
                        <label className={filterStyles.UserDescriptionTag}>{user.username}</label>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            errorMessage && (
              <div className={`${filterStyles.searchDropdown} text-center`}>
                <label className={filterStyles.nousersfoundFont}>{errorMessage}</label>
              </div>
            )
          )}

          {/* Selected users in Toast */}
          <div className="selected-users mb-2">
            <label onClick={toggleShowAttendees} className={`${filterStyles.toggleDropdownClick}`}>
              Attendees &nbsp;({selectedUsers.length || 0}) <FaAngleDown />
            </label>
            <Toast show={showAttendees} onClose={toggleShowAttendees}>
              {selectedUsers.map((user, index) => (
                <div key={user.userId || index} className={filterStyles.AttendeesUserDiv}>
                  <label className={`tag ${filterStyles.tag}`}>{user.username}</label>
                  <button
                    onClick={() => removeSelectedUser(user.userId)}
                    className={`tag-remove ${filterStyles.tagRemove}`}
                    style={{display: 'none'}}
                  >
                    ×
                  </button>
                </div>
              ))}
            </Toast>

          </div>
        </div>
      </Row>
    </Container>
  );
};

export default SearchAttendees;
