import UserProfile from '../../BaseComponents/UserProfile';
import HomeOverview from '../../BaseComponents/HomeOverview';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';


const SellerLHNSidebar = ({ type }) => {

  return (
    <Container className={`stickySidebar noPadding`}>

        <UserProfile />
        <br />
        <div>
            <Link to="/seller-experiences/create-workshop">
                <button className={`LHNYellowButton`} >
                  Create Workshop
                </button>
            </Link>
        </div>
        <HomeOverview  />
    
    </Container>    
  );
};

export default SellerLHNSidebar;