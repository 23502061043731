import { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
// import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import ProductDetailspage from './ProductDetailspage';
// import OnetoOneChat from '../Messaging/OnetoOneChat';
import SendBird from '../SendBird';
import styles from './product.module.css';


const ProductOverview = ({ contentType, contentId, refferalId }) => {

  const [typeOfChat, setTypeOfChat] = useState("");
  const [channelUrl, setChannelUrl] = useState(null);
  const [sendBirdKey, setSendBirdKey] = useState(0);

  const openMessagingchat = (roomId) => {
    if (roomId) {
      setTypeOfChat('one-to-one');
      setChannelUrl(roomId);
      setSendBirdKey(prevKey => prevKey + 1);  // Force re-render by changing key
    }
  };

  return (
      <main className="MainpartSetup HomeViewportHt">
        <Row className="mainPad">

          <Col md={9} xs={12} lg={9} className='mb-3'>
            <ProductDetailspage contentType={contentType} contentId={contentId} refferalId={refferalId} openProductChat={openMessagingchat} />
          </Col>

          <Col md={3} xs={0} lg={3} className={`${styles.responsiveDisplay}`}>
            <Container className="RightPadding stickySidebar">
              <SendBird
                key={sendBirdKey} // Unique key to force re-render
                openChat={typeOfChat} 
                openUrl={channelUrl} 
              />
            </Container>
          </Col>

        </Row>
      </main>
    
  );
}


export default ProductOverview;
