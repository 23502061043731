import { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import profileStyles from './base.module.css'; 
import { Link } from 'react-router-dom';



const UserProfile = () => {

    const [userProfileData, setUserProfileData] = useState(null);

    useEffect(() => {
      // Retrieve data from localStorage
      const profileData = localStorage.getItem('userProfileData');
      if (profileData) {
        const parsedData = JSON.parse(profileData);
        setUserProfileData(parsedData);
      }
    }, []);
   

  return (
  <Container className='noPadding stickySidebar boxBody'>
      <Row className={`${profileStyles.userProfileInnerBox}`}>
      {userProfileData ? (
          <Link to={`/seller/public-profile/${userProfileData.userId}`} >
            <Row className={`${profileStyles.userProfileOuterBox}`}>
              
              <Col xs="auto">
                <Image
                  src={userProfileData.imageUrl ? userProfileData.imageUrl : '/Content/Images/empty_user.jpg'}
                  alt="Profile" roundedCircle
                  className={`roundedUser ${profileStyles.profileImage}`}
                />
              </Col>
              <Col xs="auto">
                <h3 className={profileStyles.profileName}>&nbsp;{userProfileData.userId}&nbsp;</h3>
              </Col>
              <Col xs="auto">
                <p className={profileStyles.profileBio}>{userProfileData.bio}</p>
                <p className={profileStyles.profileBio} style={{visibility: 'hidden'}}>{userProfileData.bio}</p>
              </Col>
            </Row>
          </Link>
        
        ) : (
          <Row className={`text-center ${profileStyles.userProfileBox}`}>  
            <Image
              src="/Content/Images/empty_user.jpg"
              alt="Profile"
              roundedCircle
              className={profileStyles.profileImage}
            />
            <p>No profile Data</p>
          </Row>
        )}
      </Row> 
  </Container>
      
  );
};

export default UserProfile;
