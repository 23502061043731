import { useState, useEffect } from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import BeatLoader from 'react-spinners/BeatLoader';
import profileStyles from './Profile.module.css';
import userServices from '../../Services/userServices';
import loginApiServices from '../../Services/loginApiServices';
import TotalSalesCount from '../BaseComponents/TotalSalesCount';
import FollowersCount from '../BaseComponents/FollowersCount';
import UserPurchasesCount from '../BaseComponents/UserPurchasesCount';
import LikesCount from '../BaseComponents/LikesCount';

const ProfileDashboard = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [userProfileData, setUserProfileData] = useState(null);
    const [totalFollowers, setTotalFollowers] = useState(0);
    const [totalSales, setTotalSales] = useState(0);
    const [purchasedExperiencesCount, setPurchasedExperiencesCount] = useState(0);
    const [totalLikesCount, setTotalLikesCount] = useState(0);
    const [allInterests, setAllInterests] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
        
        const profileData = localStorage.getItem('userProfileData');
        if (profileData) {
          const parsedData = JSON.parse(profileData);
          setUserProfileData(parsedData);
          console.log("Parsed user profile data:", parsedData);
        }

        try {
          const [followersRes, salesRes, purchasesRes, likesRes, interestsRes] = await Promise.all([
            userServices.fetchFollowersCount(),
            userServices.fetchTotalSalesCount(),
            userServices.fetchPurchasedExperiencesCount(),
            userServices.fetchTotalLikesCount(),
            loginApiServices.getAllUserInterests()
          ]);

          if (followersRes?.statusCode === 200) {
            setTotalFollowers(followersRes.data?.[0]?.count || 0);
          }

          if (salesRes?.statusCode === 200) {
            setTotalSales(salesRes.data?.[0]?.count || 0);
          }

          if (purchasesRes?.statusCode === 200) {
            setPurchasedExperiencesCount(purchasesRes.data?.[0]?.count || 0);
          }

          if (likesRes?.statusCode === 200) {
            setTotalLikesCount(likesRes.data?.[0]?.count || 0);
          }
          debugger
          if (interestsRes?.statusCode === 200 && interestsRes.data) {
            setAllInterests(interestsRes.data);
          }

        } catch (error) {
          console.error("API Error:", error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }, []);

  return (
    <Container className='RightPadding'>
        <Row className={`boxBody boxPadding`}>
            
            {isLoading ? (
              <div className={profileStyles.dashboardProfileLoader}>
                  <BeatLoader color="#2E637D" loading={true} size={15} />
                  <label> Loading... </label>
              </div>
            ) : (
              userProfileData ? (
                <Row className={`${profileStyles.dashboardProfileBox}`}>
                    <div className='mt-3 mb-2'>
                        <Image
                            src={userProfileData.imageUrl ? userProfileData.imageUrl : '/Content/Images/empty_user.jpg'}
                            alt="Profile"
                            roundedCircle
                            className={`roundedUser ${profileStyles.DBprofileImage}`}
                        />
                    </div>
                    <h3 className={profileStyles.dashboardProfileName}>{userProfileData.userId}</h3>
                    <p className={profileStyles.dashboardProfileBio}><strong>Bio : </strong> {userProfileData.bio}</p>

                    <Row>
                        <Col xs={3} md={3} lg={3}>
                            <FollowersCount count={totalFollowers} />
                        </Col>
                        <Col xs={3} md={3} lg={3}>
                            <TotalSalesCount count={totalSales} />
                        </Col>
                        <Col xs={3} md={3} lg={3}>
                            <UserPurchasesCount count={purchasedExperiencesCount} />
                        </Col>
                        <Col xs={3} md={3} lg={3}>
                            <LikesCount count={totalLikesCount} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <hr />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <h4 className={profileStyles.dashboardProfileTitle}> Topics You Care About </h4>
                            <div>
                                {allInterests.length > 0 ? (
                                    <ul className={profileStyles.profileInterestsList}>
                                    {allInterests.map((interest, index) => (
                                        <li key={index} className={profileStyles.profileInterestItemName}>
                                        {interest.interestName}
                                        </li>
                                    ))}
                                    </ul>
                                ) : (
                                    <p>No interests available</p>
                                )}
                            </div>
                        </Col>
                    </Row>

                        <br/><br/>

                </Row>
              ) : (
                <Row className={`text-center ${profileStyles.dashboardProfileMHeight} ${profileStyles.userProfileBox}`}> 
                    <div>
                        <Image
                            src="/Content/Images/empty_user.jpg"
                            alt="Profile"
                            roundedCircle
                            className={profileStyles.dashboardProfileImageNoUser}
                        />
                        <p className={profileStyles.dashboardProfileTextNoUser}>No profile data found</p>
                    </div> 
                </Row>
              )
            )}
        </Row>
    </Container>
  );
};

export default ProfileDashboard;
