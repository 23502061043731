import axios from "axios";
import config from "../config/config";

// const BASE_URL = config.apiUrl;
// console.log("API URI :: ", BASE_URL);
// const BASE_URL = "http://localhost/mkv/v1";
// const BASE_URL = "http://apiv2.hapifyr.com/mkv/v1";
// const BASE_URL = "https://api.hapifyr.com/mkv/v1";
// const BASE_URL = process.env.REACT_APP_HAPIFYR_API_URL;
// const accessToken = Cookies.get("accessToken");

const BASE_URL = config.apiUrl;

let accessToken = localStorage.getItem('accessToken');
let token = `Bearer ${accessToken}`;
let isRefreshing = false;
let refreshPromise = null;

const axiosAPI = {
  baseInstance: axios.create({
    baseURL: BASE_URL,
  }),

  axiosAuth: axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-type': 'application/json',
      'Authorization': token,
    },
  }),

  refreshTokens: async () => {
    try {
      const oldAccessToken = localStorage.getItem('accessToken');
      const accessBearer = `Bearer ${oldAccessToken}`;
      const oldRefreshToken = localStorage.getItem('refreshToken');
      const refreshBearer = `Bearer ${oldRefreshToken}`;

      const response = await axiosAPI.baseInstance.get('/auth/refresh-token', {
        headers: {
          'Content-type': 'application/json',
          'Authorization': accessBearer,
          'x-refresh-token': refreshBearer,
        },
      });

      if (response.data && response.data.data && response.data.data.token) {
        localStorage.clear();
        const newAccessToken = response.data.data.token;
        const newRefreshToken = response.data.data.refreshToken;
        localStorage.setItem('accessToken', newAccessToken);
        localStorage.setItem('refreshToken', newRefreshToken);
        localStorage.setItem('userProfileData', JSON.stringify(response.data.data));
        localStorage.setItem('sendbirdUserId', response.data.data.id);
        localStorage.setItem('sendbirdNickname', response.data.data.userId);
        localStorage.setItem('userProfilePicture', response.data.data.imageUrl);
        localStorage.setItem('userReferralCode', response.data.data.referralKey);
        localStorage.setItem('stripeAccountExists', response.data.data.stripeAccountExists);

        const refreshedAt = Date.now();
        localStorage.setItem('refreshedAt', refreshedAt.toString());
        accessToken = newAccessToken;
        token = `Bearer ${accessToken}`;
        axiosAPI.axiosAuth.defaults.headers['Authorization'] = token;

      } else {
        window.location.href = '/signin/form';
      }

      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      localStorage.clear();
      window.location.href = '/signin/form';
      throw error;
    } finally {
      isRefreshing = false;
    }
  },

  validateToken: async () => {
    const refreshedAt = localStorage.getItem('refreshedAt');
    const currentTime = Date.now();
    const timeInterval = currentTime - refreshedAt;
    const tokenValidity = 170 * 60 * 1000; // 170 minutes

    if (timeInterval >= tokenValidity) {
      if (!isRefreshing) {
        isRefreshing = true;
        refreshPromise = axiosAPI.refreshTokens();
      }
      await refreshPromise;
    }
  },
};

export default axiosAPI;
