import React, { useState, useEffect } from 'react';
import TransparentHeader from '../../Navbar/TransparentHeader';
import MandatoryDetails from './MandatoryDetails';
import SignupInterests from './SignupInterests';
import SignupProfile from './SignupProfile';
import { useParams } from 'react-router-dom';


const Signup = () => {
  
  // Get the required path parameter
  const { signupstatus } = useParams();
  // Get query parameters
  // const [searchParams] = useSearchParams();
  // Extract the optional 'ref' query parameter
  // const ref = searchParams.get('ref') || null;

  // State to hold data
  const [userData, setUserData] = useState({});
  const [referralCode, setReferralCode] = useState("");

  useEffect(() => {
    const storedRefCode = sessionStorage.getItem("referralCode");
    
    if (storedRefCode) {
      setReferralCode(storedRefCode);
    }
  }, []);
  // Function to update user data
  const updateUserData = (data) => {
    setUserData(data);
  };


  return (

    <div className="signupSection">

      <TransparentHeader />

      {signupstatus === 'mandatory-details' && <MandatoryDetails referralId={referralCode} updateUserData={updateUserData} />}
      {signupstatus === 'profile_details' && <SignupProfile userData={userData} updateUserData={updateUserData} />}
      {signupstatus === 'interest_setup' && <SignupInterests userData={userData} />}

    </div>

  );
}

export default Signup;
