import axiosAPI from "./axiosAPI";
import axios from "axios";


const experienceServices = {

  
    getAllCategoryInterests: async () => {
      try {
        
      const accessToken = localStorage.getItem('accessToken');
      const token = `Bearer ${accessToken}`;

      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.get(`/common/interest-categories`, {
          // withCredentials: true,
          headers: {
            'Authorization': token, // Include the "Authorization" header
            'Content-Type': 'application/json',
          },
        });

        //console.log("getAllCategoryInterests: :", response.data);
        return response.data;

      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },

    saveDraftWorkshop: async (form) => {
      try {
        await axiosAPI.validateToken();
        const accessToken = localStorage.getItem('accessToken');
        const token = `Bearer ${accessToken}`;
       const response = await axiosAPI.axiosAuth.post(`/experiences/feed`, form,  {
          headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data',
          },
        });
        //console.log("Response::creatingExperience : ", response.data);
  
        return response.data;
      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },

    getDraftWorkshop: async ( workshopId ) => {
      try {
        await axiosAPI.validateToken();
       const response = await axiosAPI.axiosAuth.get(`/experiences/unpublished?story=${workshopId}`);
        // console.log("Response::getDraftWorkshopDetails : ", response.data);
  
        return response.data;
      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },

    publishWorkshop: async (feedId) => {
    try {
      await axiosAPI.validateToken();
      const accessToken = localStorage.getItem('accessToken');
      const token = `Bearer ${accessToken}`;
      const response = await axiosAPI.axiosAuth.post(`/experiences/publish`, {
        feedId: feedId,
        },
        {
        headers: {
          'Authorization': token,
          'Content-Type': 'multipart/form-data',
        },
      });
      //console.log("Response::creatingExperience : ", response.data);

      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
    },

    saveUploadedContentstoWorkshop: async (contents, contentType, size) => {
      try {
        let resultArray =[];
        let fileData =[];
        if (contents && contents.length > 0) {
          contents.forEach((content, index) => {
            fileData.push({
              seq: index + 1,
              filename: content.name
            });
          });
        }
        await axiosAPI.validateToken();
        const response = await axiosAPI.axiosAuth.post(`/experiences/request-upload/${contentType}`, {
          fileData: fileData,
        });
        //console.log("saveUploadedContentstoWorkshop: ", response);
        if(response.data !== null && response.data.statusCode === 200 && response.data.data.length === size){
          for (let i = 0; i<size; i++){
            let presignedArray = await axios.put(response.data.data[i].uploadUrl, contents[i], {
              headers: {
                'Content-Type': contents[i].type,
              }
            });
            // console.log("saveUploadedContentstoWorkshop:: presignedArray Data::", JSON.stringify(presignedArray));

            if(presignedArray && presignedArray.status === 200 && presignedArray.statusText === "OK"){
              resultArray.push(response.data.data[i]);
            }
          }
          // console.log("saveUploadedContentstoWorkshop :: resultArray::", resultArray);
          response.data.data.push({ resultArray });
        } else {

          return resultArray;
        }

        return resultArray;
      } catch (error) {
        console.error("saveUploadedContentstoWorkshop API Error:", error);
        return error;
      }
    },

    fetchUserWorkshop: async ( workshopId ) => {
      try {
        await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.get(`/experiences/feed?story=${workshopId}`);
        // console.log("Response::fetchUserWorkshop : ", response.data);
        return response.data;
      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },

    saveUpdatedWorkshop: async (form) => {
      try {
        await axiosAPI.validateToken();
        const accessToken = localStorage.getItem('accessToken');
        const token = `Bearer ${accessToken}`;
      const response = await axiosAPI.axiosAuth.put(`/experiences/feed`, form,  {
          headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data',
          },
        });
        //console.log("Response::saveUpdatedWorkshop : ", response.data);

        return response.data;
      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },

    publishUpdatedWorkshop: async (feedId) => {
      try {
        await axiosAPI.validateToken();
        const accessToken = localStorage.getItem('accessToken');
        const token = `Bearer ${accessToken}`;
        const response = await axiosAPI.axiosAuth.post(`/experiences/publish`, {
          feedId: feedId,
          },
          {
          headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data',
          },
        });
        //console.log("Response::creatingExperience : ", response.data);
  
        return response.data;
  
      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },


    validateSellerBankDetails: async () => {
      try {
        await axiosAPI.validateToken();
        const response = await axiosAPI.axiosAuth.get(`/upm/bank-details-status`);
        //console.log("fetchSellerBankDetails: ", response);
        return response.data;

      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },

    fetchSellerBankDetails: async () => {
      try {
        await axiosAPI.validateToken();
        const response = await axiosAPI.axiosAuth.get(`/upm/bank-details`);
        //console.log("fetchSellerBankDetails: ", response);
        return response.data;

      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },

    saveSellerBankDetails: async (accountName, accountNumber, accountBsb, isConfirm) => {
      try {
        await axiosAPI.validateToken();
        const response = await axiosAPI.axiosAuth.post(`/upm/bank-details`, {
          accName: accountName,
          accNum: accountNumber,
          bsb: accountBsb,
        });
        //console.log("saveSellerBankDetails: ", response);
        return response.data;

      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },

    updateSellerBankDetails: async (accountName, accountNumber, accountBsb, isConfirm) => {
      try {
        await axiosAPI.validateToken();
        const response = await axiosAPI.axiosAuth.put(`/upm/bank-details`, {
          accName: accountName,
          accNum: accountNumber,
          bsb: accountBsb,
        });
        //console.log("updateSellerBankDetails: ", response);
        return response.data;

      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },

    fetchAllUserCreatedWorkshops: async () => {
      try {
        await axiosAPI.validateToken();
        const response = await axiosAPI.axiosAuth.get(`/experiences/published`);
        // console.log("fetchAllUserCreatedWorkshops Resp: ", response);
        return response.data;

      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },

    fetchUnpublishedWorkshops: async () => {
      try {
        await axiosAPI.validateToken();
        const response = await axiosAPI.axiosAuth.get(`/experiences/unpublished`);
        // console.log("fetchAllUserCreatedWorkshops Resp: ", response);
        return response.data;

      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },

    createSellersAccount: async (countyObj, page) => {
      try {
        await axiosAPI.validateToken();
        
        const response = await axiosAPI.axiosAuth.get(`/upm/create-seller-account?region=${countyObj.country}&page=${page}`);
        // console.log("createSellersAccount Resp: ", response); region
        return response.data;

      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },


    fetchSellerDetails: async (username) => {
      try {
        await axiosAPI.validateToken();
        const response = await axiosAPI.axiosAuth.get(`/profile/users?u=${username}`);
        // console.log("fetchAllUserCreatedWorkshops Resp: ", response);
        return response.data;

      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },

    fetchSellerWorkshops: async (username, type, index) => {
      try {
        await axiosAPI.validateToken();
        const response = await axiosAPI.axiosAuth.get(`/profile/users?u=${username}&cType=${type}&p=${index}`);
        // console.log("fetchAllUserCreatedWorkshops Resp: ", response);
        return response.data;

      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },

    fetchSellerBoasts: async (username, type, index) => {
      try {
        await axiosAPI.validateToken();
        const response = await axiosAPI.axiosAuth.get(`/profile/users?u=${username}&cType=${type}&p=${index}`);
        // console.log("fetchAllUserCreatedWorkshops Resp: ", response);
        return response.data;

      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },


};


export default experienceServices;